<template>
    <div class="page-loader">
        <img src="@/assets/Images/loading.svg" width="100">
    </div>
</template>

<script>
export default {
  name: 'PageLoader'
}
</script>
