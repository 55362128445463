/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const PetCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Pets', [
            'pets',
            'attempted',
            'loadingPets',
            'retryPets'
        ])
    },
    watch: {
        selectedSiteId() {
            this.refreshPets();
        }
    },
    methods: {
        ...mapActions('Pets', [
            'getPetsBySiteId'
        ]),
        refreshPets() {
            if (this.pets.length == 0 || !!this.retryPets) {
                this.tryGetPetsBySiteId();
            }
        },
        tryGetPetsBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getPetsBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPets();
    }
}
