/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const ServiceProductCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('ServiceProducts', [
            'serviceProducts',
            'attempted',
            'loadingServiceProducts',
            'retryServiceProducts'
        ]),
        attemptedServiceProducts() {
            return !!this.attempted;
        }
    },
    watch: {
        selectedSiteId() {
            this.refreshServiceProducts()
        }
    },
    methods: {
        ...mapActions('ServiceProducts', [
            'getServiceProductsBySiteId'
        ]),
        refreshServiceProducts() {
            if (this.serviceProducts.length == 0 || !!this.retryServiceProducts) {
                this.tryGetServiceProducts();
            }
        },
        tryGetServiceProducts() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getServiceProductsBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshServiceProducts();
    }
}
