<template>
    <div class="footer bg-footer text-footer-color">
      <div class="footer-content">
        <error-banner v-if="error" :message="error"></error-banner>
        <fieldset v-bind:disabled="formDisabled">
          <label class="{ 'error' : validator.nameError }">{{ nameLabel }}</label>
          <div>
            <input v-model="signedName" class="form-control mb-2" type="text" required placeholder="Please enter name" />
          </div>
          <label class="{ 'error': validator.signatureError }">{{ signatureLabel }}</label>
          <signature-pad v-model="signedText">
            <button @click="tryAddSignature" class="btn btn-tertiary">
                Complete
                <inline-loader v-if="loading"></inline-loader>
            </button>
          </signature-pad>
        </fieldset>
      </div>
    </div>
</template>

<script>
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SignatureHelper } from '@/helpers/SignatureHelper'
import { WebApi } from '@/services/WebApi'
import SignaturePad from './SignaturePad.vue'
import InlineLoader from '../Loading/InlineLoader.vue'
import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'

export default {
  name: 'SigningForm',
  data () {
    return {
      error: '',
      signature: null,
      loading: false,
      signedName: '',
      signedText: '',
      validator: SignatureHelper.getSignatureValidator()
    }
  },
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  computed: {
    contractId () {
      return this.contract.id
    },
    formDisabled () {
      return this.loading || !!this.signature
    },
    nameLabel () {
      return this.validator.nameError ? this.validator.nameError : 'Full name'
    },
    signatureLabel () {
      return this.validator.signatureError ? this.validator.signatureError : 'Signature'
    },
    signatureAddModel () {
      return {
        signedName: this.signedName,
        signedText: this.signedText
      }
    }
  },
  methods: {
    tryAddSignature () {
      const self = this
      self.validator.validate(self.signatureAddModel)
      if (!self.validator.isValid) {
        return
      }
      self.error = ''
      self.loading = true
      WebApi.post('ClientContract/' + self.contractId + '/Signature', self.signatureAddModel)
        .then(response => {
          self.signature = response.data
          self.$emit('signed')
        })
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    },
    tryFetchSignature () {
      const self = this
      self.error = ''
      self.loading = true
      WebApi.get('ClientContract/' + self.contractId + '/Signature')
        .then(response => { self.signature = response.data })
        .catch(error => {
          if (!ErrorHelper.isNotFound(error)) {
            self.error = ErrorHelper.getError(error)
          }
        })
        .finally(() => { self.loading = false })
    }
  },
  components: {
    'signature-pad': SignaturePad,
    'inline-loader': InlineLoader,
    'error-banner': ErrorBanner
  },
  created () {
    this.tryFetchSignature()
  }
}
</script>

<style scoped>
.footer {
  position: fixed;
  height: max-content;
  width: 100%;
  bottom: 0;
  left: 0;
}

.footer-content {
  margin: 10px auto;
  width: 300px;
}

.form-control {
  background: #FFEDB2;
  border: 1px solid #121820;
}

.label {
  color: #121820;
  font-size: 1rem;
}

.error {
  color: #FF576C;
  font-size: 1rem;
}
</style>
