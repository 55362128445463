/* eslint-disable */
import { mapActions } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { ClientAddressCacheMixin } from "./ClientAddressCacheMixin"
import { AddressHelper } from "@/helpers/AddressHelper";

export const ClientAddressAddMixin = {
    mixins: [ClientAddressCacheMixin],
    data() {
        return {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postCode: '',
            clientAddressValidator: AddressHelper.getAddressValidator()
        }
    },
    computed: {
        clientAddressAddModel() {
            return Object.assign({}, {
                line1: this.line1,
                line2: this.line2,
                line3: this.line3,
                town: this.town,
                county: this.county,
                postCode: this.postCode
            });
        }
    },
    methods: {
        ...mapActions('RegistrationClientAddress', [
            'addClientAddress'
        ]),
        tryAddClientAddress() {
            const self = this;
            self.clientAddressValidator.check(self.clientAddressAddModel);
            if (!self.clientAddressValidator.isValid) {
                return;
            }
            self.error = '';
            return self.addClientAddress({ inviteId: self.inviteId, payload: self.clientAddressAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
