/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const EventSearchMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('EventSearches', [
            'eventSearchesCleared'
        ]),
        ...mapGetters('EventSearches', [
            'findEventSearch'
        ]),
        siteBookingSettingBlockOffEvents() {
            console.log('OVERRIDE COMPUTED - siteBookingSettingBlockOffEvents')
        },
        selectedDates() {
            console.log('OVERRIDE COMPUTED - selectedDates ')
            return {}
        },
        searchModel() {
            return {
                startUtc: this.selectedDates.start,
                endUtc: this.selectedDates.end,
                siteIds: [this.selectedSiteId],

            }
        },
        searchModelValid() {
            return !!this.searchModel
                && !!this.searchModel.startUtc
                && !!this.searchModel.endUtc
        },
        eventSearchCacheKey() {
            return CacheHelper.fullObjectCacheKey(this.searchModel);
        },
        eventCache() {
            return this.findEventSearch(this.eventSearchCacheKey);
        },
        loadingEvents() {
            return this.eventCache && this.eventCache.loading;
        },
        retryEvents() {
            return this.eventCache && this.eventCache.retry;
        },
        events() {
            return this.eventCache ? this.eventCache.value.events : [];
        }
    },
    watch: {
        searchModel() {
            this.refreshEvents();
        },
        eventSearchesCleared() {
            this.refreshEvents();
        }
    },
    methods: {
        ...mapActions('EventSearches', [
            'searchEventsBySiteId'
        ]),
        refreshEvents() {
            if(!!this.siteBookingSettingBlockOffEvents){
                this.error = '';
                if (!this.eventCache || !!this.eventCache.retry) {
                    this.searchAndCacheEvents();
                }
            }
        },
        searchAndCacheEvents() {
            const self = this;
            if (!self.searchModelValid) {
                return;
            }
            self.searchEventsBySiteId({
                key: self.eventSearchCacheKey,
                siteId: self.selectedSiteId,
                payload: self.searchModel
            }).catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshEvents();
    }
}
