/* eslint-disable */
<template>
    <div>
        <pet-appointment-conflict :time-slot="timeSlot" v-model="petAppointmentConflictStatus"></pet-appointment-conflict>
        <appointment-conflict :time-slot="timeSlot" :resource-id="resourceId" v-model="appointmentConflictStatus"></appointment-conflict>
        <event-conflict :time-slot="timeSlot" :resource-id="resourceId" v-model="eventConflictStatus"></event-conflict>
        <resource-hours-conflict :time-slot="timeSlot" :resource-id="resourceId" v-model="resourceHoursConflictStatus"></resource-hours-conflict>
    </div>
</template>

<script>
/* eslint-disable */
    import { StatusEmitterMixin } from '@/mixins/Booking/StatusEmitterMixin'
    import ResourceAppointmentConflict from './ResourceAppointmentConflict.vue'
    import ResourceEventConflict from './ResourceEventConflict.vue'
    import ResourceHoursConflict from './ResourceHoursConflict.vue'
    import PetAppointmentConflict from './PetAppointmentConflict.vue'

    export default {
        name: 'ResourceConflict',
        mixins: [StatusEmitterMixin],
        props: {
            timeSlot: Object,
            resourceId: String
        },
        data() {
            return {
                petAppointmentConflictStatus: {},
                appointmentConflictStatus: {},
                eventConflictStatus: {},
                resourceHoursConflictStatus: {}
            }
        },
        computed: {
            statuses() {
                return [
                    this.petAppointmentConflictStatus,
                    this.appointmentConflictStatus,
                    this.eventConflictStatus,
                    this.resourceHoursConflictStatus
                ];
            },
            loading() {
                return this.statuses.some(s => !!s.loading);
            },
            available() {
                return this.statuses.every(s => !!s.available);
            }
        },
        components: {
            'appointment-conflict': ResourceAppointmentConflict,
            'event-conflict': ResourceEventConflict,
            'resource-hours-conflict': ResourceHoursConflict,
            'pet-appointment-conflict': PetAppointmentConflict
        }
    }
</script>
