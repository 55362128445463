import { mapActions, mapState } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const SiteCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapState('Sites', [
      'fetchedSites',
      'fetchingSites',
      'sites'
    ])
  },
  methods: {
    ...mapActions('Sites', [
      'fetchSites'
    ]),
    refreshSites () {
      if (!this.fetchedSites && !this.fetchingSites) {
        this.tryFetchSites()
      }
    },
    tryFetchSites () {
      const self = this
      self.error = ''
      self.fetchSites()
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshSites()
  }
}
