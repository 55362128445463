/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';

export const ClientNotificationSettingUnsubscribeMixin = {
    computed: {
        settingId () {
            console.log('OVERRIDE COMPUTED - settingId');
        }
    },
    methods: {
        ...mapActions('ClientNotificationSetting', [
            'unsubscribeMarketingSetting'
        ]),
        tryUnsubscribeMarketingSetting() {
            const self = this;
            if (!self.settingId) {
                return;
            }
            self.error = '';
            self.unsubscribeMarketingSetting(self.settingId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
