/* eslint-disable */
import { WebApi } from '@/services/WebApi';

const state = () => ({
    loadedPets: false,
    loadingPets: false,
    petCaches: []
})

const mutations = {
    setPets(state, pets) {
        pets.forEach((pet) => {
            const existingPetIndex = state.petCaches.findIndex(
                (existingPet) => existingPet.id === pet.id
            );
            if (existingPetIndex !== -1) {
                // If the pet with matching id exists, update it
                state.petCaches[existingPetIndex] = { ...pet };
            } else {
                // Otherwise, add the new pet to the array
                state.petCaches.push({ ...pet });
            }
        });
        state.loadedPets = true;
    },
    setLoading(state, value) {
        state.loadingPets = !!value;
    }
}

const actions = {
    findPets({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('ClientOnboardingInvite/' + inviteId + '/Pet')
                .then(response => {
                    commit('setPets', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    updatePet({ commit }, { inviteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.put('ClientOnboardingInvite/'+ inviteId + '/Pet', payload)
                .then(response => {
                    commit('setPets', [response.data]);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
