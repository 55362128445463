/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
  loadingAlternateContact: false,
  loadedAlternateContact: false,
  alternateContactCache: null
})

const mutations = {
  setAlternateContact(state, alternateContact) {
    state.alternateContactCache = alternateContact
    state.loadedAlternateContact = true;
  },
  setLoading (state, value) {
      state.loadingAlternateContact = !!value;
  }
}

const actions = {
  fetchAlternateContact ({ commit }, inviteId) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        WebApi.get('ClientOnboardingInvite/' + inviteId + '/Alternate/Contact')
          .then(response => {
              commit('setAlternateContact', response.data);
              resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('setLoading', false));
      })
  },
  addAlternateContact ({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      WebApi.post('ClientOnboardingInvite/' + inviteId + '/Alternate/Contact', payload)
        .then(response => {
            commit('setAlternateContact', response.data);
            resolve(response)
        })
        .catch(error => reject(error))
        .finally(() => commit('setLoading', false));
    })
  },
  updateAlternateContact ({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      WebApi.put('ClientOnboardingInvite/' + inviteId + '/Alternate/Contact', payload)
        .then(response => {
            commit('setAlternateContact', response.data);
            resolve(response)
        })
        .catch(error => reject(error))
        .finally(() => commit('setLoading', false));
    })
  },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
