<template>
  <labeled-form-control label="Breed">
    <span v-if="loadingPetBreeds" class="inline-loader"></span>
    <template v-else>
      <div v-if="selected">
        <div class="mb-1">{{ selected.name }}</div>
        <a v-if="!updated" @click="selected = null" class="link" style="cursor: pointer">Clear</a>
      </div>
      <template v-else>
        <breed-search v-model="selected"></breed-search>
        <validation-message v-if="validator.breedId" :message="validator.breedId"></validation-message>
      </template>
    </template>
  </labeled-form-control>
</template>

<script>
import { mapMutations } from 'vuex'
import { BreedCacheMixin } from '@/mixins/Registration/BreedCacheMixin'
import PetBreedSearchField from './PetBreedSearchField.vue'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'

export default {
  name: 'PetBreed',
  mixins: [BreedCacheMixin],
  props: {
    value: String,
    invite: Object,
    validator: Object,
    updated: Boolean
  },
  computed: {
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    breedId () {
      return this.value ? this.value : ''
    },
    breed () {
      return this.petBreedCaches ? this.petBreedCaches.find(pb => pb.id === this.breedId) : {}
    },
    selected: {
      get () {
        return this.breed
      },
      set (value) {
        if (value) {
          this.setPetBreed(value)
        }
        this.$emit('input', value ? value.id : '')
      }
    }
  },
  methods: {
    ...mapMutations('RegistrationBreeds', ['setPetBreed'])
  },
  components: {
    'breed-search': PetBreedSearchField,
    'labeled-form-control': LabeledFormControl,
    'validation-message': ValidationMessage
  }
}
</script>
