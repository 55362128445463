/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const AlternateContactCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationAlternateContact', [
            'alternateContactCache',
            'loadingAlternateContact',
            'loadedAlternateContact'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        contactTypeId(){
            return 2;
        },
        ownerTypeId() {
            return 2;
        }
    },
    watch: {
        inviteId() {
            this.refreshAlternateContact()
        }
    },
    methods: {
        ...mapActions('RegistrationAlternateContact', [
            'fetchAlternateContact'
        ]),
        refreshAlternateContact() {
            if (!this.loadedAlternateContact && this.inviteId) {
                this.tryFetchAlternateContact();
            }
        },
        tryFetchAlternateContact() {
            const self = this;
            self.error = '';
            self.fetchAlternateContact(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAlternateContact();
    }
}
