/* eslint-disable */
import { mapActions, mapState } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { PetHelper } from "@/helpers/PetHelper";

export const PetUpdateMixin = {
    props: {
        petIndex: Number
    },
    data() {
        return {
            error: '',
            sectionName: '',
            name: '',
            dateOfBirth: null,
            microChip: '',
            breedId: '',
            genderId: 2,
            neuteredId: 0,
            behaviourId: 0,
            deceased: false,
            petValidator: PetHelper.getPetValidator()
        }
    },
    computed: {
        ...mapState('RegistrationPets', [
            'petCaches'
        ]),
        petCache () {
            return this.petCaches ? this.petCaches[this.petIndex] : {};
        },
        petId() {
            return this.petCache ? this.petCache.id : '';
        },
        petUpdateModel() {
            return !!this.petCache ? Object.assign({}, this.petCache, {
                name: this.name,
                dateOfBirth: this.dateOfBirth,
                microChip: this.microChip,
                breedId: this.breedId,
                gender: Object.assign({}, this.petCache.gender, { id: this.genderId }),
                neutered: Object.assign({}, this.petCache.neutered, { id: this.neuteredId }),
                behaviour: Object.assign({}, this.petCache.behaviour, { id: this.behaviourId }),
                deceased: this.deceased
            }) : {};
        }
    },
    watch: {
        petCache() {
            this.refreshPetUploadModel();
        }
    },
    methods: {
        ...mapActions('RegistrationPets', [
            'updatePet',
        ]),
        refreshPetUploadModel() {
            this.sectionName = !!this.petCache ? this.petCache.name : '';
            this.name = !!this.petCache ? this.petCache.name : '';
            this.dateOfBirth = !!this.petCache ? this.petCache.dateOfBirth : '';
            this.microChip = !!this.petCache ? this.petCache.microChip : '';
            this.breedId = !!this.petCache ? this.petCache.breedId : '';
            this.genderId = !!this.petCache ? this.petCache.gender.id : 1;
            this.neuteredId = !!this.petCache ? this.petCache.neutered.id : 0;
            this.behaviourId = !!this.petCache ? this.petCache.behaviour.id : 0;
            this.deceased = !!this.petCache ? this.petCache.deceased : false;
        },
        tryUpdatePet() {
            const self = this;
            self.petValidator.check(self.petUpdateModel);
            if (!self.petValidator.isValid) {
                return;
            }
            self.error = '';
            return self.updatePet({ inviteId: self.inviteId, payload: self.petUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetUploadModel();
    }
}
