/* eslint-disable */
import { ErrorHelper } from "@/helpers/ErrorHelper"
import { WebApi } from "@/services/WebApi"

const state = () => ({
    petProductCaches: []
})

const getters = {
    findPetProductCache: (state) => key => state.petProductCaches.find(ppc => ppc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.petProductCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setPetProduct(state, { key, product }) {
        const existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.value = product;
            existing.loading = false;
        } else {
            state.petProductCaches.push({ key: key, value: product, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.petProductCaches.find(ppc => ppc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findPetProductBySiteId({ commit }, { siteId, petId }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: petId, loading: true });
            WebApi.get('Portal/' + siteId + '/Pet/' + petId + '/PetProduct')
                .then(response => {
                    commit('setPetProduct', { key: petId, product: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        resolve(false);
                    } else {
                        commit('setRetry', petId);
                        reject(error);
                    }
                })
                .finally(() => commit('setLoading', { key: petId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
