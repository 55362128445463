<template>
    <div class="booking">
        <div class="booking-wrapper">
            <petstar-ribbon></petstar-ribbon>
            <div class="booking-divider booking-divider-left">
                <booking-header></booking-header>
                <opening-hours></opening-hours>
            </div>
            <div class="booking-divider booking-divider-right">
                <display-banner></display-banner>
                <div class="font-size-h2 text-header-color px-4 mt-5 mb-3">Do you have an existing account?</div>
                <div class="px-5 d-flex flex-column justify-content-center align-items-center mb-5">
                    <div class="btn btn-outline-tertiary w-100 mb-3" @click="gotoLogin">
                        <div class="font-size-h1 m-2">Yes</div>
                    </div>
                    <div class="btn btn-outline-tertiary w-100" @click="gotoAnonymous">
                        <div class="font-size-h1 m-2">No</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin';
    import { SubscriptionCacheMixin } from '@/mixins/Booking/SubscriptionCacheMixin';
    import Header from './Header.vue';
    import OpeningHours from './OpeningHours.vue';
    import PetstarRibbon from './PetstarRibbon.vue'
    import DisplayBanner from './DisplayBanner.vue';

    export default {
        name: 'BookingSelect',
        mixins: [SelectedSiteIdMixin, SubscriptionCacheMixin],
        computed: {
            featureKey() {
                return 'unregisteredClientBookingAllowed'
            }
        },
        methods: {
            gotoLogin(){
                this.$router.push({ name: 'login', query: { siteId: this.selectedSiteId } })
            },
            gotoAnonymous(){
                this.$router.push({ name: 'anonymous', query: { siteId: this.selectedSiteId }})
            }
        },
        components: {
            'booking-header': Header,
            'opening-hours': OpeningHours,
            'petstar-ribbon': PetstarRibbon,
            'display-banner': DisplayBanner
        },
        created() {
            if(!this.subscriptionFeatureAllowed) {
                this.$router.push({ name: 'login', query: { siteId: this.selectedSiteId }})
            }
        }
    }
</script>

<style scoped>
  @media (max-width: 768px) {
    .booking-divider-right {
        height: max-content;
        min-height: 60vh !important;
    }
  }
</style>