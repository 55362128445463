<template>
    <nav class="bg-header">
      <div class="container mx-auto py-2">
        <div class="d-flex justify-content-between align-items-center">
          <template v-if="!!logo">
            <img v-if="isLogoProperlyLoaded" :src="logoUrl" height="43" @error="handleImageError"/>
            <template v-else>
              <i class="fa fas fa-paw text-header-color" style="font-size: 2.75rem"></i>
            </template>
          </template>
          <template v-else>
            <i class="fa fas fa-paw text-header-color" style="font-size: 2.75rem"></i>
          </template>
          <div class="text-header-color">
            <div class="font-size-h3 font-weight-bold">
              {{ selectedCompanyName }}
            </div>
            <p class="p2 mb-0">{{ selectedTagLine }}</p>
          </div>
          <slot></slot>
        </div>
      </div>
    </nav>
</template>

<script>
import { LogoCacheMixin } from '@/mixins/Logo/LogoCacheMixin'
import { BrandingSettingMixin } from '@/mixins/Shared/BrandingSettingMixin'

export default {
  name: 'PageNavBar',
  mixins: [LogoCacheMixin, BrandingSettingMixin],
  data () {
    return {
      isLogoProperlyLoaded: true
    }
  },
  methods: {
    handleImageError () {
      if (this.logo) {
        this.isLogoProperlyLoaded = false
      }
    }
  }
}
</script>
