import Vue from 'vue'
import VueRouter from 'vue-router'
import ExternalView from '@/views/ExternalView.vue'
import ContractViewer from '@/components/Contract/ContractViewer.vue'
import RedirectorView from '@/views/RedirectorView.vue'
import AppointmentViewer from '@/components/Appointment/AppointmentViewer.vue'
import RegistrationViewer from '@/components/Registration/RegistrationViewer.vue'
import UnsubscribeViewer from '@/components/Unsubscribe/UnsubscribeViewer.vue'
import PasswordSet from '@/components/Authentication/PasswordSet.vue'
import Authentication from '@/components/Authentication/Authentication.vue'
import ForgotPassword from '@/components/Authentication/ForgotPassword.vue'
import Authenticated from '@/components/Booking/Authenticated.vue'
import Anonymous from '../components/Booking/Anonymous.vue'
import Home from '@/components/Booking/Home.vue'
import Public from '@/components/Authentication/Public.vue'
import BookingSuggestion from '@/components/BookingSuggestion/BookingSuggestion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/public',
    name: 'home',
    component: Public,
    children: [
      {
        path: 'home',
        name: 'bookingHome',
        component: Home
      },
      {
        path: 'anonymous',
        name: 'anonymous',
        component: Anonymous
      },
      {
        path: 'booking',
        name: 'booking',
        component: Authenticated
      },
      {
        path: 'login',
        name: 'login',
        component: Authentication
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword
      },
      {
        path: 'booking-suggestion',
        name: 'bookingSuggestion',
        component: BookingSuggestion
      }
    ]
  },
  {
    path: '',
    name: 'external',
    component: ExternalView,
    children: [
      { path: 'contract', name: 'contract', component: ContractViewer },
      { path: 'appointment', name: 'appointment', component: AppointmentViewer },
      { path: 'registration', name: 'registration', component: RegistrationViewer },
      { path: 'unsubscribe', name: 'unsubscribe', component: UnsubscribeViewer },
      { path: 'public/password-set', name: 'passwordSet', component: PasswordSet }
    ]
  },
  {
    path: '/i',
    name: 'redirect',
    component: RedirectorView
  },
  {
    path: '*',
    redirect: ''
  }
]

const router = new VueRouter({
  routes
})

export default router
