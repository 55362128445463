export const SignatureHelper = {
  getSignatureValidator () {
    return {
      nameError: '',
      signatureError: '',
      isValid: false,
      validate (model) {
        this.nameError = model.signedName ? '' : 'Please enter your full name.'
        this.signatureError = model.signedText ? '' : 'Please mark your signature.'
        this.isValid = !this.nameError && !this.signatureError
      }
    }
  }
}
