/* eslint-disable */
import { WebApi } from "@/services/WebApi"
import moment from 'moment-timezone'

const state = () => ({
    timeZone: null,
    selectedTimeZoneId: moment.tz.guess(),
    loadingTimeZone: false,
    loadedTimeZone: false,
    retryTimeZone: false
})

const mutations = {
    setTimeZone(state, timeZone) {
        state.timeZone = timeZone;
        state.loadedTimeZone = true;
        if (timeZone.timeZoneId !== state.selectedTimeZoneId && moment.tz.names().includes(timeZone.timeZoneId)) {
            moment.tz.setDefault(timeZone.timeZoneId);
            state.selectedTimeZoneId = moment().tz();
        }
    },
    setLoading(state, loading) {
        state.loadingTimeZone = !!loading;
    },
    setRetry(state, retry) {
        state.retryTimeZone = !!retry;
    }
}

const actions = {
    getTimeZoneBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/' + siteId + '/TimeZone')
                .then(response => {
                    commit('setTimeZone', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
