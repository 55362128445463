<template>
    <div class="w-100 text-center">
        <label class="text-danger mb-0" ref="validationRef">
            {{ message }}
        </label>
    </div>
</template>

<script>
export default {
  name: 'ValidationMessage',
  props: ['message'],
  mounted () {
    this.$nextTick(() => {
      this.$refs.validationRef.scrollIntoView({ behavior: 'smooth' })
    })
  }
}
</script>
