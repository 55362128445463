<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <display-banner></display-banner>
            <template v-if="loadingPets">
                <span class="inline-loader d-md-block d-none h-100"></span>
                <span class="inline-loader d-md-none d-block"></span>
            </template>
            <template v-else-if="pets.length > 0">
                <div class="booking-divider-title text-header-color">Your pet details</div>
                <div class="timeslots">
                    <validation-message v-if="message" :message="message" class="mb-2"></validation-message>
                    <pet-select-item v-for="pet in pets" :key="pet.id" :pet="pet"></pet-select-item>
                    <pet-select-all v-if="pets.length > 1"></pet-select-all>
                </div>
            </template>
            <div v-else-if="noPets" class="font-size-h2 text-header-color mt-3 mt-md-1 px-4">NO REGISTERED PETS</div>
        </div>
        <div class="fixed-buttons" v-if="pets.length > 0">
            <button class="btn btn-secondary w-100" @click="next" :disabled="loadingPets">Next</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { PetCacheMixin } from '@/mixins/Booking/PetCacheMixin';
    import { ClientUserCheckingMixin } from '@/mixins/Booking/ClientUserCheckingMixin';
    import PetSelectItem from './PetSelectItem.vue';
    import PetSelectAll from './PetSelectAll.vue';
    import DisplayBanner from '../DisplayBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';
    
    export default {
        name: 'PetSelect',
        mixins: [ClientUserCheckingMixin, PetCacheMixin],
        data() {
            return {
                message: '',
                isValid: false
            }
        },
        computed: {
            ...mapState('BookingUpload', [
               'selectedPets'
            ]),
            noPets() {
                return !!this.attempted && this.pets.length < 1 && !this.loadingPets
            }
        },
        methods: {
            next() {
                this.check();
                if(this.isValid) {
                    this.$emit('next');
                    this.$emit('toLastPet', false);
                }
            },
            check() {
                this.isValid = this.selectedPets.length > 0;
                this.message = this.isValid ? '' : 'Please select pet(s)';
            }
        },
        components: {
            'pet-select-item': PetSelectItem,
            'pet-select-all': PetSelectAll,
            'display-banner': DisplayBanner,
            'validation-message': ValidationMessage
        }
    }
</script>