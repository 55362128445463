<template>
    <div>
        <no-site v-if="noSiteId"></no-site>
        <template v-else>
            <page-loader v-if="fetchingSites"></page-loader>
            <template v-else>
                <no-site v-if="sites.length < 1"></no-site>
                <page-loader v-else-if="loading"></page-loader>
                <feature-disabled v-else-if="!appSettingEnabled || !siteBookingSettingEnabled"></feature-disabled>
                <template v-else-if="subscriptionFeatureAllowed">
                    <slot></slot>
                </template>
                <no-subscription v-else-if="!subscriptionFeatureAllowed"></no-subscription>
            </template>
        </template>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapMutations } from 'vuex';
    import moment from 'moment-timezone';
    import { SubscriptionCacheMixin } from '@/mixins/Booking/SubscriptionCacheMixin';
    import { BookingSettingsCacheMixin } from '@/mixins/Booking/BookingSettingsCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import { SiteCacheMixin } from '@/mixins/Booking/SiteCacheMixin';
    import { TimeZoneCacheMixin } from '@/mixins/Booking/TimeZoneCacheMixin';
    import { SelectedDateMixin } from '@/mixins/Booking/SelectedDateMixin';
    import { RecaptchaSettingsCacheMixin } from '@/mixins/Booking/RecaptchaSettingsCacheMixin';
    import { TimeFormatMixin } from '@/mixins/Booking/TimeFormatMixin';
    import { DateFormatMixin } from '@/mixins/Booking/DateFormatMixin';
    import PageLoader from '../Loading/PageLoader.vue';
    import NoSiteFound from './NoSiteFound.vue'
    import NoSubscription from './NoSubscription.vue';
    import FeatureDisabled from './FeatureDisabled.vue';


    export default {
        name: 'SettingsChecker',
        mixins: [
            SiteCacheMixin, 
            SubscriptionCacheMixin, 
            SiteBookingSettingMixin, 
            BookingSettingsCacheMixin, 
            TimeZoneCacheMixin, 
            SelectedDateMixin, 
            RecaptchaSettingsCacheMixin, 
            TimeFormatMixin,
            DateFormatMixin],
        computed: {
            featureKey() {
                return 'onlineBooking';
            },
            noSiteId() {
                return !this.siteId;
            },
            noSubscription() {
                return !!this.attemptedSubscription && !this.subscription && !this.fetchingSubscription;
            },
            appSettingEnabled() {
                return !!this.attemptedBookingSettings && !!this.enabled && !this.fetchingBookingSettings;
            },
            loading() {
                return !!this.fetchingSubscription || !!this.loadingSetting || !!this.fetchingBookingSettings || !!this.loadingTimeZone || !!this.fetchingRecaptchaSettings
            }
        },
        watch: {
            selectedTimeZoneId() {
                this.refreshBookingSelectedDate()
            },
            siteBookingSettingServiceMinNotice() {
                this.refreshBookingSelectedDate()
            },
            siteBookingSettingServiceMaxNotice() {
                this.refreshBookingSelectedDate()
            }
        },
        methods: {
            ...mapMutations('BookingSelectedDate', [
                'setDatePickerStartDate',
                'setDatePickerEndDate'
            ]),
            refreshBookingSelectedDate() {
                const datePickerInitialDate = moment().add(this.siteBookingSettingServiceMinNotice, this.siteBookingSettingServiceMinNoticeUnit)._d;
                const datePickerLowerLimit = moment().subtract(1, 'days').add(this.siteBookingSettingServiceMinNotice, this.siteBookingSettingServiceMinNoticeUnit)._d;
                const datePickerUpperLimit = moment().subtract(1, 'days').add(this.siteBookingSettingServiceMaxNotice, this.siteBookingSettingServiceMaxNoticeUnit)._d;
                this.setSelectedDate(datePickerInitialDate);
                this.setDatePickerStartDate(datePickerLowerLimit);
                this.setDatePickerEndDate(datePickerUpperLimit);
            }
        },
        created() {
            this.refreshBookingSelectedDate();
        },
        components: {
            'page-loader': PageLoader,
            'no-site': NoSiteFound,
            'no-subscription': NoSubscription,
            'feature-disabled': FeatureDisabled
        }
    }
</script>