<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <display-banner></display-banner>
            <div class="font-size-h2 text-header-color px-4 my-5">BOOKING PORTAL FOR NEW CLIENTS IS CURRENTLY NOT AVAILABLE.</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import DisplayBanner from '../DisplayBanner.vue'

    export default {
        name: 'AnonymousBookingDisabled',
        components: {
            'display-banner': DisplayBanner
        }
    }
</script>