/* eslint-disable */
<template>
    <div class="timeslots timeslots-wrapper px-0">
        <div v-if="closed" class="text-primary">Choose another day for a furry-tastic experience!<br/>We are closed on {{hours.dayOfWeek.description}}s.</div>
        <div v-else class="d-flex flex-wrap justify-content-center">
            <div v-if="timeSlots.length === 0 && !loading" class="text-primary mt-5">There is no available time slot for the chosen day.</div>
            <template v-else>
                <time-slots-item v-for="(timeSlot, index) in timeSlots"
                    :time-slot="timeSlot"
                    :key="timeSlot.id"
                    :index="index"
                    @statusChange="handleStatusChange">
                </time-slots-item>
                <div v-if="noAvailableSlots" class="text-primary mt-5">There is no available time slot for the chosen day.</div>
            </template>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { OpeningHourCacheMixin } from '@/mixins/Booking/OpeningHourCacheMixin';
    import { mapState } from 'vuex';
    import TimeSlotsItem from './TimeSlotsItem.vue';
    import moment from 'moment-timezone';

    export default {
        name: 'TimeSlots',
        mixins:[OpeningHourCacheMixin],
        data() {
            return {
                loading: false,
                available: true
            }
        },
        computed: {
            ...mapState('BookingSelectedDate', [
                'selectedDate'
            ]),
            ...mapState('BookingUpload', [
                'duration'
            ]),
            ...mapState('TimeZone', [
                'selectedTimeZoneId'
            ]),
            hours() {
                return !!this.openingHours ? this.openingHours.find(oh => oh.dayOfWeek.id === this.selectedDate.getDay()) : null;
            },
            closed() {
                return !!this.hours ? this.hours.closed : false;
            },
            dateString() {
                return moment(this.selectedDate).tz(moment.tz.guess()).format('YYYY-MM-DD');
            },
            openingTimeString() {
                return !!this.hours ? this.hours.openingLocal.split('T')[1] : '';
            },
            closingTimeString() {
                return !!this.hours ? this.hours.closingLocal.split('T')[1] : '';
            },
            openingTime() {
                return !!this.openingTimeString ? moment.tz(`${this.dateString}T${this.openingTimeString}`, this.selectedTimeZoneId): null;
            },
            closingTime() {
                return !!this.closingTimeString ? moment.tz(`${this.dateString}T${this.closingTimeString}`, this.selectedTimeZoneId) : null;
            },
            timeSlots() {
                const slots = [];
                if (!this.openingTime || !this.closingTime) {
                    return slots;
                }
                for (let startTime = this.openingTime.clone(); startTime < this.closingTime; startTime.add(this.duration, 'minutes')) {
                    const endTime = startTime.clone().add(this.duration, 'minutes');
                    // Check if a slot's startTime is not yet in the past 
                    // Check if a slot's endTime is within business hours
                    if (moment().isBefore(startTime) && endTime <= this.closingTime) {
                        slots.push({
                            id: Math.random().toString(36).substring(2, 10),
                            startTime: startTime.format(),
                            endTime: endTime.format(),
                            status: {
                                loading: false,
                                available: true
                            }
                        });
                    }
                }

                return slots;
            },
            noAvailableSlots() {
                return !this.available && !this.loading
            }
        },
        methods: {
            handleStatusChange(value) {
                this.$set(this.timeSlots, value.index, value.timeSlot);
                this.updateStatus();
            },
            updateStatus() {
                this.loading = this.timeSlots.some(ts => ts.status && !!ts.status.loading);
                this.available = this.timeSlots.some(ts => ts.status && !!ts.status.available);
            }
        },
        components: {
            'time-slots-item': TimeSlotsItem
        }
    }
</script>
