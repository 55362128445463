/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    loadingPetNotes: false,
    loadedPetNotes: false,
    petNoteCaches: []
})

const mutations = {
  setPetNotes(state, notes) {
    notes.forEach((note) => {
      const existingNoteIndex = state.petNoteCaches.findIndex(
        (existingNote) =>
          existingNote.ownerId === note.ownerId &&
          existingNote.noteType.id === note.noteType.id
      );
      if (existingNoteIndex !== -1) {
        // If the note with matching ownerType.id and noteType.id exists, update it
        state.petNoteCaches[existingNoteIndex] = { ...note };
      } else {
        // Otherwise, add the new note to the array
        state.petNoteCaches.push({ ...note });
      }
    });
    state.loadedPetNotes = true;
  },
  setLoading (state, value) {
      state.loadingPetNotes = !!value;
  }
}

const actions = {
  findPetNotes ({ commit }, inviteId ) {
      return new Promise((resolve, reject) => {
          commit('setLoading', true)
          WebApi.get('ClientOnboardingInvite/' + inviteId + '/Pet/Note' )
              .then(response => {
                  commit('setPetNotes', response.data);
                  resolve(response)
              })
              .catch(error => reject(error))
              .finally(() => commit('setLoading', false));
      })
  },
  addPetNote({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        WebApi.post('ClientOnboardingInvite/' + inviteId + '/Pet/Note', payload)
            .then(response => {
                commit('setPetNotes', [response.data]);
                resolve(response);
            })
            .catch(error => reject(error))
            .finally(() => commit('setLoading', false));
    })
  },
  updatePetNote({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        WebApi.put('ClientOnboardingInvite/' + inviteId + '/Pet/Note', payload)
            .then(response => {
                commit('setPetNotes', [response.data]);
                resolve(response);
            })
            .catch(error => reject(error))
            .finally(() => commit('setLoading', false));
    });
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}