/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const ClientNoteCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationClientNotes', [
            'clientNoteCaches',
            'loadingClientNotes',
            'loadedClientNotes'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        noteTypeId(){
            return 3;
        },
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            return 2;
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },  
        clientNoteCache(){
            return this.clientNoteCaches.length > 0 ? this.clientNoteCaches.find((obj) => {
                return obj.ownerType.id === this.ownerTypeId && obj.noteType.id === this.noteTypeId
            }) : null;
        }
    },
    watch: {
        inviteId() {
            this.refreshClientNotes()
        }
    },
    methods: {
        ...mapActions('RegistrationClientNotes', [
            'findClientNotes'
        ]),
        refreshClientNotes() {
            if (!this.loadedClientNotes) {
                this.tryFindClientNotes();
            }
        },
        tryFindClientNotes() {
            const self = this;
            if (!this.ownerQueryModel.ownerId || !this.ownerQueryModel.ownerTypeId) {
                return;
            }
            self.error = '';
            self.findClientNotes(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientNotes();
    }
}
