<template>
    <div class="d-flex flex-column">
        <div class="d-flex w-100">
            <input v-model="postcode" class="form-control search-input" placeholder="Enter postcode" type="search" :disabled="clientAddressUpdated"/>
            <div class="form-control search-icon">
                <span v-if="searchingPostcode" class="inline-loader"></span>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </div>
        </div>
        <div v-if="showPostcodeResults" class="dropdown-menu show" role="presentation" style="margin-left: 15px !important; width: calc(100% - 30px) !important; max-height: 300px; overflow: scroll;">
            <search-result v-for="(address, index) in addresses" :key="index" :address="address" @addressClicked="onAddressClicked" ></search-result>
            <template v-if="addresses.length === 0">
                <a role="option" class="dropdown-item" style="font-size: 0.75rem; cursor: pointer"> No results found </a>
            </template>
        </div>
    </div>
</template>

<script>
import { PostcodeSearchMixin } from '@/mixins/Registration/PostcodeSearchMixin'
import ClientAddressFieldLookupResult from './ClientAddressFieldLookupResult.vue'

export default {
  name: 'PostcodeLookup',
  mixins: [PostcodeSearchMixin],
  props: {
    clientAddressUpdated: Boolean
  },
  methods: {
    onAddressClicked (address) {
      this.$emit('input', address)
    }
  },
  components: {
    'search-result': ClientAddressFieldLookupResult
  }
}
</script>

<style scoped>
.search-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-icon {
    width: max-content;
    padding: 0.375rem 0.75rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
