/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const BreedProductCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BreedProducts', [
            'findBreedProductCache'
        ]),
        breedId() {
            console.log('OVERRIDE COMPUTED - breedId');
        },
        breedProductCache() {
            return this.findBreedProductCache(this.breedId);
        },
        breedProduct() {
            return !!this.breedProductCache ? this.breedProductCache.value : null;
        },
        loadingBreedProduct() {
            return !!this.breedProductCache && this.breedProductCache.loading;
        }
    },
    watch: {
        breedId() {
            this.refreshBreedProduct();
        },
        selectedSiteId() {
            this.refreshBreed();
        }
    },
    methods: {
        ...mapActions('BreedProducts', [
            'findBreedProductBySiteId'
        ]),
        refreshBreedProduct() {
            if (!this.breedProductCache || !!this.breedProductCache.retry) {
                this.tryFindBreedProductBySiteId();
            }
        },
        tryFindBreedProductBySiteId() {
            const self = this;
            if (!self.breedId) {
                return;
            }
            self.error = '';
            self.findBreedProductBySiteId({siteId: self.selectedSiteId, siteBreedId: self.breedId})
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBreedProduct();
    }
}
