/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper';

export const BreedCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationBreeds', [
            'loadingPetBreeds',
            'loadedPetBreeds',
            'petBreedCaches'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
    },
    methods: {
        ...mapActions('RegistrationBreeds', [
            'fetchPetBreeds'
        ]),
        refreshPetBreeds() {
            if (!this.loadedPetBreeds) {
                this.tryFetchPetBreeds();
            }
        },
        tryFetchPetBreeds() {
            const self = this;
            self.error = '';
            self.fetchPetBreeds(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetBreeds();
    }
}
