/* eslint-disable */
import { mapActions } from 'vuex';
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { VetContactCacheMixin } from "./VetContactCacheMixin";

export const VetContactAddMixin = {
    mixins: [VetContactCacheMixin],
    data() {
        return {
            name: '',
            mobile: '',
            email: '',
            otherInfo: ''
        }
    },
    computed: {
        vetContactAddModel() {
            return Object.assign({}, {
                contactTypeId: this.contactTypeId,
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            });
        }
    },
    methods: {
        ...mapActions('RegistrationVetContact', [
            'addVetContact'
        ]),
        tryAddVetContact() {
            const self = this;
            self.error = '';
            return self.addVetContact({ inviteId: self.inviteId, payload: self.vetContactAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
