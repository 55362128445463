<template>
  <collapsed-section :title="sectionHeaderTitle">
    <error-banner v-if="error" :message="error"></error-banner>
    <labeled-form-control label="Name">
      <input v-model="name" class="form-control" type="text" value="Pet Name" :disabled="petDetailsUpdated"/>
      <validation-message v-if="petValidator.name" :message="petValidator.name"></validation-message>
    </labeled-form-control>
    <pet-breed v-model="breedId" :invite="invite" :validator="petValidator" :updated="petDetailsUpdated"></pet-breed>
    <pet-dob v-model="dateOfBirth" :updated="petDetailsUpdated"></pet-dob>
    <pet-gender v-model="genderId" :updated="petDetailsUpdated"></pet-gender>
    <pet-neutered v-model="neuteredId" :updated="petDetailsUpdated"></pet-neutered>
    <pet-medical-note :invite="invite" :pet-index="this.petIndex" :updated="petDetailsUpdated"></pet-medical-note>
  </collapsed-section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { PetUpdateMixin } from '@/mixins/Registration/PetUpdateMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import CollapsedSection from '@/components/Layout/CollapsedSection.vue'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'
import PetBreed from './PetBreed.vue'
import PetDob from './PetDob.vue'
import PetGender from './PetGender.vue'
import PetNeutered from './PetNeutered.vue'
import PetMedicalNote from './PetMedicalNote.vue'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'PetDetailsSection',
  mixins: [PetUpdateMixin, ClientOnboardingInviteCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedPetDetailEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    petDetailsUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.petDetailsUpdated : false
    },
    updatedPets () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.updatedPets : null
    },
    sectionHeaderTitle () {
      return this.sectionName + "'s Details"
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedPetDetail']),
    ...mapMutations('ClientOnboardingInvites', ['setUpdatedPet'])
  },
  watch: {
    async updatedPetDetailEvent (value) {
      if (value) {
        const petUpdated = this.updatedPets ? this.updatedPets.includes(this.petId) : false
        if (value === this.petIndex + 1 && !petUpdated) {
          try {
            await this.tryUpdatePet()
          } finally {
            this.setUpdatedPet({ inviteId: this.inviteId, petId: this.petId })
            this.triggerUpdatedPetDetail()
          }
        } else if (value === this.petIndex + 1 && petUpdated) {
          this.triggerUpdatedPetDetail()
        }
      }
    }
  },
  components: {
    'collapsed-section': CollapsedSection,
    'labeled-form-control': LabeledFormControl,
    'pet-breed': PetBreed,
    'pet-dob': PetDob,
    'pet-gender': PetGender,
    'pet-neutered': PetNeutered,
    'pet-medical-note': PetMedicalNote,
    'validation-message': ValidationMessage,
    'error-banner': ErrorBanner
  }
}
</script>
