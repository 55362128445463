<template>
  <div class="row form-group">
    <div class="col-md-4 text-left">
      <label>Neutered</label>
    </div>
    <div class="col-md-8">
        <div class="col">
            <div class="row">
                <div class="d-flex">
                    <input v-model="neuteredId" :value="1" type="radio" :disabled="updated"/><span class="ml-2">Unknown</span>
                </div>
                <div class="ml-4 d-flex">
                    <input v-model="neuteredId" :value="2" type="radio" :disabled="updated"/><span class="ml-2">Yes</span>
                </div>
                <div class="ml-4 d-flex">
                    <input v-model="neuteredId" :value="3" type="radio" :disabled="updated"/><span class="ml-2">No</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PetNeutered',
  props: {
    value: Number,
    updated: Boolean
  },
  computed: {
    neuteredId: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
