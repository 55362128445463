<template>
  <div class="collapsible">
    <div class="collapsible-header" @click="toggleCollapsible">
      <div class="col-8">
        <div>{{title}}</div>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <i class="icon fa fas fa-chevron-circle-down" :class="{'icon-rotate': isOpen}"></i>
      </div>
    </div>
    <div class="collapsible-content" v-bind:class="{'collapsible-content-show': isOpen}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsedSection',
  props: {
    title: String
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleCollapsible () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
.collapsible {
  margin-block: 1rem;
}

.collapsible-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #CCC;
  cursor: pointer;
  padding-block: 0.25rem;
}

.icon {
  font-size: 1.25rem;
  transition: transform 0.3s;
}

.icon-rotate {
  transform: rotate(180deg);
}

.collapsible-content {
  padding-block: 0.5rem;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.3s ease-out, opacity 0.3s ease-out;
}

.collapsible-content-show {
  height: max-content;
  opacity: 1;
  transition: height 0.3s ease-out, opacity 0.3s ease-out;
}
</style>
