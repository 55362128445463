/* eslint-disable */
<template>
    <div class="timeslots-item">
        <div v-if="available"
            class="timeslots-item_btn"
            :class="{'active': selected}"
            @click="selectTimeSlot">{{formattedStartTime}}
        </div>
        <resource-conflict v-for="resource in unhiddenResources"
            :key="resource.id"
            :resource-id="resource.id"
            :time-slot="timeSlot"
            v-model="resourceConflictStatus[resource.id]">
        </resource-conflict>
    </div>
</template>

<script>
/* eslint-disable */
    import moment from 'moment-timezone';
    import { mapState, mapMutations } from 'vuex'
    import { ResourceCacheMixin } from '@/mixins/Booking/ResourceCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import { TimeFormatMixin } from '@/mixins/Booking/TimeFormatMixin';
    import ResourceConflict from '@/components/Booking/ResourceConflict/ResourceConflict.vue';

    export default {
        name: 'BookingTimeSlotsItem',
        mixins: [ResourceCacheMixin, SiteBookingSettingMixin, TimeFormatMixin],
        props: {
            timeSlot: Object,
            index: 0
        },
        data() {
            return {
                resourceConflictStatus: {}
            };
        },
        computed: {
            ...mapState('BookingUpload', [
                'startUtc',
                'selectedPets'
            ]),
            loading() {
                return Object.values(this.resourceConflictStatus).some(resource => resource.loading);
            },
            startTimeUtc() {
                return moment(this.timeSlot.startTime).utc().format();
            },
            selected() {
                return this.startUtc === this.startTimeUtc;
            },
            formattedStartTime() {
                return moment(this.timeSlot.startTime).format(this.momentJSTimeFormat);
            },
            availableResourceIds() {
                return Object.keys(this.resourceConflictStatus).filter(id => this.resourceConflictStatus[id].available);
            },
            availableResourceCount() {
                return this.availableResourceIds.length;
            },
            available() {
                return this.siteBookingSettingSinglePetResourceEnabled ? 
                    this.availableResourceCount >= this.selectedPets.length : 
                    this.availableResourceCount > 0; 
            },
            status() {
                return {
                    available: this.available,
                    loading: this.loading
                }
            },
        },
        watch: {
            status() {
                this.$emit('statusChange', {
                    index: this.index,
                    timeSlot: {
                        ...this.timeSlot,
                        status: this.status
                    }
                })
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'setResourceIds',
                'setStartUtc'
            ]),
            selectTimeSlot() {
                this.setResourceIds(this.availableResourceIds)
                this.setStartUtc(this.startTimeUtc)
            }
        },
        components: {
            'resource-conflict': ResourceConflict 
        }
    }
</script>
