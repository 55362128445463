/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapGetters, mapActions } from 'vuex';

export const ClientOnboardingInviteCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('ClientOnboardingInvites', [
            'findClientOnboardingInviteCache'
        ]),
        inviteId(){
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        clientOnboardingInviteCache() {
            return this.findClientOnboardingInviteCache(this.inviteId);
        },
        clientOnboardingInvite() {
            return this.clientOnboardingInviteCache ? this.clientOnboardingInviteCache.value : null;
        },
        clientOnboardingInviteStatusId() {
            return this.clientOnboardingInvite ? this.clientOnboardingInvite.status.id : null;
        },
        isClientOnboardingInviteCompleted() {
            return this.clientOnboardingInvite ? this.clientOnboardingInvite.completed : false;
        },
        loadingClientOnboardingInvite() {
            return this.clientOnboardingInviteCache && this.clientOnboardingInviteCache.loading;
        }
    },
    watch: {
        inviteId() {
            this.refreshClientOnboardingInvite();
        }
    },
    methods: {
        ...mapActions('ClientOnboardingInvites', [
            'fetchClientOnboardingInvite',
        ]),
        refreshClientOnboardingInvite() {
            if (!this.clientOnboardingInviteCache || !!this.clientOnboardingInviteCache.retry) {
                this.tryFetchClientOnboardingInvite();
            }
        },
        tryFetchClientOnboardingInvite() {
            const self = this;
            if (!self.inviteId) {
                return;
            }
            self.error = '';
            self.fetchClientOnboardingInvite(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientOnboardingInvite();
    }
}
