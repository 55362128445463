/* eslint-disable */
import { WebApi } from '../../services/WebApi';

const state = () => ({
    loadingBookingSuggestion: false,
    bookingSuggestion: null
})

const mutations = {
    setLoading(state, value) {
        state.loadingBookingSuggestion = !!value;
    },
    setBookingSuggestion(state, bookingSuggestion) {
        state.bookingSuggestion = bookingSuggestion
    }
}

const actions = {
    fetchBookingSuggestionByToken({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.post('BookingSuggestion/Token', payload)
                .then(response => {
                    commit('setBookingSuggestion', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    acceptBookingSuggestion({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.put('BookingSuggestion/Accept', payload)
                .then(response => {
                    commit('setBookingSuggestion', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
