/* eslint-disable */
const state = () => ({
    selectedDate: new Date(),
    localDateString: '',
    datePickerStartDate: null,
    datePickerEndDate: null,
});

const mutations = {
    setSelectedDate(state, date) {
        state.selectedDate = date;
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        state.localDateString = `${year}-${month}-${day}`;
    },
    setDatePickerStartDate(state, date) {
        state.datePickerStartDate = date
    },
    setDatePickerEndDate(state, date) {
        state.datePickerEndDate = date;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
