/* eslint-disable */
import router from '@/router'

import { WebApi } from '../../services/WebApi'
const state = () => ({
    user: null,
    loading: false
})

const getters = {
    isAuthenticated: state => !!state.user,
    isLoading: state => state.loading
}

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setLoading(state, loading) {
        state.loading = !!loading;
    },
    clearUser(state) {
        state.user = null;
    }
}

const actions = {
    login({ commit }, {siteId, request}) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.post('Authentication/Site/' + siteId + '/Credentials/Login', request)
                .then(response => {
                    commit('setUser', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    logout({ commit, getters }) {
        if (getters.isLoading) {
            return;
        }
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.post('Authentication/Credentials/Logout')
                .then(() => {
                    commit('clearUser');
                    resolve();
                })
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
