import VueCookies from 'vue-cookies'

export const SiteHelper = {
  getDefaultSite () {
    const site = VueCookies.get('selected_site')
    return site || {
      id: '',
      name: 'Portal'
    }
  },
  setDefaultSite (site) {
    VueCookies.set('selected_site', site, -1, null, null, true, 'Strict')
  },
  clearDefaultSite () {
    VueCookies.remove('selected_site')
  }
}
