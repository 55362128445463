/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const BookingTermCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingTerm', [
            'loadedBookingTerm',
            'loadingBookingTerm',
            'attempted',
            'bookingTerm'
        ]),
        attemptedBookingTerm() {
            return !!this.attempted
        }
    },
    watch: {
        selectedSiteId() {
            this.refreshBookingTerm();
        }
    },
    methods: {
        ...mapActions('BookingTerm', [
            'fetchBookingTermBySiteId'
        ]),
        refreshBookingTerm() {
            if (!this.loadedBookingTerm && !this.loadingBookingTerm) {
                this.tryFetchBookingTermBySiteId();
            }
        },
        tryFetchBookingTermBySiteId() {
            const self = this;
            if(!self.selectedSiteId) {
                return;
            }
            self.error = '';
            self.fetchBookingTermBySiteId(self.selectedSiteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingTerm();
    }
}
