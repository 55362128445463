/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const PetDurationCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('PetDurations', [
            'petDurations',
            'loadingPetDurations',
            'retryPetDurations'
        ])
    },
    watch: {
        selectedSiteId() {
            this.refreshPetDurations();
        }
    },
    methods: {
        ...mapActions('PetDurations', [
            'getPetDurationsBySiteId'
        ]),
        refreshPetDurations() {
            if (this.petDurations.length == 0 || !!this.retryPetDurations) {
                this.tryGetPetDurationsBySiteId();
            }
        },
        tryGetPetDurationsBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getPetDurationsBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetDurations();
    }
}
