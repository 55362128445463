import store from '@/store'
import axios from 'axios'
import router from '../router'
import { RoutingHelper } from '@/helpers/RoutingHelper'

export const WebApi = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_AXIOS_WEB_BASE_URL || '#{Project.WebApi.Endpoint}'
})

WebApi.interceptors.request.use(config => {
  const siteId = store.state.Sites.selected.id
  if (siteId) {
    config.headers.AccountSiteId = siteId
  }
  return config
})

WebApi.interceptors.response.use(
  response => response,
  error => {
    const status = (error.response && error.response.status) ?? ''
    if (status === 401 || status === 403) {
      if (RoutingHelper.isOnlineBookingPath(router.currentRoute.path)) {
        const siteId = router.currentRoute.query.siteId ?? ''
        return store.dispatch('Authentication/logout')
          .then(() => {
            return router.push({ name: 'login', query: { siteId } })
          })
          .then(() => { window.location.reload() })
      }
    }
    return Promise.reject(error)
  }
)
