<template>
    <section class="group">
        <div class="form-group">
            <div class="col-md-12">
                <button class="fa" type="button" @click="onCancel">
                  <span class="fa-stack fa-primary">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-arrow-left fa-stack-1x fa-inverse"></i>
                  </span>
                </button>
            </div>
        </div>
        <sumup-widget v-if="providerTypeId === 1" :checkout="checkout" @completed="onComplete"
            @errored="onError"></sumup-widget>
    </section>
</template>

<script>
import SumUpWidget from './Widgets/SumUpWidget.vue'

export default {
  name: 'PaymentProcessor',
  props: {
    checkout: {
      type: Object,
      required: true
    }
  },
  computed: {
    providerTypeId () {
      return this.checkout.providerType.id
    }
  },
  methods: {
    onCancel () {
      this.$emit('cancelled')
    },
    onComplete () {
      this.$emit('completed')
    },
    onError (message) {
      this.$emit('completed', message)
    }
  },
  components: {
    'sumup-widget': SumUpWidget
  }
}
</script>
