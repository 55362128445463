import { FormatOptionsTimeCacheMixin } from './FormatOptionsTimeCacheMixin'
import { FormatSettingTimeCacheMixin } from './FormatSettingTimeCacheMixin'

export const TimeFormatMixin = {
  mixins: [FormatOptionsTimeCacheMixin, FormatSettingTimeCacheMixin],
  computed: {
    timeFormatId () {
      return this.timeFormatSetting ? this.timeFormatSetting.timeFormatId : 0
    },
    timeFormatOption () {
      return this.timeFormatOptions.find(dfo => dfo.id === this.timeFormatId)
    },
    momentJSTimeFormat () {
      return this.timeFormatOption ? this.timeFormatOption.momentJS : 'DD MMM YYYY'
    },
    cSharpTimeFormat () {
      return this.timeFormatOption ? this.timeFormatOption.cSharp : 'dd MMM yyyy'
    }
  }
}
