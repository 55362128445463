<template>
    <button v-if="subscriptionFeatureAllowed"
        class="btn btn-outline-secondary w-100"
        @click="gotoHome">
        <i class="fas fa-home"></i>
        Home
    </button>
</template>

<script>
/* eslint-disable */
    import { SubscriptionCacheMixin } from '@/mixins/Booking/SubscriptionCacheMixin'

    export default {
        name: 'HomeButton',
        mixins: [SubscriptionCacheMixin],
        computed: {
            featureKey() {
                return 'unregisteredClientBookingAllowed'
            }
        },
        methods: {
            gotoHome(){
                this.$router.push({ name: 'bookingHome', query: { siteId: this.selectedSiteId } })
                    .then(() => { window.location.reload()});
            }
        }
    }
</script>
