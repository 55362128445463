/* eslint-disable */
<template>
    <div v-if="siteBookingSettingServiceSelectEnabled" class="d-flex w-100 justify-content-between mt-2">
        <div class="font-weight-bold mt-2">Total:</div>
        <div>{{currencySymbol}} {{totalAmount}}</div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { CountryCacheMixin } from '@/mixins/Booking/CountryCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';

    export default {
        name: 'TotalAmount',
        mixins: [CountryCacheMixin, SiteBookingSettingMixin],
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets',
            ]),
            totalAmount() {
                let total = 0;
                this.selectedPets.forEach(pet => {
                    total += pet.serviceItems.length > 0 ? pet.serviceItems.reduce((sum, item) => {
                        return sum + item.amount;
                    }, 0) : 0;
                });
                return total.toFixed(2).toString();
            }
        }
    }
</script>
