<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <display-banner></display-banner>
            <div class="booking-divider-title text-header-color">Your details</div>
            <div class="timeslots">
                <div class="form-group w-100">
                    <label for="firstName" class="w-100 text-left">First Name</label>
                    <input v-model="clientFirstName"
                        type="text"
                        class="form-control"
                        name="firstName"
                        placeholder="Enter your first name">
                    <validation-message v-if="clientValidator.firstName" :message="clientValidator.firstName"></validation-message>
                </div>
                <div class="form-group w-100">
                    <label for="lastName" class="w-100 text-left">Last Name</label>
                    <input v-model="clientLastName"
                        type="text"
                        class="form-control"
                        name="lastName"
                        placeholder="Enter your last name">
                    <validation-message v-if="clientValidator.lastName" :message="clientValidator.lastName"></validation-message>
                </div>
                <div class="form-group w-100">
                    <label for="email" class="w-100 text-left">Email Address</label>
                    <input v-model="clientEmail"
                        type="text"
                        class="form-control"
                        name="email"
                        placeholder="Enter your email address">
                    <validation-message v-if="emailValidator.email" :message="emailValidator.email"></validation-message>
                </div>
                <div class="form-group w-100">
                    <label for="telephoneMobile" class="w-100 text-left">Mobile</label>
                    <input v-model="clientTelephoneMobile"
                        type="tel"
                        class="form-control"
                        name="telephoneMobile"
                        placeholder="Enter your mobile number"/>
                    <validation-message v-if="message" :message="message"></validation-message>
                </div>
            </div>
        </div>
        <div class="fixed-buttons">
            <button class="btn btn-secondary w-100" @click="next">Next</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState, mapMutations } from 'vuex';
    import { ClientHelper } from '@/helpers/ClientHelper';
    import { EmailHelper } from '@/helpers/EmailHelper';
    import { MobileValidatorMixin } from '@/mixins/Booking/MobileValidatorMixin';
    import DisplayBanner from '../DisplayBanner.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ClientDetails',
        mixins: [MobileValidatorMixin],
        data() {
            return {
                clientValidator: ClientHelper.getUnregisteredClientValidator(),
                emailValidator: EmailHelper.getEmailValidator()
            }
        },
        computed: {
            ...mapState('BookingUpload', [
                'firstName',
                'lastName',
                'email',
                'telephoneMobile'
            ]),
            clientFirstName: {
                get() {
                    return this.firstName;
                },
                set(value) {
                    this.setFirstName(value);
                }
            },
            clientLastName: {
                get() {
                    return this.lastName;
                },
                set(value) {
                    this.setLastName(value);
                }
            },
            clientEmail: {
                get() {
                    return this.email;
                },
                set(value) {
                    this.setEmail(value);
                }
            },
            clientTelephoneMobile: {
                get() {
                    return this.telephoneMobile;
                },
                set(value) {
                    this.setTelephoneMobile(value);
                }
            },
            clientModel() {
                return {
                    firstName: this.clientFirstName,
                    lastName: this.clientLastName,
                    email: this.clientEmail
                }
            },
            clientModelValid() {
                return !!this.clientValidator.isValid && !!this.emailValidator.isValid && !this.message;
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'setClientTypeId',
                'setFirstName',
                'setLastName',
                'setEmail',
                'setTelephoneMobile'
            ]),
            next() {
                this.clientValidator.check(this.clientModel)
                this.emailValidator.check(this.clientEmail)
                this.validateMobile(this.clientTelephoneMobile);
                if(!!this.clientModelValid) {
                    this.$emit('next');
                }
            }
        },
        created() {
            this.setClientTypeId(2)
        },
        components: {
            'display-banner': DisplayBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
