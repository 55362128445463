<template>
  <section class="mb-5">
    <h3 class="text-center text-tertiary">Appointment: {{ startFormatted }}</h3>
    <div v-if="error" class="alert alert-danger alert-icon" role="alert">
        <div class="alert-content">
            <p>{{ error }}</p>
        </div>
    </div>
    <table-desktop>
        <template #header>
            <tr class="text-black bg-form">
                <th>Time</th>
                <th>Pet</th>
                <th>Resource</th>
                <th>Status</th>
            </tr>
        </template>
        <template #rows>
            <row-desktop v-for="(appointment, index) in scheduleAppointments" :key="appointment.id"
                :appointment="appointment" :pets="schedulePets" :resources="scheduleResources" :time-format="momentJSTimeFormat"
                :number="index"></row-desktop>
        </template>
    </table-desktop>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ScheduleAppointmentsCacheMixin } from '@/mixins/Appointment/ScheduleAppointmentsCacheMixin'
import { ScheduleResourcesCacheMixin } from '@/mixins/Appointment/ScheduleResourcesCacheMixin'
import { SchedulePetsCacheMixin } from '@/mixins/Appointment/SchedulePetsCacheMixin'
import { DateFormatMixin } from '@/mixins/Format/DateFormatMixin'
import { TimeFormatMixin } from '@/mixins/Format/TimeFormatMixin'
import { TimeZoneCacheMixin } from '@/mixins/Shared/TimeZoneCacheMixin'
import moment from 'moment-timezone'
import TableDesktop from '../Layout/TableDesktop.vue'
import AppointmentDesktopRow from './AppointmentDesktopRow.vue'

export default {
  name: 'AppointmentsTable',
  mixins: [DateFormatMixin, TimeFormatMixin, TimeZoneCacheMixin, ScheduleAppointmentsCacheMixin, ScheduleResourcesCacheMixin, SchedulePetsCacheMixin],
  computed: {
    ...mapState('TimeZone', [
      'selectedTimeZoneId'
    ]),
    appointment () {
      return this.scheduleAppointments.find(a => a)
    },
    startUtc () {
      return this.appointment ? this.appointment.startUtc : ''
    },
    startFormatted () {
      return this.startUtc ? moment.tz(this.startUtc, this.selectedTimeZoneId).format(this.momentJSDateFormat) : ''
    }
  },
  components: {
    'table-desktop': TableDesktop,
    'row-desktop': AppointmentDesktopRow
  }
}
</script>
