/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const ClientCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Client', [
            'loadedClient',
            'loadingClient',
            'attempted',
            'client'
        ]),
        attemptedClient() {
            return !!this.attempted
        }
    },
    watch: {
        selectedSiteId() {
            this.refreshClient();
        }
    },
    methods: {
        ...mapActions('Client', [
            'fetchClientBySiteId'
        ]),
        refreshClient() {
            if (!this.loadedClient && !this.loadingClient) {
                this.tryFetchClientBySiteId();
            }
        },
        tryFetchClientBySiteId() {
            const self = this;
            if(!self.selectedSiteId) {
                return;
            }
            self.error = '';
            self.fetchClientBySiteId(self.selectedSiteId)
                .then(response => self.afterFetchClient(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterFetchClient() {
            //override if necessary
        }
    },
    created() {
        this.refreshClient();
    }
}
