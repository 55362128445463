<template>
    <settings-checker>
        <div class="booking">
            <div class="booking-wrapper">
                <petstar-ribbon></petstar-ribbon>
                <div class="booking-divider booking-divider-left">
                    <div class="booking-content">
                        <booking-header></booking-header>
                        <div class="w-100 d-block d-md-none px-4 mb-3">
                            <home-button></home-button>
                        </div>
                        <opening-hours v-if="tab === 0 || tab === 4"></opening-hours>
                        <template v-else>
                            <div class="section-header d-flex">
                                Booking Summary
                            </div>
                            <summary-client></summary-client>
                            <booking-summary v-if="tab !== 1"></booking-summary>
                        </template>
                    </div>
                    <div class="fixed-buttons d-none d-md-block">
                        <home-button></home-button>
                    </div>
                </div>
                <anonymous-booking-disabled v-if="!clientTypeAllowed || !subscriptionFeatureAllowed"></anonymous-booking-disabled>
                <template v-else>
                    <idle @idle="handleIdle"></idle>
                    <duration-set></duration-set>
                    <client-details v-if="tab === 0" @next="handleNext"></client-details>
                    <new-pets v-if="tab === 1" @next="handleNext" @back="handleBack" @toLastPet="handleToLastPet"></new-pets>
                    <products-select v-if="tab === 2" @next="handleNext" @back="handleBack" :last-pet="lastPet"></products-select>
                    <date-time-select v-if="tab === 3" @next="handleNext" @back="handleBack" @toLastPet="handleToLastPet"></date-time-select>
                    <booking-confirmation v-if="tab === 4" @back="handleBack"></booking-confirmation>
                </template>
            </div>
        </div>
    </settings-checker>
</template>

<script>
/* eslint-disable */
import { mapActions, mapMutations } from 'vuex'
import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin'
import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin'
import { SubscriptionCacheMixin } from '@/mixins/Booking/SubscriptionCacheMixin'
import Header from './Header.vue'
import OpeningHours from './OpeningHours.vue'
import PetstarRibbon from './PetstarRibbon.vue'
import ClientDetails from './Anonymous/ClientDetails.vue'
import NewPets from './PetDetails/NewPets.vue'
import ProductsSelect from './Anonymous/ProductsSelect.vue'
import DateTimeSelect from './TimeSlots/DateTimeSelect.vue'
import SettingsChecker from './SettingsChecker.vue'
import Confirmation from './Anonymous/Confirmation.vue'
import Summary from './Summary.vue'
import SummaryClientDetails from './Anonymous/SummaryClientDetails.vue'
import DurationSet from './Anonymous/DurationSet.vue'
import AnonymousBookingDisabled from './Anonymous/AnonymousBookingDisabled.vue'
import HomeButton from './HomeButton.vue'
import Idle from './Idle.vue'

export default {
    name: 'Anonymous',
    mixins: [SelectedSiteIdMixin, SiteBookingSettingMixin, SubscriptionCacheMixin],
    data() {
        return {
            tab: 0,
            lastPet: false
        }
    },
    computed: {
        clientTypeAllowed() {
            return this.siteBookingSettingClientType === 2
        },
        featureKey() {
            return 'unregisteredClientBookingAllowed';
        }
    },
    methods: {
        ...mapMutations('BookingUpload', [
            'resetBookingUpload'
        ]),
        ...mapActions('Authentication', [
            'logout'
        ]),
        handleNext() {
            if(!this.siteBookingSettingServiceSelectEnabled && this.tab === 1) {
                this.tab = 3
            } else {
                this.tab++
            }
        },
        handleBack() {
            if(!this.siteBookingSettingServiceSelectEnabled && this.tab === 3) {
                this.tab = 1
            } else {
                this.tab--
            }
        },
        handleToLastPet(value) {
            this.lastPet = !!value
        },
        handleIdle() {
            this.$router.push({ name: 'bookingHome', query: { siteId: this.selectedSiteId } })
                .then(() => { window.location.reload()});
        }
    },
    created(){
        this.resetBookingUpload();
        this.logout();
    },
    components: {
        'booking-header': Header,
        'settings-checker': SettingsChecker,
        'petstar-ribbon': PetstarRibbon,
        'opening-hours': OpeningHours,
        'client-details': ClientDetails,
        'new-pets': NewPets,
        'products-select': ProductsSelect,
        'date-time-select': DateTimeSelect,
        'booking-confirmation': Confirmation,
        'summary-client': SummaryClientDetails,
        'booking-summary': Summary,
        'duration-set': DurationSet,
        'anonymous-booking-disabled': AnonymousBookingDisabled,
        'home-button': HomeButton,
        'idle': Idle
    }
}
</script>