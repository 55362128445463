<template>
    <div class="d-flex flex-column align-items-center w-100">
        <div class="section-header text-center px-0">
            <div class="d-flex justify-content-center align-items-center">
                <input v-model="acceptedTerms" type="checkbox" style="cursor: pointer" :disabled="loading"/>
                &nbsp;I agree to the&nbsp; <div class="btn-link" @click="handleDisplayTerms" style="cursor: pointer">Terms & Conditions.</div>
            </div>
            <validation-message v-if="termsMessage" :message="termsMessage" class="mb-2"></validation-message>
        </div>
        <div v-if="recaptchaEnabled && renderRecaptcha" class="mt-2">
            <div v-if="renderingRecaptcha">
                <span class="inline-loader"></span> Loading reCAPTCHA...
            </div>
            <vue-recaptcha ref="recaptcha"
                sitekey="6Lcyez4UAAAAAB0CbUGH_RZMHws77eZgJBT2LcFO"
                @render="onRecaptchaRendered"
                @verify="onRecaptchaSuccess"
                @expired="onRecaptchaFailed"
                @error="onRecaptchaFailed" />
            <validation-message v-if="recaptchaMessage" :message="recaptchaMessage"></validation-message>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { VueRecaptcha } from 'vue-recaptcha';
    import { BookingAuthenticatedAddMixin } from '@/mixins/Booking/BookingAuthenticatedAddMixin'
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import { CompleteStatusEmitterMixin } from '@/mixins/Booking/CompleteStatusEmitterMixin'
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'BookingAdd',
        mixins: [BookingAuthenticatedAddMixin, CompleteStatusEmitterMixin, SiteBookingSettingMixin],
        data() {
            return {
                addedBookingId: '',
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'bookAppointmentEvent',
                'bookingAddedEvent'
            ]),
            participants() {
                const { selectedPets, resourceIds } = this.anonymousBookingModel;
                if (selectedPets.length > 0 && resourceIds.length > 0) {
                    return selectedPets.map((pet, i) => ({
                        ...pet,
                        resourceId: this.siteBookingSettingSinglePetResourceEnabled ? resourceIds[i] : resourceIds[0],
                        serviceItems: pet.serviceItems.map(item => ({ ...item, quantity: 1 }))
                    }));
                } else {
                    return [];
                }
            },
            bookingAddModel() {
                return Object.assign({}, this.anonymousBookingModel, {
                    participants: this.participants,
                    timeZoneId: this.selectedTimeZoneId,
                    recaptcha: this.recaptcha
                });
            },
            loading() {
                return !!this.addingBooking || !!this.fetchingRecaptchaSettings;
            },
            completed() {
                return !!this.addedBookingId;
            },
            status() {
                return {
                    error: this.error,
                    completed: this.completed,
                    loading: this.loading
                }
            },
            canAddBooking() {
                return !this.completed;
            }
        },
        watch: {
            acceptedTerms(value) {
                this.checkTerms();
                if(!!value && !this.renderRecaptcha && !this.completed) {
                    this.renderingRecaptcha = true;
                    this.renderRecaptcha = true;
                }
            },
            bookingAddedEvent(booking) {
                this.addedBookingId = booking.id;
                this.renderRecaptcha = false;
            },
            bookAppointmentEvent() {
                this.onValidatedChanged();
            },
            recaptcha(value) {
                if(!!value) {
                    this.onValidatedChanged();
                }
            }
        },
        methods: {
            onValidatedChanged() {
                this.checkTerms();
                if (!this.canAddBooking || !this.isValid) {
                    return;
                }
                this.checkRecaptcha();
                if(!this.recaptchaValid) {
                    return;
                }
                this.tryAddBooking();
            },
            checkTerms() {
                this.isValid = !!this.acceptedTerms || !!this.completed;
                this.termsMessage = this.isValid ? '' : 'Please agree to the Terms and Conditions.';
            },
            checkRecaptcha() {
                this.recaptchaMessage = !this.recaptchaValid && !!this.renderRecaptcha ? 'Please answer reCAPTCHA.' : '';
            },
            handleDisplayTerms() {
                this.$emit('termsClicked')
            },
            refreshRecaptchaSettings() {
                //do nothing
            }
        },
        components: {
            'validation-message': ValidationMessage,
            VueRecaptcha
        }
    }
</script>
