<template>
    <div class="wrapper">
        <div class="main">
            <h2 v-if="error" class="text-center">{{ error }}</h2>
            <template v-else>
                <page-loader></page-loader>
            </template>
        </div>
    </div>
</template>

<script>
import PageLoader from '@/components/Loading/PageLoader.vue'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi'

export default {
  name: 'RedirectorView',
  data () {
    return {
      error: ''
    }
  },
  methods: {
    tryRedirect () {
      const self = this
      WebApi.get('ShortLink/' + self.$route.query.d)
        .then(response => { window.location.href = response.data.link })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  components: {
    'page-loader': PageLoader
  },
  created () {
    this.tryRedirect()
  }
}
</script>
