<template>
  <tr>
    <td class="align-middle">{{ formattedDate }}</td>
    <td class="align-middle">{{ amount }}</td>
    <td class="align-middle">{{ status }}</td>
    <td class="align-middle">
      <button class="btn btn-secondary" type="button" v-if="canProcess" @click="signalProcess">
        <i class="fas fa-shopping-cart"></i> Pay Now
      </button>
    </td>
  </tr>
</template>

<script>
import { PaymentRowMixin } from '@/mixins/Appointment/PaymentRowMixin'

export default {
  name: 'PaymentDesktopRow',
  mixins: [PaymentRowMixin],
  props: {
    number: {
      type: Number,
      required: true
    }
  }
}
</script>
