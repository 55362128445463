<template>
    <div></div>
</template>

<script>
/* eslint-disable */
import { StatusEmitterMixin } from '@/mixins/Booking/StatusEmitterMixin'
import { AppointmentSearchMixin } from '@/mixins/Booking/AppointmentSearchMixin'
import { SelectedDateMixin } from '@/mixins/Booking/SelectedDateMixin'

export default {
    name: 'ResourceAppointmentConflict',
    mixins: [StatusEmitterMixin, AppointmentSearchMixin, SelectedDateMixin],
    props: {
        timeSlot: Object,
        resourceId: String,
        value: Object
    },
    computed: {
        resourceAppointments() {
            return !!this.appointments ? this.appointments.filter(a => a.resourceId === this.resourceId) : [];
        },
        conflictingAppointment() {
            // Check for any appointment where the endTime of timeSlot is within the startUtc and endUtc
            // or the startTime of timeSlot is within the startUtc and endUtc
            // or where an appointment is within the startTime and endTime of timeSlot
            return this.resourceAppointments.find(appointment => {
                const startUtc = new Date(appointment.startUtc).getTime();
                const endUtc = new Date(appointment.endUtc).getTime();
                const timeSlotStartTime = new Date(this.timeSlot.startTime).getTime();
                const timeSlotEndTime = new Date(this.timeSlot.endTime).getTime();

                return (startUtc < timeSlotEndTime  && timeSlotEndTime < endUtc) ||
                    (startUtc < timeSlotStartTime && timeSlotStartTime < endUtc) ||
                    (timeSlotStartTime <= startUtc && endUtc <= timeSlotEndTime);
            });
        },
        loading() {
            return this.loadingAppointments
        },
        available() {
            return !this.loading && !this.conflictingAppointment && !this.retryAppointments
        }
    }
}
</script>
