/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    serviceProducts: [],
    loadingServiceProducts: false,
    retryServiceProducts: false,
    attempted: false
})

const getters = {
    findServiceProductByProductId: (state) => productId => state.serviceProducts.find(sp => sp.productId === productId),
}

const mutations = {
    setServiceProducts(state, serviceProducts) {
        state.serviceProducts = serviceProducts;
    },
    setLoading(state, loading) {
        state.loadingServiceProducts = !!loading;
        state.attempted = true;
    },
    setRetry(state, retry) {
        state.retryServiceProducts = !!retry;
    }
}

const actions = {
    getServiceProductsBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/' + siteId + '/ServiceProduct')
                .then(response => {
                    commit('setServiceProducts', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}