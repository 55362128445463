<template>
  <div>
    <div class="text-left mb-2">
      <span>Line 1</span>
      <input v-model="line1" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
      <validation-message v-if="validator.line1" :message="validator.line1"></validation-message>
    </div>
    <div class="text-left mb-2">
      <span>Line 2 <span class="small text-muted">(optional)</span></span>
      <input v-model="line2" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
    </div>
    <div class="text-left mb-2">
      <span>Line 3 <span class="small text-muted">(optional)</span></span>
      <input v-model="line3" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
    </div>
    <div class="text-left mb-2">
      <span>Town <span class="small text-muted">(optional)</span></span>
      <input v-model="town" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
    </div>
    <div class="text-left mb-2">
      <span>County <span class="small text-muted">(optional)</span></span>
      <input v-model="county" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
    </div>
    <div class="text-left mb-2">
      <span>Post Code</span>
      <input v-model="postCode" @blur="submitAddress" type="text" class="form-control" :disabled="clientAddressUpdated"/>
      <validation-message v-if="validator.postCode" :message="validator.postCode"></validation-message>
    </div>
  </div>
</template>

<script>
import { AddressHelper } from '@/helpers/AddressHelper.js'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'

export default {
  name: 'ClientAddressFieldManualInput',
  data () {
    return {
      line1: '',
      line2: '',
      line3: '',
      town: '',
      county: '',
      postCode: '',
      validator: AddressHelper.getAddressValidator()
    }
  },
  props: {
    value: Object,
    clientAddressUpdated: Boolean
  },
  computed: {
    address () {
      return {
        line1: this.line1,
        line2: this.line2,
        line3: this.line3,
        town: this.town,
        county: this.county,
        postCode: this.postCode
      }
    }
  },
  watch: {
    value (address) {
      this.setAddress(address)
    }
  },
  methods: {
    submitAddress () {
      this.validator.check(this.address)
      this.$emit('input', this.address)
    },
    setAddress (address) {
      this.line1 = address ? address.line1 : ''
      this.line2 = address ? address.line2 : ''
      this.line3 = address ? address.line3 : ''
      this.town = address ? address.town : ''
      this.county = address ? address.county : ''
      this.postCode = address ? address.postCode : ''
    }
  },
  created () {
    this.setAddress(this.value)
    this.submitAddress()
  },
  components: {
    'validation-message': ValidationMessage
  }
}
</script>
