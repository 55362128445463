<template>
    <div>
        <span v-if="loadingBreed" class="inline-loader"></span>
        <div v-else class="btn-pet" :class="{ 'btn-pet-active': active }" @click="setSelectedPets([petWithPetId])">
            <div class="btn-pet-name">{{pet.name}}</div>
            <div class="btn-pet-breed">{{breedName}}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapMutations, mapState } from 'vuex'
    import { BreedCacheMixin } from '@/mixins/Booking/BreedCacheMixin';

    export default {
        name: 'PetSelectItem',
        mixins: [BreedCacheMixin],
        props: {
            pet: Object
        },
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            petWithPetId() {
                return Object.assign({}, this.pet, {petId: this.pet.id, serviceItems: []});
            },
            breedId() {
                return !!this.pet ? this.pet.breedId : '';
            },
            selectedPetIds() {
                return this.selectedPets.length > 0 ? this.selectedPets.map(p => p.id) : [];
            },
            active() {
                return this.selectedPetIds.length === 1 && this.selectedPetIds.includes(this.pet.id)
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'setSelectedPets'
            ])
        }
    }
</script>
