/* eslint-disable */
export const ClientHelper = {
    getClientValidator() {
        return {
            isValid: false,
            firstName: '',
            lastName: '',
            check(model) {
                this.firstName = !!model.firstName ? '' : 'Please enter first name';
                this.lastName = !!model.lastName ? '' : 'Please enter last name';
                this.isValid = !this.firstName && !this.lastName;
            }
        }
    },
    getUnregisteredClientValidator() {
        return {
            isValid: false,
            firstName: '',
            lastName: '',
            check(model) {
                this.firstName = !!model.firstName ? 
                    model.firstName.length > 1 ? '' 
                    : 'Please enter at least 2 characters' 
                    : 'Please enter first name';
                this.lastName = !!model.lastName ? 
                    model.lastName.length > 1 ? '' 
                    : 'Please enter at least 2 characters' 
                    : 'Please enter last name';
                this.isValid = !this.firstName && !this.lastName;
            }
        }
    }
}
