/* eslint-disable */
<template>
    <input v-model="email" type="email" class="form-control" placeholder="Email" aria-label="Email">
</template>

<script>
/* eslint-disable */
    import { EmailHelper } from '../../helpers/EmailHelper'
    export default {
        name: 'EmailInput',
        props: {
            value: String
        },
        data() {
            return {
                email: this.value
            }
        },
        watch: {
            email(newValue) {
                const valid = EmailHelper.isValid(newValue);
                this.$emit('input', valid ? this.email : '');
            }
        }
    }
</script>
