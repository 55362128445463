<template>
  <labeled-form-control label="Date of Birth">
      <a v-if="dateStringFormatted" style="cursor: pointer" class="text-underline" @click="showSummary = !showSummary">
        {{ dateStringFormatted }} {{ age }}
      </a>
      <transition name="fade">
        <div class="row mt-1" v-if="!showSummary" id="edit-dob">
          <div class="col-4">
            <input v-model="dayString"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
              class="text-center form-control"
              maxlength="2"
              placeholder="DD"
              type="number"
              :disabled="updated"/>
          </div>
          <div class="col-4">
            <input v-model="monthString"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
              class="text-center form-control"
              maxlength="2" placeholder="MM"
              type="number"
              :disabled="updated"/>
          </div>
          <div class="col-4">
            <input v-model="yearString"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
              class="text-center form-control"
              maxlength="4" placeholder="YYYY"
              type="number"
              :disabled="updated"/>
          </div>
        </div>
      </transition>
  </labeled-form-control>
</template>

<script>
import moment from 'moment'
import { DateFormatMixin } from '@/mixins/Format/DateFormatMixin'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'

export default {
  name: 'PetDob',
  mixins: [DateFormatMixin],
  props: {
    value: String,
    updated: Boolean
  },
  data () {
    return {
      currentYear: new Date().getFullYear(),
      dayString: '',
      monthString: '',
      yearString: '',
      showSummary: false,
      isAnyInputFocused: false
    }
  },
  computed: {
    year () {
      if (this.yearString.length < 4) return ''
      const year = parseInt(this.yearString, 10)
      return isNaN(year) || year < 1 || year > this.currentYear
        ? ''
        : ('0000' + year.toString()).slice(-4)
    },
    month () {
      const month = parseInt(this.monthString, 10)
      return isNaN(month) || month < 1 || month > 12
        ? ''
        : ('00' + month.toString()).slice(-2)
    },
    day () {
      const day = parseInt(this.dayString, 10)
      return isNaN(day) || day < 1 || day > 31
        ? ''
        : ('00' + day.toString()).slice(-2)
    },
    date () {
      return !!this.year && !!this.month && !!this.day
        ? moment(this.year + this.month + this.day, 'YYYYMMDD')
        : null
    },
    dateString () {
      return this.date ? this.date.utc().format() : null
    },
    dateStringFormatted () {
      return this.date ? this.date.local().format(this.momentJSDateFormat) : this.momentJSDateFormat
    },
    age () {
      if (!this.date) return ''
      const today = moment()
      const diffInYears = today.diff(this.date, 'years')
      const diffInMonths = today.diff(this.date, 'months')
      if (diffInYears === 0) {
        if (diffInMonths === 0) {
          return '(Less than 1 month old)'
        } else if (diffInMonths === 1) {
          return '(1 month old)'
        } else {
          return `(${diffInMonths} months old)`
        }
      } else if (diffInYears === 1) {
        return '(1 year old)'
      } else {
        return `(${diffInYears} years old)`
      }
    }
  },
  methods: {
    refreshValues () {
      if (this.value) {
        const date = moment(this.value)
        this.dayString = date.format('DD')
        this.monthString = date.format('MM')
        this.yearString = date.format('YYYY')
      } else {
        this.dayString = ''
        this.monthString = ''
        this.yearString = ''
      }
    },
    handleInputFocus () {
      this.isAnyInputFocused = true
    },
    handleInputBlur () {
      this.isAnyInputFocused = false
      setTimeout(() => {
        if (this.dateString && !this.isAnyInputFocused) {
          this.showSummary = true
        } else if (this.dateString && this.isAnyInputFocused) {
          this.showSummary = false
        } else {
          this.showSummary = false
        }
      }, 100)
    }
  },
  watch: {
    dateString (value) {
      if (value) {
        this.$emit('input', value)
      }
    },
    value () {
      this.refreshValues()
    }
  },
  created () {
    this.refreshValues()
    if (this.value) {
      this.showSummary = true
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
