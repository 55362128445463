<template>
  <div>
      <canvas ref="signatureCanvas" class="signature mb-2"></canvas>
      <div>
          <button @click="clear" class="btn text-footer-color">Clear</button>
          <slot></slot>
      </div>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
  name: 'SignaturePad',
  data () {
    return {
      pad: null
    }
  },
  methods: {
    clear () {
      this.pad.clear()
      this.$emit('input', '')
    }
  },
  mounted () {
    const canvas = this.$refs.signatureCanvas
    this.pad = new SignaturePad(canvas)
    this.pad.addEventListener('endStroke', () => {
      this.$emit('input', this.pad.toDataURL())
    })
  }
}
</script>

<style scoped>
.signature {
  background: #FFEDB2;
  border: 1px solid #121820;
  height: 100px;
  width: 100%;
  border-radius: 0.25rem;
}
@media screen and (max-width: 768px)  {
  .signature {
    height: 80px;
    width: 100%;
  }
}
</style>
