/* eslint-disable */
<template>
  <div class="booking-divider booking-divider-right">
    <div class="booking-content">
      <template v-if="completed">
        <div class="d-flex flex-column justify-content-center align-items-center h-100 mt-5 px-4">
          <template v-if="addedBookingStatusApproved">
            <span class="text-default font-size-h3 font-weight-bold">
              Thank you for your booking request! Your appointment on
            </span>
            <span class="font-weight-bold font-size-h3">
              &nbsp;{{formattedStartDay}}, {{formattedStartDate}} at {{formattedStartTime}}&nbsp;
            </span>
            <span class="text-default font-size-h3 font-weight-bold">
              has been confirmed. An email confirmation will be sent to you very shortly. We look forward to seeing you!
            </span>
          </template>
          <template v-else>
            <span class="text-default font-size-h3 font-weight-bold">
              Thank you for your booking request for
              <span class="font-weight-bold font-size-h3">
                {{formattedStartDay}}, {{formattedStartDate}} at {{formattedStartTime}},
              </span>
              this is currently under review and we will send a confirmation email shortly.
            </span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="font-size-h3 font-weight-bold mt-3">Booking Summary</div>
        <div class="mb-2">
          <span>{{formattedStartDay}}</span>, <span>{{formattedStartDate}}</span><br/>
          <span>@ {{formattedStartTime}}</span>
        </div>
        <error-banner v-if="errors" :message="errors" class="mb-2 mx-3"></error-banner>
        <div class="timeslots" v-if="displayTerms" style="border-top: 1px solid #D9D9D9">
            <terms-and-conditions></terms-and-conditions>
        </div>
        <div class="timeslots" v-else>
          <summary-item v-for="pet in selectedPets" :key="pet.id" :pet="pet"></summary-item>
          <total-amount></total-amount>
        </div>
        <booking-add @termsClicked="handleDisplayTerms" v-model="bookingStatus"></booking-add>
      </template>
    </div>
    <div class="fixed-buttons">
      <template v-if="completed">
        <button @click="handleAnotherAppointment" class="btn btn-secondary w-100">
          Book Another Appointment
        </button>
      </template>
      <template v-else>
        <button class="btn btn-tertiary w-100 mr-2" @click="back" :disabled="addedBooking || loading">Back</button>
        <button @click="triggerBookAppointment" class="btn btn-secondary w-100 ml-2" v-bind:disabled="loading || fetchingClient">
          Confirm
          <span v-if="loading" class="inline-loader"></span>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import moment from 'moment-timezone'
  import { mapState, mapMutations } from 'vuex';
  import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
  import { ClientUserCheckingMixin } from '@/mixins/Booking/ClientUserCheckingMixin'
  import { DateFormatMixin } from '@/mixins/Booking/DateFormatMixin';
  import { TimeFormatMixin } from '@/mixins/Booking/TimeFormatMixin';
  import TermsAndConditions from '../TermsAndConditions.vue';
  import SummaryItem from '../SummaryItem.vue'
  import TotalAmount from '../TotalAmount.vue';
  import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
  import BookingAdd from './BookingAdd.vue';

  export default {
    name: 'Confirmation',
    mixins: [ClientUserCheckingMixin, SiteBookingSettingMixin, DateFormatMixin, TimeFormatMixin],
    data() {
      return {
        addedBooking: null,
        displayTerms: false,
        bookingStatus: {}
      }
    },
    computed: {
      ...mapState('ApplicationEvents', [
          'bookingAddedEvent'
      ]),
      ...mapState('BookingUpload', [
        'selectedPets',
        'startUtc'
      ]),
      ...mapState('TimeZone', [
          'selectedTimeZoneId'
      ]),
      formattedStartUtc() {
        if (!!this.startUtc) {
          return moment.utc(this.startUtc).clone().tz(this.selectedTimeZoneId)
        }
      },
      formattedStartDay(){ 
        return this.formattedStartUtc ? this.formattedStartUtc.format('dddd') : "";
      },
      formattedStartDate(){ 
        return this.formattedStartUtc ? this.formattedStartUtc.format(this.momentJSDateFormat) : "";
      },
      formattedStartTime() {
        return this.formattedStartUtc ? this.formattedStartUtc.format(this.momentJSTimeFormat) : "";
      },
      statuses() {
        return [
          this.bookingStatus
        ]
      },
      completed() {
        return this.statuses.every(s => !!s.completed);
      },
      loading() {
        return this.statuses.some(s => !!s.loading)
      },
      errors() {
        const statusErrors = this.statuses.map(s => s.error).filter(error => !!error);
        if (!!this.error) {
          statusErrors.push(this.error);
        }
        return statusErrors.join(' ');
      },
      addedBookingStatus() {
        return !!this.addedBooking && this.addedBooking.status ?  this.addedBooking.status : null; 
      },
      addedBookingStatusApproved() {
        return !!this.addedBookingStatus && this.addedBookingStatus.id === 2 ? true : false;
      }
    },
    watch: {
      bookingAddedEvent(booking) {
        this.addedBooking = booking;
      }
    },
    methods: {
      ...mapMutations('ApplicationEvents', [
        'triggerBookAppointment'
      ]),
      back() {
        if(!!this.addedBooking) {
          return;
        }
        this.$emit('back');
      },
      handleDisplayTerms() {
        this.displayTerms = !this.displayTerms
      },
      handleAnotherAppointment(){
        window.location.reload()
      }
    },
    components: {
      'terms-and-conditions': TermsAndConditions,
      'summary-item': SummaryItem,
      'total-amount': TotalAmount,
      'error-banner': ErrorBanner,
      'booking-add': BookingAdd
    }
  }
</script>

<style scoped>
  @media (max-width: 768px) {
    .booking-divider-right {
      height: max-content;
      min-height: 50vh !important;
    }
    .booking-content {
      height: max-content;
      min-height: 40vh !important;
    }

    .timeslots {
      height: max-content;
      min-height: 22vh !important;
    }
  }
</style>
