import { mapActions, mapState } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteMixin } from '../Site/SelectedSiteMixin'

export const FormatOptionsTimeCacheMixin = {
  mixins: [SelectedSiteMixin],
  computed: {
    ...mapState('FormatOptionsTime', [
      'fetchingTimeFormatOptions',
      'fetchedTimeFormatOptions',
      'timeFormatOptions'
    ])
  },
  watch: {
    selectedSiteId () {
      this.refreshTimeFormatOptions()
    }
  },
  methods: {
    ...mapActions('FormatOptionsTime', [
      'fetchTimeFormatOptions'
    ]),
    refreshTimeFormatOptions () {
      if (!this.fetchedTimeFormatOptions && !this.fetchingTimeFormatOptions) {
        this.tryFetchTimeFormatOptions()
      }
    },
    tryFetchTimeFormatOptions () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchTimeFormatOptions()
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshTimeFormatOptions()
  }
}
