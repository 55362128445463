import { WebApi } from '@/services/WebApi'

const state = () => ({
  timeFormatOptions: [],
  fetchingTimeFormatOptions: false,
  fetchedTimeFormatOptions: false
})

const mutations = {
  setFetching (state, fetching) {
    state.fetchedTimeFormatOptions = false
    state.fetchingTimeFormatOptions = !!fetching
  },
  setTimeFormatOptions (state, timeFormatOptions) {
    state.timeFormatOptions = timeFormatOptions
    state.fetchedTimeFormatOptions = true
    state.fetchingTimeFormatOptions = false
  }
}

const actions = {
  fetchTimeFormatOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('TimeFormatSetting/Option')
        .then(response => {
          commit('setTimeFormatOptions', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  },
  fetchTimeFormatOptionsBySiteId ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Portal/' + siteId + '/TimeFormatSetting/Option')
        .then(response => {
          commit('setTimeFormatOptions', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
