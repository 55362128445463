/* eslint-disable */
import { mapActions } from 'vuex';
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { AlternateContactCacheMixin } from "./AlternateContactCacheMixin";

export const AlternateContactAddMixin = {
    mixins: [AlternateContactCacheMixin],
    data() {
        return {
            name: '',
            mobile: '',
            email: '',
            otherInfo: '',
        }
    },
    computed: {
        alternateContactAddModel() {
            return Object.assign({}, {
                contactTypeId: this.contactTypeId,
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            });
        }
    },
    methods: {
        ...mapActions('RegistrationAlternateContact', [
            'addAlternateContact'
        ]),
        tryAddAlternateContact() {
            const self = this;
            self.error = '';
            return self.addAlternateContact({ inviteId: self.inviteId, payload: self.alternateContactAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
