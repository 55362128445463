/* eslint-disable */
export const CompleteStatusEmitterMixin = {
    computed: {
        status() {
            console.log('OVERRIDE COMPUTED - status');
            return {};
        }
    },
    watch: {
        status() {
            this.emitStatus();
        }
    },
    methods: {
        emitStatus() {
            this.$emit('input', this.status);
        }
    },
    created() {
        this.emitStatus();
    }
}
