<template>
    <div class="d-flex flex-column">
        <div class="d-flex w-100">
            <input v-model="searchTerm" class="form-control search-input" placeholder="Search breed" type="search"/>
            <div class="form-control search-icon">
                <span v-if="loadingBreedSearch" class="inline-loader"></span>
                <template v-else>
                    <i class="fas fa-search"></i>
                </template>
            </div>
        </div>
        <search-results v-if="breedResultsReady" :breeds="breeds" :selected-id="selectedId" @clicked="onClicked"></search-results>
    </div>
</template>

<script>
import { BreedSearchMixin } from '@/mixins/Registration/BreedSearchMixin'
import PetBreedSearchFieldResults from './PetBreedSearchFieldResults.vue'

export default {
  name: 'PetBreedSearchField',
  mixins: [BreedSearchMixin],
  props: {
    value: Object
  },
  computed: {
    selectedId () {
      return this.value ? this.value.id : ''
    }
  },
  methods: {
    onClicked (breed) {
      this.$emit('input', Object.assign({}, breed))
      this.searchTerm = ''
    }
  },
  components: {
    'search-results': PetBreedSearchFieldResults
  }
}
</script>

<style scoped>
.search-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-icon {
    width: max-content;
    padding: 0.375rem 0.75rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
