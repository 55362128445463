/* eslint-disable */
const state = () => ({
    clientId: '',
    clientTypeId: 0,
    firstName: '',
    lastName: '',
    email: '',
    telephoneMobile: '',
    startUtc: '',
    duration: 120,
    resourceIds: [],
    selectedPets: []
})

const getters = {
    anonymousBookingModel: (state) => {
        return {
            clientId: state.clientId,
            clientTypeId: state.clientTypeId,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            telephoneMobile: state.telephoneMobile,
            startUtc: state.startUtc,
            duration: state.duration,
            resourceIds: state.resourceIds,
            selectedPets: state.selectedPets,
        }
    }
}

const mutations = {
    setSelectedPets(state, pets) {
        state.selectedPets = pets
    },
    setResourceIds(state, resourceIds) {
        state.resourceIds = resourceIds
    },
    setStartUtc(state, startUtc) {
        state.startUtc = startUtc
    },
    clearStartUtc(state) {
        state.startUtc = ''
    },
    setDuration(state, duration) {
        state.duration = duration
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setClientId(state, clientId) {
        state.clientId = clientId
    },
    setClientTypeId(state, clientTypeId) {
        state.clientTypeId = clientTypeId
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setTelephoneMobile(state, telephoneMobile) {
        state.telephoneMobile = telephoneMobile;
    },
    removePet(state, id){
        state.selectedPets.forEach((pet) => {
            pet.isValid = false;
        })
        state.selectedPets = state.selectedPets.filter(p => p.id !== id)
    },
    addPet(state){
        let newId;

        do {
            newId = Math.random().toString(36).substring(7);
        } while (state.selectedPets.some(p => p.id === newId));

        state.selectedPets.push({
            id: newId,
            petId: '',
            name: '',
            breedId: '',
            serviceItems: [],
            isValid: false
        })
    },
    updateBreed(state, { petId, breedId }){
        const existing = state.selectedPets.find(p => p.id === petId);

        if (!!existing) {
            existing.breedId = breedId;
        }
    },
    updatePetName(state, { petId, name }) {
        const existing = state.selectedPets.find(p => p.id === petId);

        if (!!existing) {
            existing.name = name;
        }
    },
    addServiceItem(state, { petId, serviceItem }) {
        const existing = state.selectedPets.find(p => p.id === petId);

        if (!!existing) {
            existing.serviceItems.push(serviceItem)
        }
    },
    removeServiceItem(state, { petId, serviceItem }) {
        const existing = state.selectedPets.find(p => p.id === petId);
        
        if (!!existing) {
            existing.serviceItems = existing.serviceItems.filter(si => si.id !== serviceItem.id);
        }
    },
    clearServiceItems(state, petId) {
        const existing = state.selectedPets.find(p => p.id === petId);
        
        if (!!existing) {
            existing.serviceItems = [];
        }
    },
    updatePetIsValid(state, { petId, value }) {
        const existing = state.selectedPets.find(p => p.id === petId);

        if (!!existing) {
            existing.isValid = !!value;
        }
    },
    resetBookingUpload(state) {
        state.clientId =  '',
        state.clientTypeId = 0,
        state.firstName = '',
        state.lastName = '',
        state.email = '',
        state.telephoneMobile = '',
        state.startUtc = '',
        state.duration = 120,
        state.resourceIds = [],
        state.selectedPets = []     
    },
    updateBookingUploadState (state, booking) {
        state.clientId = booking.clientId;
        state.clientTypeId = booking.clientType.id;
        state.firstName = booking.firstName;
        state.lastName = booking.lastName;
        state.email = booking.email;
        state.telephoneMobile = booking.telephoneMobile;
        state.startUtc = booking.startUtc;
        state.duration = booking.duration;
        state.resourceIds = booking.participants.map(participant => participant.resourceId);
        state.selectedPets = booking.participants.map(participant => {
            return {
                id: participant.id,
                petId: participant.petId,
                name: participant.name,
                breedId: participant.breedId,
                serviceItems: participant.serviceItems.map(item => {
                    return {
                        id: item.productId,
                        quantity: item.quantity,
                        amount: item.amount,
                        name: item.name,
                        description: item.description
                    };
                }),
                isValid: true
            };
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
