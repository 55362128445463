/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    openingHours: null,
    loadingOpeningHours: false,
    retryOpeningHours: false
})

const mutations = {
    setOpeningHours(state, openingHours) {
        state.openingHours = openingHours;
    },
    setLoading(state, loading) {
        state.loadingOpeningHours = !!loading;
    },
    setRetry(state, retry) {
        state.retryOpeningHours = !!retry;
    }
}

const actions = {
    getOpeningHoursBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/' + siteId + '/OpeningHour')
                .then(response => {
                    commit('setOpeningHours', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}