/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const WorkingHourCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('WorkingHours', [
            'workingHours',
            'loadingWorkingHours',
            'retryWorkingHours'
        ])
    },
    watch: {
        selectedSiteId() {
            this.refreshWorkingHours()
        }
    },
    methods: {
        ...mapActions('WorkingHours', [
            'getWorkingHoursBySiteId'
        ]),
        refreshWorkingHours() {
            if (this.workingHours.length == 0 || !!this.retryWorkingHours) {
                this.tryGetWorkingHoursBySiteId();
            }
        },
        tryGetWorkingHoursBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getWorkingHoursBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshWorkingHours();
    }
}
