import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi'

const state = () => ({
  schedulePaymentAccountCaches: []
})

const getters = {
  findSchedulePaymentAccountCache: (state) => key => state.schedulePaymentAccountCaches.find(spac => spac.key === key)
}

const mutations = {
  setLoading (state, { key, loading }) {
    const existing = state.schedulePaymentAccountCaches.find(spac => spac.key === key)
    if (existing) {
      existing.loading = !!loading
      existing.retry = false
    } else {
      state.schedulePaymentAccountCaches.push({ key: key, value: null, loading: !!loading })
    }
  },
  setSchedulePaymentAccount (state, { key, paymentAccount }) {
    const existing = state.schedulePaymentAccountCaches.find(spac => spac.key === key)
    if (existing) {
      existing.value = paymentAccount
      existing.loading = false
    } else {
      state.schedulePaymentAccountCaches.push({ key: key, value: paymentAccount, loading: false })
    }
  },
  setRetry (state, key) {
    const existing = state.schedulePaymentAccountCaches.find(spac => spac.key === key)
    if (existing) {
      existing.retry = true
      existing.loading = false
    }
  }
}

const actions = {
  addSchedulePaymentAccount ({ commit }, { key, scheduleId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: key, loading: true })
      WebApi.post('ClientAppointmentSchedule/' + scheduleId + '/PaymentUser/Register', payload)
        .then(response => {
          commit('setSchedulePaymentAccount', { key: key, paymentAccount: response.data })
          resolve(response)
        })
        .catch(error => {
          if (ErrorHelper.isNotFound(error)) {
            commit('setLoading', { key: key, loading: false })
            resolve(false)
          } else {
            commit('setRetry', key)
            reject(error)
          }
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
