<template>
  <h2 class="text-left text-default mb-5">{{ clientFullName }}</h2>
</template>

<script>
import { WebApi } from '@/services/WebApi'

export default {
  name: 'ClientDetails',
  props: {
    scheduleId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: '',
      client: null
    }
  },
  computed: {
    clientFullName () {
      return this.client ? this.client.fullName : ''
    }
  },
  watch: {
    scheduleId () {
      this.refreshClient()
    }
  },
  methods: {
    refreshClient () {
      const self = this
      self.error = ''
      WebApi.get('ClientAppointmentSchedule/' + self.scheduleId + '/Client')
        .then(response => { self.client = response.data })
        .catch(error => { self.error = error })
    }
  },
  created () {
    this.refreshClient()
  }
}
</script>
