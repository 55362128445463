import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi'

const state = () => ({
  logoCaches: []
})

const getters = {
  findLogoCache: (state) => key => state.logoCaches.find(lc => lc.key === key)
}

const mutations = {
  setLoading (state, { key, loading }) {
    const existing = state.logoCaches.find(lc => lc.key === key)
    if (existing) {
      existing.loading = !!loading
      existing.retry = false
    } else {
      state.logoCaches.push({ key: key, value: null, loading: !!loading })
    }
  },
  setLogo (state, { key, logo }) {
    const existing = state.logoCaches.find(lc => lc.key === key)
    if (existing) {
      existing.value = logo
      existing.loading = false
    } else {
      state.logoCaches.push({ key: key, value: logo, loading: false })
    }
  },
  setRetry (state, key) {
    const existing = state.logoCaches.find(lc => lc.key === key)
    if (existing) {
      existing.retry = true
      existing.loading = false
    }
  }
}

const actions = {
  fetchLogo ({ commit }, { key, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: key, loading: true })
      WebApi.get('Logo?' + new URLSearchParams(payload))
        .then(response => {
          commit('setLogo', { key: key, logo: response.data })
          resolve(response)
        })
        .catch(error => {
          if (ErrorHelper.isNotFound(error)) {
            commit('setLoading', { key: key, loading: false })
            resolve(false)
          } else {
            commit('setRetry', key)
            reject(error)
          }
        })
    })
  },
  fetchLogoBySiteId ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: siteId, loading: true })
      WebApi.get('Portal/' + siteId + '/Logo')
        .then(response => {
          commit('setLogo', { key: siteId, logo: response.data })
          resolve(response)
        })
        .catch(error => {
          if (ErrorHelper.isNotFound(error)) {
            commit('setLoading', { key: siteId, loading: false })
            resolve(false)
          } else {
            commit('setRetry', siteId)
            reject(error)
          }
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
