<template>
  <div>
    <table class="table">
        <thead>
            <slot name="header"></slot>
        </thead>
        <tbody>
            <slot name="rows"></slot>
        </tbody>
    </table>
    <ul v-if="pageNumber" class="pagination justify-content-center">
      <li class="page-item disabled">
        <button @click="previous" class="page-link" disabled>&lt;</button>
      </li>
      <li class="page-item">
        <span class="page-link">{{pageNumber || 0}}</span>
      </li>
      <li class="page-item">
        <button @click="next" class="page-link">&gt;</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TableDesktop',
  props: {
    pageNumber: Number
  },
  methods: {
    previous () {
      this.$emit('previous')
    },
    next () {
      this.$emit('next')
    }
  }
}
</script>
