/* eslint-disable */
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper';

export const OneOffHourSearchMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('OneOffHourSearches', [
            'oneOffHourSearchesCleared'
        ]),
        ...mapGetters('OneOffHourSearches', [
            'findOneOffHourSearch'
        ]),
        selectedDatesLocal() {
            console.log('OVERRIDE COMPUTED - selectedDatesLocal');
        },
        oneOffHourSearchModel() {
            return {
                fromLocal: this.selectedDatesLocal.fromLocal,
                toLocal: this.selectedDatesLocal.toLocal,
                siteId: this.selectedSiteId,
            }
        },
        oneOffHourSearchModelValid() {
            return !!this.oneOffHourSearchModel
                && !!this.oneOffHourSearchModel.fromLocal
                && !!this.oneOffHourSearchModel.toLocal
        },
        oneOffHourSearchCacheKey() {
            return CacheHelper.fullObjectCacheKey(this.oneOffHourSearchModel);
        },
        oneOffHourCache() {
            return this.findOneOffHourSearch(this.oneOffHourSearchCacheKey);
        },
        loadingOneOffHours() {
            return this.oneOffHourCache && this.oneOffHourCache.loading;
        },
        attemptedOneOffHours() {
            return this.oneOffHourCache && this.oneOffHourCache.attempted;
        },
        oneOffHours() {
            return this.oneOffHourCache ? this.oneOffHourCache.value.oneOffHours : [];
        }
    },
    watch: {
        oneOffHourSearchModel() {
            this.refreshOneOffHours();
        },
        oneOffHourSearchesCleared() {
            this.refreshOneOffHours();
        }
    },
    methods: {
        ...mapActions('OneOffHourSearches', [
            'searchOneOffHoursBySiteId'
        ]),
        refreshOneOffHours() {
            this.error = '';
            if (!this.oneOffHourCache || !!this.oneOffHourCache.retry) {
                this.searchAndCacheOneOffHours();
            }
        },
        searchAndCacheOneOffHours() {
            const self = this;
            if (!self.oneOffHourSearchModelValid) {
                return;
            }
            self.searchOneOffHoursBySiteId({
                key: self.oneOffHourSearchCacheKey,
                siteId: self.selectedSiteId,
                payload: self.oneOffHourSearchModel
            }).catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOneOffHours();
    }
}
