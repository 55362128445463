<template>
    <settings-checker>
        <div class="booking">
            <div class="booking-wrapper">
                <petstar-ribbon></petstar-ribbon>
                <div class="booking-divider booking-divider-left">
                    <div class="booking-content">
                        <booking-header></booking-header>
                        <div class="w-100 d-block d-md-none px-4 mb-3">
                            <home-button></home-button>
                        </div>
                        <opening-hours></opening-hours>
                    </div>
                    <div class="fixed-buttons d-none d-md-block">
                        <home-button></home-button>
                    </div>
                </div>
                <booking-confirmation v-if="token && !!bookingSuggestion" :token="token"></booking-confirmation>
                <div v-else-if="!token" class="booking-divider booking-divider-right h-100 d-flex justify-content-center align-items-centerright">
                    <error-banner v-if="error" message="LINK IS INVALID"></error-banner>
                </div>
                <div v-else-if="!bookingSuggestion && !loadingBookingSuggestion" class="booking-divider booking-divider-right h-100 d-flex justify-content-center align-items-center">
                    <error-banner v-if="error" :message="error"></error-banner>
                </div>
            </div>
        </div>
    </settings-checker>
</template>

<script>
/* eslint-disable */
import { mapMutations } from 'vuex';
import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin'
import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin'
import { SubscriptionCacheMixin } from '@/mixins/Booking/SubscriptionCacheMixin'
import { BookingSuggestionCacheMixin } from '@/mixins/BookingSuggestion/BookingSuggestionCacheMixin'
import Header from '../Booking/Header.vue'
import OpeningHours from '../Booking/OpeningHours.vue'
import PetstarRibbon from '../Booking/PetstarRibbon.vue'
import SettingsChecker from '../Booking/SettingsChecker.vue'
import HomeButton from '../Booking/HomeButton.vue'
import Confirmation from './Confirmation.vue';
import ErrorBanner from '../ErrorValidation/ErrorBanner.vue';

export default {
    name: 'BookingSuggestion',
    mixins: [SelectedSiteIdMixin, SiteBookingSettingMixin, SubscriptionCacheMixin, BookingSuggestionCacheMixin],
    methods: {
        ...mapMutations('Sites', [
            'clearSites'
        ]),
        ...mapMutations('BookingUpload', [
            'updateBookingUploadState',
            'setStartUtc'
        ])
    },
    watch: {
        bookingSuggestion(value) {
            if(!!value){
                this.updateBookingUploadState(value.booking);
                this.setStartUtc(value.startUtc);
            }
        }
    },
    created () {
        this.clearSites()
    },
    components: {
        'booking-header': Header,
        'opening-hours': OpeningHours,
        'petstar-ribbon': PetstarRibbon,
        'settings-checker': SettingsChecker,
        'home-button': HomeButton,
        'booking-confirmation': Confirmation,
        'error-banner': ErrorBanner
    }
}
</script>