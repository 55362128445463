import { mapState } from 'vuex'
import { SiteCacheMixin } from './SiteCacheMixin'

export const SelectedSiteMixin = {
  mixins: [SiteCacheMixin],
  computed: {
    ...mapState('Sites', [
      'selected'
    ]),
    selectedSiteId () {
      return this.selected ? this.selected.id : ''
    },
    selectedSiteName () {
      return this.selected ? this.selected.name : ''
    },
    selectedCompanyName () {
      return this.selected ? this.selected.companyName : ''
    },
    selectedTagLine () {
      return this.selected ? this.selected.tagLine : ''
    }
  }
}
