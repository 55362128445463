/* eslint-disable */
export const AddressHelper = {
    getDefaultAddress() {
        return {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postCode: ''
        }
    },
    getAddressValidator() {
        return {
            line1: '',
            postCode: '',
            isValid: true,
            check(address) {
                // check if the client has entered a value in any of the address fields
                const hasAnyValue = Object.values(address).some(value => !!value);

                if(hasAnyValue) {
                    this.line1 = !!address.line1 ? '' : 'Please enter line 1';
                    this.postCode = !!address.postCode ? '' : 'Please enter postcode';
                    this.isValid = !!address.line1 && !!address.postCode;
                }
            }
        }
    },
    getAddressLines(address) {
        return !!address ? [
            address.line1,
            address.line2,
            address.line3,
            address.town,
            address.county,
            address.postCode].filter(o => !!o) : [];
    }
}