<template>
  <div>
    <div class="d-none d-md-block">
      <labeled-form-control label="Name">
        <div class="row">
          <div class="col-6">
            <input v-model="firstName" class="form-control" type="text" name="name" placeholder="First Name" ref="firstNameField" :disabled="updated"/>
            <validation-message v-if="validator.firstName" :message="validator.firstName"></validation-message>
          </div>
          <div class="col-6">
            <input  v-model="lastName" class="form-control" type="text" name="name" placeholder="Last Name" :disabled="updated"/>
            <validation-message v-if="validator.lastName" :message="validator.lastName"></validation-message>
          </div>
        </div>
      </labeled-form-control>
    </div>
    <div class="d-block d-md-none">
      <labeled-form-control label="First Name">
        <div class="row">
          <div class="col-12">
            <input v-model="firstName" class="form-control" type="text" name="name" placeholder="First Name" ref="firstNameField" :disabled="updated"/>
            <validation-message v-if="validator.firstName" :message="validator.firstName"></validation-message>
          </div>
        </div>
      </labeled-form-control>
      <labeled-form-control label="Last Name">
        <div class="row">
          <div class="col-12">
            <input  v-model="lastName" class="form-control" type="text" name="name" placeholder="Last Name" :disabled="updated"/>
            <validation-message v-if="validator.lastName" :message="validator.lastName"></validation-message>
          </div>
        </div>
      </labeled-form-control>
    </div>
  </div>
</template>

<script>
import process from 'process'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'

export default {
  name: 'ClientNameFields',
  data () {
    return {
      setFocus: true
    }
  },
  props: {
    value: Object,
    validator: Object,
    updated: Boolean
  },
  computed: {
    firstName: {
      get () {
        return this.value.firstName
      },
      set (value) {
        this.$emit('input', {
          firstName: value,
          lastName: this.value.lastName
        })
      }
    },
    lastName: {
      get () {
        return this.value.lastName
      },
      set (value) {
        this.$emit('input', {
          firstName: this.value.firstName,
          lastName: value
        })
      }
    }
  },
  mounted () {
    if (this.setFocus) {
      process.nextTick(() => this.$refs.firstNameField.focus())
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl,
    'validation-message': ValidationMessage
  }
}
</script>
