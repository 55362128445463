/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    fetchedSubscription: false,
    fetchingSubscription: false,
    subscription: null,
    attempted: false
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingSubscription = !!fetching;
        state.fetchedSubscription = false;
        state.attempted = true;
    },
    setSubscription(state, subscription) {
        state.subscription = subscription;
        state.fetchingSubscription = false;
        state.fetchedSubscription = true;
    }
}

const actions = {
    fetchSubscriptionBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            WebApi.get('Portal/'+ siteId +'/Subscription')
                .then(response => {
                    commit('setSubscription', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
