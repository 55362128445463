import { mapActions, mapGetters } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const SchedulePaymentAccountCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters('SchedulePaymentAccounts', [
      'findSchedulePaymentAccountCache'
    ]),
    reference () {
      console.log('OVERRIDE COMPUTED - reference')
    },
    schedulePaymentAccountAddModel () {
      return {
        sequenceNumber: this.sequence
      }
    },
    schedulePaymentAccountAddModelValid () {
      return !!this.reference
    },
    schedulePaymentAccountCache () {
      return this.findSchedulePaymentAccountCache(this.reference)
    },
    schedulePaymentAccount () {
      return this.schedulePaymentAccountCache ? this.schedulePaymentAccountCache.value : null
    },
    loadingSchedulePaymentAccount () {
      return !!this.schedulePaymentAccountCache && this.schedulePaymentAccountCache.loading
    }
  },
  watch: {
    reference () {
      this.refreshSchedulePaymentAccount()
    }
  },
  methods: {
    ...mapActions('SchedulePaymentAccounts', [
      'addSchedulePaymentAccount'
    ]),
    refreshSchedulePaymentAccount () {
      if (!this.schedulePaymentAccountCache || this.schedulePaymentAccountCache.retry) {
        this.tryAddSchedulePaymentAccount()
      }
    },
    tryAddSchedulePaymentAccount () {
      const self = this
      if (!self.schedulePaymentAccountAddModelValid) {
        return
      }
      self.error = ''
      self.addSchedulePaymentAccount({ key: self.reference, scheduleId: self.scheduleId, payload: self.schedulePaymentAccountAddModel })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshSchedulePaymentAccount()
  }
}
