/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const PetNoteCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationPetNotes', [
            'petNoteCaches',
            'loadingPetNotes',
            'loadedPetNotes'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        noteTypeId(){
            return 3;
        },
        ownerId() {
            console.log('!!!OVERRIDE COMPUTED - ownerId!!!');
        },
        ownerTypeId() {
            return 3;
        },
        ownerQueryModel() {
            return {
                ownerId: this.ownerId,
                ownerTypeId: this.ownerTypeId
            }
        },
        petNoteCache(){
            return this.petNoteCaches.length > 0 ? this.petNoteCaches.find((obj) => {
                return obj.ownerId === this.ownerId && obj.noteType.id === this.noteTypeId
            }) : null;
        }
    },
    methods: {
        ...mapActions('RegistrationPetNotes', [
            'findPetNotes'
        ]),
        refreshPetNotes() {
            if (!this.loadedPetNotes) {
                this.tryFindPetNotes();
            }
        },
        tryFindPetNotes() {
            const self = this;
            if (!this.ownerQueryModel.ownerId || !this.ownerQueryModel.ownerTypeId) {
                return;
            }
            self.error = '';
            self.findPetNotes(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetNotes();
    }
}
