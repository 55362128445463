<template>
  <div class="dropdown-menu show" role="presentation" style="margin-left: 15px !important; width: calc(100% - 30px) !important; max-height: 300px; overflow: scroll;">
      <div v-for="breed in breeds" :key="breed.id" @click="clicked(breed)" role="option" class="dropdown-item" style="font-size: 0.75rem; cursor: pointer">
        <span class="text">{{ breed.name }}</span>
      </div>
      <template v-if="breeds.length === 0">
          <a role="option" class="dropdown-item" style="font-size: 0.75rem; cursor: pointer"> No results found </a>
      </template>
  </div>
</template>

<script>
export default {
  name: 'BreedSearchResults',
  props: {
    breeds: Array,
    selectedId: String
  },
  methods: {
    clicked (breed) {
      this.$emit('clicked', breed)
    }
  }
}
</script>
