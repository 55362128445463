/* eslint-disable */
import moment from 'moment-timezone'
import { mapState, mapMutations } from 'vuex';

export const SelectedDateMixin = {
    computed: {
        ...mapState('BookingSelectedDate', [
            'selectedDate',
            'localDateString',
            'datePickerStartDate',
            'datePickerEndDate'
        ]),
        ...mapState('TimeZone', [
            'selectedTimeZoneId',
            'loadedTimeZone'
        ]),
        dateString() {
            return moment(this.selectedDate).tz(moment.tz.guess()).format('YYYY-MM-DD');
        },
        startDate() {
            return moment.tz(`${this.dateString}`, this.selectedTimeZoneId);
        },
        endDate() {
            return this.startDate.clone().add(1, 'day');
        },
        selectedDates() {
            if(!this.loadedTimeZone) {
                return {
                    start: '',
                    end: ''
                };
            }
            else {
                return {
                    start: moment(this.startDate.format()).utc().format(),
                    end: moment(this.endDate.format()).utc().format()
                }
            }
        },
        selectedDatesLocal() {
            return {
                fromLocal: !!this.localDateString ?  this.localDateString + 'T00:00:00' : '',
                toLocal: !!this.localDateString ?  this.localDateString + 'T23:59:59' : ''
            }
        }
    },
    methods: {
        ...mapMutations('BookingSelectedDate', [
            'setSelectedDate'
        ])
    }
}
