/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const PetProductCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PetProducts', [
            'findPetProductCache'
        ]),
        petId() {
            console.log('OVERRIDE COMPUTED - petId');
        },
        petProductCache() {
            return this.findPetProductCache(this.petId);
        },
        petProduct() {
            return !!this.petProductCache ? this.petProductCache.value : null;
        },
        loadingPetProduct() {
            return !!this.petProductCache && this.petProductCache.loading;
        }
    },
    watch: {
        petId() {
            this.refreshPetProduct();
        },
        selectedSiteId() {
            this.refreshPetProduct();
        }
    },
    methods: {
        ...mapActions('PetProducts', [
            'findPetProductBySiteId'
        ]),
        refreshPetProduct() {
            if (!this.petProductCache || !!this.petProductCache.retry) {
                this.tryFindPetProductBySiteId();
            }
        },
        tryFindPetProductBySiteId() {
            const self = this;
            if (!self.petId) {
                return;
            }
            self.error = '';
            self.findPetProductBySiteId({ siteId: self.selectedSiteId, petId: self.petId })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetProduct();
    }
}
