<template>
  <labeled-form-control label="Any notes for us?">
    <textarea v-model="noteText" class="form-control" cols="50" placeholder="Add notes (optional)" rows="5" :disabled="clientNoteUpdated"></textarea>
  </labeled-form-control>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ClientNoteUploadMixin } from '@/mixins/Registration/ClientNoteUploadMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'

export default {
  name: 'ClientMedicalNote',
  mixins: [ClientNoteUploadMixin, ClientOnboardingInviteCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedClientAddressEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    ownerId () {
      return this.invite ? this.invite.clientId : ''
    },
    clientNoteUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.clientNoteUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedClientNote'])
  },
  watch: {
    async updatedClientAddressEvent () {
      if (!this.clientNoteUpdated) {
        try {
          await this.tryUploadClientNote()
        } finally {
          this.triggerUpdatedClientNote()
        }
      } else {
        this.triggerUpdatedClientNote()
      }
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl
  }
}
</script>
