/* eslint-disable */
import { CacheHelper } from '@/helpers/CacheHelper'
import { WebApi } from '@/services/WebApi'

const state = () => ({
    imageCaches: []
})

const getters = {
    findImageCache: (state) => (key) => {
        return state.imageCaches.find(cc => cc.key === key);
    }
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.imageCaches.push({ key: key, values: [], loading: !!loading, retry: false });
            }
        }
    },
    setImages(state, { keys, images }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            const ownerImages = images.filter(i => CacheHelper.ownerCacheKey(i) === key);
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.values = ownerImages;
                existing.loading = false;
            } else {
                state.imageCaches.push({ key: key, values: ownerImages, loading: false });
            }
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.imageCaches.find(cc => cc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    }
}

const actions = {
    searchImagesBySiteId({ commit }, { keys, siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: keys, loading: true });
            WebApi.post('Portal/' + siteId + '/Image/Search', payload)
                .then(response => {
                    commit('setImages', { keys: keys, images: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', keys);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
