/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    loadedBookingTerm: false,
    loadingBookingTerm: false,
    bookingTerm: null,
    attempted: false
})

const mutations = {
    setLoading(state, fetching) {
        state.loadingBookingTerm = !!fetching;
        state.loadedBookingTerm = false;
        state.attempted = true;
    },
    setBookingTerm(state, bookingTerm) {
        state.bookingTerm = bookingTerm;
        state.loadingBookingTerm = false;
        state.loadedBookingTerm = true;
    }
}

const actions = {
    fetchBookingTermBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/'+ siteId +'/BookingTerm')
                .then(response => {
                    commit('setBookingTerm', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
