/* eslint-disable */
import { WebApi } from '../../services/WebApi';

const state = () => ({
    appSettings: []
})

const getters = {
    findAppSetting: (state) => (key) => {
        return state.appSettings.find(sc => sc.key === key);
    }
}

const mutations = {
    setSetting(state, { key, setting }) {
        var existing = state.appSettings.find(sc => sc.key === key);
        if (!!existing) {
            const update = Object.assign({}, existing, {
                value : setting,
                loading : false,
                attempted : true
            })
            const index = state.appSettings.indexOf(existing);
            state.appSettings.splice(index, 1, update);
        } else {
            state.appSettings.push({ key: key, value: setting, loading: false, attempted: true });
        }
    },
    setRetry(state, key) {
        var existing = state.appSettings.find(sc => sc.key === key);
        if (existing) {
            existing.retry = true;
            existing.loading = false;
            existing.attempted = true;
        }
    },
    setLoading(state, { key, loading }) {
        var existing = state.appSettings.find(sc => sc.key === key);
        if (!!existing) {
            existing.loading = loading;
            existing.retry = false;
            existing.attempted = true;
        } else {
            state.appSettings.push({ key: key, value: null, loading: loading });
        }
    }
}

const actions = {
    fetchAppSettingBySiteId({ commit }, { key, siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            WebApi.get('Portal/' + siteId + '/AppSetting?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setSetting', { key: key, setting: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
