/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const CountryCacheMixin = {
  mixins: [SelectedSiteIdMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapState('Countries', [
      'fetchedCountries',
      'fetchingCountries',
      'country'
    ]),
    countryName () {
      return this.country ? this.country.name : ''
    },
    currency () {
      return this.country ? this.country.currency : ''
    },
    currencySymbol () {
      return this.country ? this.country.currencySymbol : ''
    }
  },
  watch: {
    selectedSiteId () {
      this.refreshCountries()
    }
  },
  methods: {
    ...mapActions('Countries', [
      'fetchCountriesBySiteId'
    ]),
    refreshCountries () {
      if (!this.fetchedCountries && !this.fetchingCountries) {
        this.tryFetchCountriesBySiteId()
      }
    },
    tryFetchCountriesBySiteId () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchCountriesBySiteId(self.selectedSiteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshCountries()
  }
}
