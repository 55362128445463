<template>
    <div></div>
</template>

<script>
/* eslint-disable */
    import { mapMutations } from 'vuex'
    import { ClientCacheMixin } from '@/mixins/Booking/ClientCacheMixin'
    
    export default {
        name: 'ClientSet',
        mixins: [ClientCacheMixin],
        methods: {
            ...mapMutations('BookingUpload', [
                'setClientId',
                'setClientTypeId',
                'setFirstName',
                'setLastName',
                'setEmail',
                'setTelephoneMobile'
            ])
        },
        watch: {
            client(value) {
                if(!!value) {
                    this.setClientId(!!value.id ? value.id : '');
                    this.setClientTypeId(1);
                    this.setFirstName(!!value.firstName ? value.firstName : '');
                    this.setLastName(!!value.lastName ? value.lastName : '');
                    this.setEmail(!!value.email ? value.email : '');
                    this.setTelephoneMobile(!!value.telephoneMobile ? value.telephoneMobile : '');
                }
            }
        }
    }
</script>
