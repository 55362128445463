/* eslint-disable */
import { mapActions } from 'vuex';
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { MarketingSettingCacheMixin } from "./MarketingSettingCacheMixin";

export const MarketingSettingUpdateMixin = {
    mixins: [MarketingSettingCacheMixin],
    data () {
        return {
            marketing: null,
            marketingSettingValidation: ''
        }
    },
    computed: {
        marketingSettingUpdateModel() {
            return !!this.marketingSettingCache ? Object.assign({}, this.marketingSettingCache, {
                marketing: this.marketing
            }) : {};
        },
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        }
    },
    methods: {
        ...mapActions('RegistrationMarketingSetting', [
            'updateMarketingSetting',
        ]),
        tryUpdateMarketingSetting() {
            const self = this;
            if (self.marketing === null) {
                self.marketingSettingValidation = 'Please select an option'
                return;
            }
            self.marketingSettingValidation = ''
            self.error = '';
            return self.updateMarketingSetting({ inviteId: self.inviteId, payload: self.marketingSettingUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
