/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';
import { ClientOnboardingInviteCacheMixin } from './ClientOnboardingInviteCacheMixin';

export const ClientOnboardingInviteViewMixin = {
    mixins: [ClientOnboardingInviteCacheMixin],
    watch: {
        clientOnboardingInviteStatusId(value){
            if(value === 1) {
                this.tryViewClientOnboardingInvite();
            }
        }
    },
    methods: {
        ...mapActions('ClientOnboardingInvites', [
            'viewClientOnboardingInvite'
        ]),
        tryViewClientOnboardingInvite() {
            const self = this;
            if (!self.inviteId) {
                return;
            }
            self.error = '';
            self.viewClientOnboardingInvite(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
