import { PaymentHelper } from '@/helpers/PaymentHelper'
import { WebApi } from '@/services/WebApi'
import axios from 'axios'
import moment from 'moment'

const state = () => ({
  paymentTokenPromise: null,
  paymentLoginRoute: ''
})

const mutations = {
  setPaymentTokenPromise (state, promise) {
    state.paymentTokenPromise = promise
  },
  clearPaymentTokenPromise (state) {
    state.paymentTokenPromise = null
  },
  setPaymentLoginRoute (state, route) {
    state.paymentLoginRoute = route
  }
}

const actions = {
  fetchPaymentToken ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const token = PaymentHelper.getToken()
      if (!!token && moment(token.expiresUtc).isAfter(moment())) {
        resolve(token)
      } else {
        if (!state.paymentTokenPromise) {
          commit('setPaymentTokenPromise', new Promise((resolve, reject) => {
            (token ? dispatch('refreshPaymentToken', token) : dispatch('loginPaymentUser'))
              .then(response => resolve(response))
              .catch(error => reject(error))
              .finally(() => commit('clearPaymentTokenPromise'))
          }))
        }
        state.paymentTokenPromise
          .then(response => resolve(response))
          .catch(error => reject(error))
      }
    })
  },
  refreshPaymentToken ({ dispatch }, token) {
    return new Promise((resolve, reject) => {
      axios.create({
        baseURL: PaymentHelper.getBaseUrl()
      }).put('User/Token/Refresh', token)
        .then(response => {
          PaymentHelper.setToken(response.data)
          resolve(response.data)
        })
        .catch(() => {
          PaymentHelper.clearToken()
          dispatch('loginPaymentUser')
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    })
  },
  loginPaymentUser ({ state }) {
    return new Promise((resolve, reject) => {
      WebApi.post(state.paymentLoginRoute)
        .then(response => {
          PaymentHelper.setToken(response.data)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
