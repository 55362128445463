/* eslint-disable */
export const StatusEmitterMixin = {
    computed: {
        available() {
            console.log('OVERRIDE COMPUTED - available');
        },
        loading() {
            console.log('OVERRIDE COMPUTED - loading');
        },
        status() {
            return {
                available: this.available,
                loading: this.loading
            }
        }
    },
    watch: {
        status() {
            this.emitStatus();
        }
    },
    methods: {
        emitStatus() {
            this.$emit('input', this.status);
        }
    },
    created() {
        this.emitStatus();
    }
}
