import VueCookies from 'vue-cookies'

export const PaymentHelper = {
  getBaseUrl () {
    return process.env.VUE_APP_AXIOS_PAYMENT_BASE_URL || '#{Project.PaymentApi.Endpoint}'
  },
  getToken () {
    return VueCookies.get(PaymentHelper.getTokenName())
  },
  setToken (token) {
    VueCookies.set(PaymentHelper.getTokenName(), token, -1, null, null, true, 'Strict')
  },
  clearToken () {
    VueCookies.remove(PaymentHelper.getTokenName())
  },
  getTokenName () {
    return 'payment_token'
  }
}
