/* eslint-disable */
<template>
  <div class="w-100 d-flex justify-content-between">
    <div class="summary-product-item">{{product.name}}</div>
    <div>{{currencySymbol}} {{product.amount.toFixed(2).toString()}}</div>
  </div>
</template>

<script>
/* eslint-disable */
  import { CountryCacheMixin } from '@/mixins/Booking/CountryCacheMixin'

  export default {
    name: 'SummaryProductItem',
    mixins: [CountryCacheMixin],
    props: {
      product: Object
    }
  }   
</script>
