/* eslint-disable */
<template>
  <div class="d-flex justify-content-center mb-2"  v-bind:class="{ 'align-items-start': siteBookingSettingServiceSelectEnabled, 'align-items-center': !siteBookingSettingServiceSelectEnabled }">
    <div class="mr-2">
        <img v-if="heroImage" class="pet-photo" :src="heroImage.thumbnails.small" width="100" :alt="pet.name">
        <img v-else class="pet-photo" src="../../assets/Images/DogProfile_Blue_300.svg" width="100" alt="DefaultPetImage"/>
    </div>
    <div class="d-flex flex-column align-items-start w-100">
        <div class="summary-pet-name text-left">{{petName}} - {{breedName}}</div>
        <product-item v-for="item in serviceItems" :key="item.id" :product="item"></product-item>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
    import { BreedCacheMixin } from '@/mixins/Booking/BreedCacheMixin';
    import { ImageCacheMixin } from '@/mixins/Booking/ImageCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import SummaryProductItem from './SummaryProductItem.vue';

    export default {
        name: 'SummaryItem',
        mixins: [BreedCacheMixin, ImageCacheMixin, SiteBookingSettingMixin],
        props: {
            pet: Object
        },
        computed: {
            petName() {
                return !!this.pet ? this.pet.name : '';
            },
            breedId() {
                return !!this.pet ? this.pet.breedId : '';
            },
            serviceItems() {
                return !!this.pet ? this.pet.serviceItems : [];
            },
            ownerId(){
                return !!this.siteBookingSettingDisplayPetProfileImage ? !!this.pet ? this.pet.id : '' : '';
            },
            ownerTypeId() {
                return !!this.siteBookingSettingDisplayPetProfileImage ? 3 : 0;
            },
            heroImage() {
                return this.images.length > 0 ? this.images[0] : null;
            }
        },
        components: {
            'product-item': SummaryProductItem
        }
    }
</script>
