import Vue from 'vue'
import Vuex from 'vuex'
import ApplicationEvents from './Modules/ApplicationEvents'
import Sites from './Modules/Sites'
import Logos from './Modules/Logos'
import SchedulePaymentAccounts from './Modules/SchedulePaymentAccounts'
import ScheduleAppointments from './Modules/ScheduleAppointments'
import ScheduleResources from './Modules/ScheduleResources'
import SchedulePets from './Modules/SchedulePets'
import FormatOptionsDate from './Modules/FormatOptionsDate'
import FormatSettingDate from './Modules/FormatSettingDate'
import FormatOptionsTime from './Modules/FormatOptionsTime'
import FormatSettingTime from './Modules/FormatSettingTime'
import CountryFormats from './Modules/CountryFormats'
import ClientCheckouts from './Modules/ClientCheckouts'
import PaymentTokens from './Modules/PaymentTokens'
import Countries from './Modules/Countries'
import ClientOnboardingInvites from './Modules/ClientOnboardingInvites'
import RegistrationAlternateContact from './Modules/RegistrationAlternateContact'
import RegistrationVetContact from './Modules/RegistrationVetContact'
import RegistrationMarketingSetting from './Modules/RegistrationMarketingSetting'
import RegistrationPets from './Modules/RegistrationPets'
import RegistrationPetNotes from './Modules/RegistrationPetNotes'
import RegistrationClientNotes from './Modules/RegistrationClientNotes'
import RegistrationClients from './Modules/RegistrationClients'
import RegistrationClientAddress from './Modules/RegistrationClientAddress'
import RegistrationNotifications from './Modules/RegistrationNotifications'
import RegistrationBreeds from './Modules/RegistrationBreeds'
import PostcodeSearches from './Modules/PostcodeSearches'
import RegistrationBreedSearches from './Modules/RegistrationBreedSearches'
import ClientNotificationSetting from './Modules/ClientNotificationSetting'
import Authentication from './Modules/Authentication'
import Resources from './Modules/Resources'
import Pets from './Modules/Pets'
import PetDurations from './Modules/PetDurations'
import OpeningHours from './Modules/OpeningHours'
import ServiceProducts from './Modules/ServiceProducts'
import WorkingHours from './Modules/WorkingHours'
import AppointmentSearches from './Modules/AppointmentSearches'
import EventSearches from './Modules/EventSearches'
import OneOffHourSearches from './Modules/OneOffHourSearches'
import BookingUpload from './Modules/BookingUpload'
import TimeZone from './Modules/TimeZone'
import AppSettings from './Modules/AppSettings'
import Addresses from './Modules/Addresses'
import Breeds from './Modules/Breeds'
import BreedSearches from './Modules/BreedSearches'
import Subscription from './Modules/Subscription'
import BookingSelectedDate from './Modules/BookingSelectedDate'
import Booking from './Modules/Booking'
import Images from './Modules/Images'
import RecaptchaSettings from './Modules/RecaptchaSettings'
import BookingSettings from './Modules/BookingSettings'
import Client from './Modules/Client'
import BreedProducts from './Modules/BreedProducts'
import PetProducts from './Modules/PetProducts'
import BookingTerm from './Modules/BookingTerm'
import BookingSuggestion from './Modules/BookingSuggestion'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Addresses,
    ApplicationEvents,
    Sites,
    Logos,
    SchedulePaymentAccounts,
    ScheduleAppointments,
    ScheduleResources,
    SchedulePets,
    FormatOptionsDate,
    FormatSettingDate,
    FormatOptionsTime,
    FormatSettingTime,
    ClientCheckouts,
    PaymentTokens,
    Countries,
    RegistrationAlternateContact,
    RegistrationVetContact,
    RegistrationMarketingSetting,
    RegistrationPets,
    RegistrationBreeds,
    RegistrationPetNotes,
    RegistrationClientNotes,
    RegistrationClientAddress,
    RegistrationNotifications,
    ClientOnboardingInvites,
    RegistrationClients,
    CountryFormats,
    PostcodeSearches,
    RegistrationBreedSearches,
    ClientNotificationSetting,
    Authentication,
    Resources,
    Pets,
    PetDurations,
    OpeningHours,
    ServiceProducts,
    WorkingHours,
    AppointmentSearches,
    EventSearches,
    OneOffHourSearches,
    BookingUpload,
    TimeZone,
    AppSettings,
    Breeds,
    BreedSearches,
    Subscription,
    BookingSelectedDate,
    Booking,
    Images,
    RecaptchaSettings,
    BookingSettings,
    Client,
    BreedProducts,
    PetProducts,
    BookingTerm,
    BookingSuggestion
  }
})
