/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const PetCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationPets', [
            'loadedPets',
            'petCaches'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        }
    },
    watch: {
        inviteId() {
            this.refreshPets();
        }
    },
    methods: {
        ...mapActions('RegistrationPets', [
            'findPets'
        ]),
        refreshPets() {
            if (!this.loadedPets) {
                this.tryFindPets();
            }
        },
        tryFindPets() {
            const self = this;
            if (!self.inviteId) {
                return;
            }
            self.error = '';
            self.findPets(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPets();
    }
}
