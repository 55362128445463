import { mapActions, mapGetters } from 'vuex'
import { SelectedSiteMixin } from '../Site/SelectedSiteMixin'
import { CacheHelper } from '@/helpers/CacheHelper'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const LogoCacheMixin = {
  mixins: [SelectedSiteMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters('Logos', [
      'findLogoCache'
    ]),
    logoFilterModel () {
      return {
        siteId: this.selectedSiteId
      }
    },
    logoFilterModelIsValid () {
      return !!this.logoFilterModel.siteId
    },
    logoCacheKey () {
      return CacheHelper.getObjectCacheKey(this.logoFilterModel)
    },
    logoCache () {
      return this.findLogoCache(this.logoCacheKey)
    },
    logo () {
      return this.logoCache ? this.logoCache.value : null
    },
    logoUrl () {
      return this.logo ? this.logo.url : ''
    },
    loadingLogo () {
      return !!this.logoCache && this.logoCache.loading
    }
  },
  watch: {
    logoCacheKey () {
      this.refreshLogo()
    }
  },
  methods: {
    ...mapActions('Logos', [
      'fetchLogo'
    ]),
    refreshLogo () {
      if (!this.logoCache || this.logoCache.retry) {
        this.tryFetchLogo()
      }
    },
    tryFetchLogo () {
      const self = this
      if (!self.logoFilterModelIsValid) {
        return
      }
      self.error = ''
      self.fetchLogo({ key: self.logoCacheKey, payload: self.logoFilterModel })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshLogo()
  }
}
