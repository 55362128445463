import { WebApi } from '@/services/WebApi'

const state = () => ({
  dateFormatOptions: [],
  fetchingDateFormatOptions: false,
  fetchedDateFormatOptions: false
})

const mutations = {
  setFetching (state, fetching) {
    state.fetchedDateFormatOptions = false
    state.fetchingDateFormatOptions = !!fetching
  },
  setDateFormatOptions (state, dateFormatOptions) {
    state.dateFormatOptions = dateFormatOptions
    state.fetchedDateFormatOptions = true
    state.fetchingDateFormatOptions = false
  }
}

const actions = {
  fetchDateFormatOptions ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('DateFormatSetting/Option')
        .then(response => {
          commit('setDateFormatOptions', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  },
  fetchDateFormatOptionsBySiteId ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Portal/' + siteId + '/DateFormatSetting/Option')
        .then(response => {
          commit('setDateFormatOptions', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
