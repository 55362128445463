<template>
    <settings-checker>
        <div class="booking">
            <div class="booking-wrapper">
                <petstar-ribbon></petstar-ribbon>
                <div class="booking-divider booking-divider-left">
                    <div class="booking-content">
                        <booking-header></booking-header>
                        <div class="w-100 d-block d-md-none px-4 mb-3">
                            <home-button></home-button>
                        </div>
                        <opening-hours></opening-hours>
                    </div>
                    <div class="fixed-buttons d-none d-md-block">
                        <home-button></home-button>
                    </div>
                </div>
                <div class="booking-divider booking-divider-right">
                    <display-banner></display-banner>
                    <div class="d-flex flex-column justify-content-center align-items-center my-5 px-4">
                        <error-banner v-if="error" :message="error"></error-banner>
                        <email-input v-model="email"></email-input>
                        <input v-model="password"
                            type="password"
                            class="form-control mt-2"
                            placeholder="Password"
                            aria-label="Password"
                            @keydown.enter="tryLogin">
                        <button class="btn btn-secondary w-100  mt-3"
                            @click="tryLogin"
                            :disabled="disabled">
                            <i class="fas fa-sign-in-alt"></i>
                            Login
                            <span v-if="loading" class="inline-loader"></span>
                        </button>
                        <button class="btn btn-outline-tertiary w-100 mt-3"
                            @click="gotoForgotPassword">
                            Forgot Password?
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </settings-checker>
</template>

<script>
/* eslint-disable */
    import { mapActions, mapState } from 'vuex'
    import { ErrorHelper } from '../../helpers/ErrorHelper'
    import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin'
    import EmailInput from '../../components/Layout/EmailInput.vue'
    import vueCookies from 'vue-cookies'
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'
    import PetstarRibbon from '../Booking/PetstarRibbon.vue';
    import DisplayBanner from '../Booking/DisplayBanner.vue'
    import SettingsChecker from '../Booking/SettingsChecker.vue'
    import Header from '../Booking/Header.vue'
    import OpeningHours from '../Booking/OpeningHours.vue'
    import HomeButton from '../Booking/HomeButton.vue'

    export default {
        name: 'Authentication',
        mixins: [SelectedSiteIdMixin],
        data () {
            return {
                error: '',
                password: '',
                email: '',
                rememberEmail: false
            }
        },
        computed: {
            ...mapState('Authentication', [
                'loading',
                'user'
            ]),
            loginModel() {
                return {
                    email: this.email,
                    password: this.password
                }
            },
            loginModelValid() {
                const model = this.loginModel;
                return !!model.email && !!model.password;
            },
            disabled() {
                return !!this.loading || !this.loginModelValid;
            },
            rememberedEmail: {
                get() {
                    return vueCookies.get('email');
                },
                set(value) {
                    vueCookies.set('email', value, -1, null, null, true, 'Strict');
                }
            }
        },
        methods: {
            ...mapActions('Authentication', [
                'login',
                'logout'
            ]),
            tryLogin() {
                const self = this;
                if (!self.loginModelValid) {
                    return;
                }
                self.error = '';
                this.login({siteId: self.selectedSiteId, request: self.loginModel})
                    .then(() => {
                        if (self.rememberEmail) {
                            self.rememberedEmail = self.email;
                        } else {
                            vueCookies.remove('email');
                        }
                        self.$router.push({ name: 'booking', query: { siteId: this.selectedSiteId }});
                    })
                    .catch(error => self.error = ErrorHelper.isNotAuthenticated(error) ?
                        'Invalid username or password' :
                        ErrorHelper.getError(error));
            },
            refreshEmail() {
                this.email = !!this.rememberedEmail ? this.rememberedEmail : '';
                this.rememberEmail = !!this.email;
            },
            gotoForgotPassword(){
                this.$router.push({ name: 'forgotPassword', query: { siteId: this.selectedSiteId }})
            }
        },
        created() {
            this.refreshEmail();
            this.logout();
        },
        components: {
            'opening-hours': OpeningHours,
            'booking-header': Header,
            'settings-checker': SettingsChecker,
            'error-banner': ErrorBanner,
            'email-input': EmailInput,
            'petstar-ribbon': PetstarRibbon,
            'display-banner': DisplayBanner,
            'home-button': HomeButton
        }
    }
</script>

<style scoped>
  @media (max-width: 768px) {
    .booking-divider-right {
        height: max-content;
        min-height: 50vh !important;
    }
  }
</style>
