/* eslint-disable */
import { AppSettingCacheMixin } from "./AppSettingCacheMixin"

export const SiteBookingSettingMixin = {
    mixins: [AppSettingCacheMixin],
    computed: {
        settingKey() {
            return 'Booking';
        },
        siteBookingSettingEnabled() {
            return !!this.settingObject ? this.settingObject.bookingEnabled : false;
        },
        siteBookingSettingClientType() {
            return !!this.settingObject ? this.settingObject.clientType : 1;
        },
        siteBookingSettingServiceSelectEnabled() {
            return !!this.settingObject ? this.settingObject.serviceSelectEnabled : true;
        },
        siteBookingSettingServiceMinNotice() {
            const parsedValue = !!this.settingObject ? parseInt(this.settingObject.minNotice) : NaN;
            return !isNaN(parsedValue) ? parsedValue : 1;
        },
        siteBookingSettingServiceMinNoticeUnit() {
            return !!this.settingObject ? this.settingObject.minNoticeUnit : 'days';
        },
        siteBookingSettingServiceMaxNotice() {
            const parsedValue = !!this.settingObject ? parseInt(this.settingObject.maxNotice) : NaN;
            return !isNaN(parsedValue) ? parsedValue : 30;
        },
        siteBookingSettingServiceMaxNoticeUnit() {
            return !!this.settingObject ? this.settingObject.maxNoticeUnit : 'days';
        },
        siteBookingSettingReviewType() {
            return !!this.settingObject ? this.settingObject.reviewType : 0;
        },
        anonymousReviewRequired() {
            return this.siteBookingSettingReviewType !== 2;
        },
        authenticatedReviewRequired() {
            return this.siteBookingSettingReviewType === 0;
        },
        siteBookingSettingPricingType() {
            return !!this.settingObject ? this.settingObject.pricingType : 1;
        },
        siteBookingSettingMultipleServiceEnabled() {
            return !!this.settingObject ? this.settingObject.multipleServiceEnabled : false;
        },
        allowedMultipleServiceSelect(){
            return (this.siteBookingSettingPricingType === 1 || this.siteBookingSettingPricingType === 4) && 
                !!this.siteBookingSettingMultipleServiceEnabled;
        },
        siteBookingSettingSinglePetResourceEnabled() {
            return !!this.settingObject ? this.settingObject.singlePetResourceEnabled : true;
        },
        siteBookingSettingMaxPets() {
            const parsedValue = !!this.settingObject ? parseInt(this.settingObject.maxPets) : NaN;
            return !isNaN(parsedValue) ? parsedValue : 6;
        },
        siteBookingSettingMaxServices() {
            const parsedValue = !!this.settingObject ? parseInt(this.settingObject.maxServices) : NaN;
            return !isNaN(parsedValue) ? parsedValue : 4;
        },
        siteBookingSettingDisplayPetProfileImage() {
            return !!this.settingObject ? this.settingObject.displayPetProfileImage : false;
        },
        siteBookingSettingBlockOffEvents() {
            return !!this.settingObject ? this.settingObject.blockOffEvents : true;
        },
        defaultSettingObject() {
            return {
                bookingEnabled: false,
                clientType: 2,
                reviewType: 0,
                serviceSelectEnabled: true,
                pricingType: 1,
                multipleServiceEnabled: false,
                minNotice: 1,
                minNoticeUnit: 'days',
                maxNotice: 30,
                maxNoticeUnit: 'days',
                singlePetResourceEnabled: true,
                maxPets: 6,
                maxServices: 4,
                displayPetProfileImage: false,
                blockOffEvents: true
            }
        }
    }
}
