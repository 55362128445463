import { WebApi } from '@/services/WebApi'

const state = () => ({
  scheduleResourcesCaches: []
})

const getters = {
  findScheduleResourcesCaches: (state) => key => state.scheduleResourcesCaches.find(src => src.key === key)
}

const mutations = {
  setLoading (state, { key, loading }) {
    const existing = state.scheduleResourcesCaches.find(src => src.key === key)
    if (existing) {
      existing.loading = !!loading
      existing.retry = false
    } else {
      state.scheduleResourcesCaches.push({ key: key, values: [], loading: !!loading })
    }
  },
  setResources (state, { key, resources }) {
    const existing = state.scheduleResourcesCaches.find(src => src.key === key)
    if (existing) {
      existing.values = resources
      existing.loading = false
    } else {
      state.scheduleResourcesCaches.push({ key: key, values: resources, loading: false })
    }
  },
  setRetry (state, key) {
    const existing = state.scheduleResourcesCaches.find(src => src.key === key)
    if (existing) {
      existing.retry = true
      existing.loading = false
    }
  }
}

const actions = {
  fetchScheduleResources ({ commit }, { key, scheduleId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: key, loading: true })
      WebApi.get('ClientAppointmentSchedule/' + scheduleId + '/Resource?' + new URLSearchParams(payload))
        .then(response => {
          commit('setResources', { key: key, resources: response.data })
          resolve(response)
        })
        .catch(error => {
          commit('setRetry', key)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
