/* eslint-disable */
import { WebApi } from '../../services/WebApi';

const state = () => ({
    addingBooking: false,
    booking: null
})

const mutations = {
    setAdding(state, value) {
        state.addingBooking = !!value;
    },
    setBooking(state, booking) {
        state.booking = booking
    }
}

const actions = {
    addBookingAnonymous({ commit }, { siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            WebApi.post('Portal/'+ siteId +'/Booking/Anonymous', payload)
                .then(response => {
                    commit('setBooking', response.data);
                    commit('ApplicationEvents/triggerBookingAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    addBookingAuthenticated({ commit }, { siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            WebApi.post('Portal/'+ siteId +'/Booking/Authenticated', payload)
                .then(response => {
                    commit('setBooking', response.data);
                    commit('ApplicationEvents/triggerBookingAdded', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
