import { WebApi } from '@/services/WebApi'

const state = () => ({
  scheduleAppointmentsCaches: []
})

const getters = {
  findScheduleAppointmentsCache: (state) => key => state.scheduleAppointmentsCaches.find(sac => sac.key === key)
}

const mutations = {
  setLoading (state, { key, loading }) {
    const existing = state.scheduleAppointmentsCaches.find(sac => sac.key === key)
    if (existing) {
      existing.loading = !!loading
      existing.retry = false
    } else {
      state.scheduleAppointmentsCaches.push({ key: key, values: [], loading: !!loading })
    }
  },
  setAppointments (state, { key, appointments }) {
    const existing = state.scheduleAppointmentsCaches.find(sac => sac.key === key)
    if (existing) {
      existing.values = appointments
      existing.loading = false
    } else {
      state.scheduleAppointmentsCaches.push({ key: key, values: appointments, loading: false })
    }
  },
  setRetry (state, key) {
    const existing = state.scheduleAppointmentsCaches.find(sac => sac.key === key)
    if (existing) {
      existing.retry = true
      existing.loading = false
    }
  }
}

const actions = {
  fetchScheduleAppointments ({ commit }, { key, scheduleId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: key, loading: true })
      WebApi.get('ClientAppointmentSchedule/' + scheduleId + '/Appointment?' + new URLSearchParams(payload))
        .then(response => {
          commit('setAppointments', { key: key, appointments: response.data })
          resolve(response)
        })
        .catch(error => {
          commit('setRetry', key)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
