<template>
    <div></div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'
import { StatusEmitterMixin } from '@/mixins/Booking/StatusEmitterMixin'
import { AppointmentSearchMixin } from '@/mixins/Booking/AppointmentSearchMixin'
import { SelectedDateMixin } from '@/mixins/Booking/SelectedDateMixin'

export default {
    name: 'PetAppointmentConflict',
    mixins: [StatusEmitterMixin, AppointmentSearchMixin, SelectedDateMixin],
    props: {
        timeSlot: Object,
        value: Object
    },
    computed: {
        ...mapState('BookingUpload', [
            'selectedPets'
        ]),
        selectedPetIds() {
            return this.selectedPets.length > 0 ? this.selectedPets.map(p => p.id) : []; 
        },
        petAppointments() {
            return !!this.appointments ? this.appointments.filter(appointment => this.selectedPetIds.includes(appointment.petId)) : [];
        },
        conflictingAppointment() {
            // Check for any appointment where the endTime of timeSlot is within the startUtc and endUtc
            // or the startTime of timeSlot is within the startUtc and endUtc
            // or where an appointment is within the startTime and endTime of timeSlot
            return this.petAppointments.find(appointment => {
                const startUtc = new Date(appointment.startUtc).getTime();
                const endUtc = new Date(appointment.endUtc).getTime();
                const timeSlotStartTime = new Date(this.timeSlot.startTime).getTime();
                const timeSlotEndTime = new Date(this.timeSlot.endTime).getTime();

                return (startUtc < timeSlotEndTime  && timeSlotEndTime < endUtc) ||
                    (startUtc < timeSlotStartTime && timeSlotStartTime < endUtc) ||
                    (timeSlotStartTime <= startUtc && endUtc <= timeSlotEndTime);
            });
        },
        loading() {
            return this.loadingAppointments
        },
        available() {
            return !this.loading && !this.conflictingAppointment && !this.retryAppointments
        }
    }
}
</script>
