/* eslint-disable */
import { mapActions, mapState, mapMutations } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SiteHelper } from '@/helpers/SiteHelper'

export const SiteCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapState('Sites', [
      'fetchedSites',
      'fetchingSites',
      'sites'
    ]),
    siteId() {
      return this.$route.query.siteId ? this.$route.query.siteId : '';
    },
    href() {
      return window.location.href;
    }
  },
  watch: {
    $route(to, from) {
      if (to.query.siteId !== from.query.siteId) {
        SiteHelper.clearDefaultSite();
        window.location.reload();
      }
    },
    href() {
      window.location.reload()
    }
  },
  methods: {
    ...mapActions('Authentication', [
      'logout'
    ]),
    ...mapActions('Sites', [
      'fetchSiteById'
    ]),
    ...mapMutations('Sites', [
      'clearSites'
    ]),
    refreshSites () {
      if (!this.fetchedSites && !this.fetchingSites) {
        this.tryFetchSiteById()
      }
    },
    tryFetchSiteById () {
      const self = this
      self.error = ''
      if(!self.siteId) {
        return;
      }
      self.fetchSiteById(self.siteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    },
    handleBeforeUnload() {
      SiteHelper.clearDefaultSite();
    }
  },
  created () {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    SiteHelper.clearDefaultSite();
    this.refreshSites();
  }
}
