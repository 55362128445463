/* eslint-disable */
<template>
  <div class="booking-divider booking-divider-right">
    <div class="booking-content">
      <div v-if="bookingSuggestionAccepted" class="d-flex flex-column justify-content-center align-items-center h-100 mt-5 px-4">
        <span class="text-default font-size-h3 font-weight-bold">
          Thanks for accepting the new date! Your appointment is confirmed for
        </span>
        <span class="font-weight-bold font-size-h3">
          &nbsp;{{formattedStartDay}}, {{formattedStartDate}} at {{formattedStartTime}}&nbsp;
        </span>
        <span class="text-default font-size-h3 font-weight-bold">
          An email confirmation will be sent to you shortly.
        </span>
      </div>
      <template v-else>
        <div class="font-size-h3 font-weight-bold mt-3">Booking Summary</div>
        <div class="text-tertiary mb-2">
            <span>{{formattedStartDay}}</span>, <span>{{formattedStartDate}}</span><br/>
            <span>@ {{formattedStartTime}}</span>
        </div>
        <error-banner v-if="error" :message="error" class="mb-2 mx-3"></error-banner>
        <summary-client></summary-client>
        <div class="timeslots" v-if="displayTerms" style="border-top: 1px solid #D9D9D9">
            <terms-and-conditions></terms-and-conditions>
        </div>
        <div class="timeslots" v-else>
            <summary-item v-for="pet in selectedPets" :key="pet.id" :pet="pet"></summary-item>
            <total-amount></total-amount>
        </div>
        <div class="d-flex flex-column align-items-center w-100">
            <div class="section-header text-center px-0">
                <div class="d-flex justify-content-center align-items-center">
                    <input v-model="acceptedTerms" type="checkbox" style="cursor: pointer"/>
                    &nbsp;I agree to the&nbsp; <div class="btn-link" @click="handleDisplayTerms" style="cursor: pointer">Terms & Conditions.</div>
                </div>
                <validation-message v-if="termsMessage" :message="termsMessage" class="mb-2"></validation-message>
            </div>
            <div v-if="recaptchaEnabled && renderRecaptcha" class="mt-2">
              <div v-if="renderingRecaptcha">
                <span class="inline-loader"></span> Loading reCAPTCHA...
              </div>
              <vue-recaptcha ref="recaptcha"
                sitekey="6Lcyez4UAAAAAB0CbUGH_RZMHws77eZgJBT2LcFO"
                @render="onRecaptchaRendered"
                @verify="onRecaptchaSuccess"
                @expired="onRecaptchaFailed"
                @error="onRecaptchaFailed" />
              <validation-message v-if="recaptchaMessage" :message="recaptchaMessage"></validation-message>
            </div>
        </div>
      </template>
    </div>
    <div class="fixed-buttons" v-if="!bookingSuggestionAccepted">
        <button @click="onValidatedChanged" class="btn btn-secondary w-100 ml-2" :disabled="updatingBookingSuggestion">
            Accept
            <span v-if="updatingBookingSuggestion" class="inline-loader"></span>
        </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import moment from 'moment-timezone'
  import process from 'process'
  import { mapActions, mapState } from 'vuex';
  import { ErrorHelper } from '@/helpers/ErrorHelper';
  import { VueRecaptcha } from 'vue-recaptcha';
  import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
  import { DateFormatMixin } from '@/mixins/Booking/DateFormatMixin';
  import { TimeFormatMixin } from '@/mixins/Booking/TimeFormatMixin';
  import { RecaptchaSettingsCacheMixin } from '@/mixins/Booking/RecaptchaSettingsCacheMixin';
  import TermsAndConditions from '../Booking/TermsAndConditions.vue';
  import SummaryItem from '../Booking/SummaryItem.vue';
  import SummaryClientDetails from '../Booking/Anonymous/SummaryClientDetails.vue';
  import TotalAmount from '../Booking/TotalAmount.vue';
  import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
  import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

  export default {
    name: 'Confirmation',
    mixins: [SiteBookingSettingMixin, DateFormatMixin, TimeFormatMixin, RecaptchaSettingsCacheMixin],
    props: {
      token: String
    },
    data() {
      return {
        displayTerms: false,
        acceptedTerms: false,
        isValid: false,
        termsMessage: '',
        recaptchaMessage: '',
        completed: false,
        updatingBookingSuggestion: false,
        recaptcha: '',
        renderRecaptcha: false,
        renderingRecaptcha: false
      }
    },
    computed: {
      ...mapState('BookingSuggestion', [
        'bookingSuggestion'
      ]),
      ...mapState('BookingUpload', [
        'selectedPets',
        'startUtc'
      ]),
      ...mapState('TimeZone', [
          'selectedTimeZoneId'
      ]),
      formattedStartUtc() {
        if (!!this.startUtc) {
          return moment.utc(this.startUtc).clone().tz(this.selectedTimeZoneId)
        }
      },
      formattedStartDay(){ 
        return this.formattedStartUtc ? this.formattedStartUtc.format('dddd') : "";
      },
      formattedStartDate(){ 
        return this.formattedStartUtc ? this.formattedStartUtc.format(this.momentJSDateFormat) : "";
      },
      formattedStartTime() {
        return this.formattedStartUtc ? this.formattedStartUtc.format(this.momentJSTimeFormat) : "";
      },
      bookingSuggestionUpdateModel() {
        return {
          token: this.token,
          recaptcha: this.recaptcha
        }
      },
      bookingSuggestionUpdateModelValid() {
        const model = this.bookingSuggestionUpdateModel;
        return this.recaptchaValid && !!model.token
      },
      recaptchaEnabled() {
        return !!this.recaptchaSettings ? this.recaptchaSettings.recaptchaEnabled : false;
      },
      recaptchaValid() {
        return !this.recaptchaEnabled || !!this.bookingSuggestionUpdateModel.recaptcha;
      },
      bookingSuggestionStatus() {
        return !!this.bookingSuggestion && this.bookingSuggestion.status ?  this.bookingSuggestion.status : null; 
      },
      bookingSuggestionAccepted() {
        return !!this.bookingSuggestionStatus && this.bookingSuggestionStatus.id === 2 ? true : false;
      },
      canUpdateBookingSuggestion() {
        return !this.completed;
      }
    },
    watch: {
      acceptedTerms(value) {
        this.checkTerms();
        if(!!value && !this.renderRecaptcha && !this.completed) {
          this.renderingRecaptcha = true;
          this.renderRecaptcha = true;
        }
      },
      recaptcha(value) {
        if(!!value) {
          this.onValidatedChanged();
        }
      }
    },
    methods: {
      ...mapActions('BookingSuggestion', [
        'acceptBookingSuggestion'
      ]),
      handleDisplayTerms() {
        this.displayTerms = !this.displayTerms
      },
      onRecaptchaSuccess(response) {
        this.recaptcha = response;
      },
      onRecaptchaFailed() {
        this.recaptcha = '';
      },
      onRecaptchaRendered() {
        this.renderingRecaptcha = false;
      },
      resetRecaptcha() {
        this.onRecaptchaFailed();
        this.renderRecaptcha = false;
        this.renderingRecaptcha = false;
      },
      checkTerms() {
        this.isValid = !!this.acceptedTerms || !!this.completed;
        this.termsMessage = this.isValid ? '' : 'Please agree to the Terms and Conditions.';
      },
      checkRecaptcha() {
        this.recaptchaMessage = !this.recaptchaValid && !!this.renderRecaptcha ? 'Please answer reCAPTCHA.' : '';
      },
      onValidatedChanged() {
        const self = this;
        self.checkTerms();
        self.checkRecaptcha();
        if (!self.canUpdateBookingSuggestion || !self.isValid || !self.recaptchaValid) {
          return;
        }
        self.tryAcceptBookingSuggestion();
      },
      tryAcceptBookingSuggestion() {
        const self = this;
        if(!self.bookingSuggestionUpdateModelValid) {
          return;
        }
        self.error = '';
        self.updatingBookingSuggestion = true;
        self.acceptBookingSuggestion(self.bookingSuggestionUpdateModel)
          .then(() => { self.completed = true })
          .catch(error => {
            self.error = ErrorHelper.getError(error)
            self.resetRecaptcha();
            process.nextTick(() => {
              self.renderRecaptcha = true;
              self.renderingRecaptcha = true;
            })
          })
          .finally(() => { self.updatingBookingSuggestion = false });
      }
    },
    components: {
      'terms-and-conditions': TermsAndConditions,
      'summary-client': SummaryClientDetails,
      'summary-item': SummaryItem,
      'total-amount': TotalAmount,
      'error-banner': ErrorBanner,
      'validation-message': ValidationMessage,
      VueRecaptcha
    }
  }
</script>

<style scoped>
  @media (max-width: 768px) {
    .time-slots {
      height: max-content;
      min-height: 20vh !important;
    }
  }
</style>