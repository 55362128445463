<template>
    <settings-checker>
        <div class="booking">
            <div class="booking-wrapper">
                <petstar-ribbon></petstar-ribbon>
                <div class="booking-divider booking-divider-left">
                    <div class="booking-content">
                        <booking-header></booking-header>
                        <div class="w-100 d-block d-md-none px-4 mb-3">
                            <home-button></home-button>
                        </div>
                        <opening-hours></opening-hours>
                    </div>
                    <div class="fixed-buttons d-none d-md-block">
                        <home-button></home-button>
                    </div>
                </div>
                <div class="booking-divider booking-divider-right">
                    <display-banner></display-banner>
                    <div class="d-flex flex-column justify-content-center align-items-center mb-4 mt-5 py-5 py-md-0 px-4">
                        <div v-if="success" role="alert" class="alert alert-info alert-icon">
                            <div class="alert-content">A password reset link will be sent if we can locate the email address.</div>
                        </div>
                        <template v-if="!success">
                            <error-banner v-if="error" :message="error"></error-banner>
                            <email-input v-model="email"></email-input>
                            <button class="btn btn-tertiary w-100 mt-3"
                                @click="trySendClientPasswordReset"
                                :disabled="disabled">
                                <i class="fas fa-paper-plane"></i>
                                Send Link
                                <span v-if="loading" class="inline-loader"></span>
                            </button>
                        </template>
                        <button class="btn btn-outline-secondary w-100 mt-3"
                            @click="gotoLogin">
                            Back to Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </settings-checker>
</template>

<script>
/* eslint-disable */
    import { WebApi } from '@/services/WebApi'
    import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin'
    import { ErrorHelper } from '@/helpers/ErrorHelper'
    import EmailInput from '../../components/Layout/EmailInput.vue'
    import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'
    import PetstarRibbon from '../Booking/PetstarRibbon.vue';
    import DisplayBanner from '../Booking/DisplayBanner.vue'
    import SettingsChecker from '../Booking/SettingsChecker.vue'
    import Header from '../Booking/Header.vue'
    import OpeningHours from '../Booking/OpeningHours.vue'
    import HomeButton from '../Booking/HomeButton.vue'

    export default {
        name: 'ForgotPassword',
        mixins: [SelectedSiteIdMixin],
        data () {
            return {
                email: '',
                error: '',
                success: false,
                sending: false
            }
        },
        computed: {
            uploadModel() {
                return {
                    email: this.email
                }
            },
            uploadModelValid() {
                return !!this.uploadModel.email;
            },
            loading() {
                return !!this.sending || !!this.loadingSetting
            },
            disabled() {
                return !this.uploadModelValid || !!this.loading
            }
        },
        methods: {
            trySendClientPasswordReset() {
                const self = this;
                self.error = '';
                self.success = false;
                self.sending = true;
                if (!self.uploadModelValid) {
                    return;
                }
                self.error = '';
                WebApi.post('Portal/' + self.selectedSiteId + '/ClientPasswordReset/Send', self.uploadModel)
                .then(() => self.success = true )
                .catch(error => self.error = ErrorHelper.getError(error))
                .finally(() => self.sending = false);
            },
            gotoLogin(){
                this.$router.push({ name: 'login', query: { siteId: this.selectedSiteId } })   
            }
        },
        components: {
            'opening-hours': OpeningHours,
            'booking-header': Header,
            'settings-checker': SettingsChecker,
            'error-banner': ErrorBanner,
            'email-input': EmailInput,
            'petstar-ribbon': PetstarRibbon,
            'display-banner': DisplayBanner,
            'home-button': HomeButton
        }
    }
</script>