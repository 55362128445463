/* eslint-disable */
import { WebApi } from '@/services/WebApi';
import { ErrorHelper } from '@/helpers/ErrorHelper.js';

const state = () => ({
    loadingPetBreeds: false,
    loadedPetBreeds: false,
    petBreedCaches: []
})

const mutations = {
    setLoading(state, value) {
        state.loadingPetBreeds = !!value;
    },
    setPetBreeds(state, petBreeds) {
        state.petBreedCaches = petBreeds;
        state.loadedPetBreeds = true;
    },
    setPetBreed(state, breed) {
        const existing = state.petBreedCaches.find(bc => bc.id === breed.id);
        if (!existing) {
            state.petBreedCaches.push({...breed})
        }
    }
}

const actions = {
    fetchPetBreeds({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('ClientOnboardingInvite/' + inviteId + '/Pet/Breed')
                .then(response => {
                    commit('setPetBreeds', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
