/* eslint-disable */
const state = () => ({
    saveRegistrationEvent: 0,
    updatedClientDetailsEvent: 0,
    updatedClientAddressEvent: 0,
    updatedClientNoteEvent: 0,
    updatedPetDetailEvent: 0,
    updatedPetNoteEvent: 0,
    updatedPetNotesEvent: 0,
    updatedVetContactEvent: 0,
    updatedAcceptMarketingEvent: 0,
    bookAppointmentEvent: 0,
    bookingAddedEvent: null
})

const mutations = {
    triggerSaveRegistration(state) {
        state.saveRegistrationEvent++
    },
    triggerUpdatedClientDetails(state) {
        state.updatedClientDetailsEvent++
    },
    triggerUpdatedClientAddress(state) {
        state.updatedClientAddressEvent++
    },
    triggerUpdatedClientNote(state) {
        state.updatedClientNoteEvent++
    },
    triggerUpdatedPetDetail(state) {
        state.updatedPetDetailEvent++
    },
    triggerUpdatedPetNote(state) {
        state.updatedPetNoteEvent++
    },
    triggerUpdatedPetNotes(state) {
        state.updatedPetNotesEvent++
    },
    triggerUpdatedVetContact(state) {
        state.updatedVetContactEvent++
    },
    triggerUpdatedAcceptMarketing(state) {
        state.updatedAcceptMarketingEvent++
    },
    triggerResetUpdatedPetDetail(state){
        state.updatedPetDetailEvent = 0
    },
    triggerResetUpdatedPetNote(state) {
        state.updatedPetNoteEvent = 0
    },
    triggerBookAppointment(state) {
        state.bookAppointmentEvent++
    },
    triggerBookingAdded(state, booking) {
        state.bookingAddedEvent = booking;
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
