/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    clientOnboardingInviteCaches: []
})

const getters = {
    findClientOnboardingInviteCache: (state) => (key) => {
        return state.clientOnboardingInviteCaches.find(coic => coic.key === key);
    }
}

const mutations = {
    setClientOnboardingInvites(state, { key, invite }) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.value = invite;
            existing.loading = false;
        } else {
            state.clientOnboardingInviteCaches.push({ key: key, value: invite, loading: false });
        }
    },
    setLoading(state, { key, loading }) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientOnboardingInviteCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setRetry(state, key) {
        const existing = state.clientOnboardingInviteCaches.find(coic => coic.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    setUpdatedPet(state, { inviteId, petId }) {
        const invite = state.clientOnboardingInviteCaches.find((coic) => coic.key === inviteId);
        if (invite) {
            if (!invite.value.updatedPets.includes(petId)) {
                invite.value.updatedPets.push(petId);
            }
        }
    },
    setUpdatedPetNote(state, { inviteId, petId }){
        const invite = state.clientOnboardingInviteCaches.find((coic) => coic.key === inviteId);
        if (invite) {
            if (!invite.value.updatedPetNotes.includes(petId)) {
                invite.value.updatedPetNotes.push(petId);
            }
        }
    }
}

const actions = {
    fetchClientOnboardingInvite({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: inviteId, loading: true });
            WebApi.get('ClientOnboardingInvite/' + inviteId)
                .then(response => {
                    commit('setClientOnboardingInvites', { key: inviteId, invite: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', inviteId);
                    reject(error);
                });
        });
    },
    viewClientOnboardingInvite({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: inviteId, loading: true });
            WebApi.put('ClientOnboardingInvite/' + inviteId + '/View')
                .then(response => {
                    commit('setClientOnboardingInvites', { key: inviteId, invite: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', inviteId);
                    reject(error);
                });
        });
    },
    completeClientOnboardingInvite({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: inviteId, loading: true });
            WebApi.put('ClientOnboardingInvite/' + inviteId + '/Complete')
                .then(response => {
                    commit('setClientOnboardingInvites', { key: inviteId, invite: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', inviteId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
