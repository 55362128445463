/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const OpeningHourCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('OpeningHours', [
            'openingHours',
            'loadingOpeningHours',
            'retryOpeningHours'
        ])
    },
    watch: {
        selectedSiteId() {
            this.refreshOpeningHours()
        }
    },
    methods: {
        ...mapActions('OpeningHours', [
            'getOpeningHoursBySiteId'
        ]),
        refreshOpeningHours() {
            if (!this.openingHours || !!this.retryOpeningHours) {
                this.tryGetOpeningHoursBySiteId();
            }
        },
        tryGetOpeningHoursBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getOpeningHoursBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshOpeningHours();
    }
}
