/* eslint-disable */
<template>
  <div class="booking">
      <div class="booking-wrapper">
          <petstar-ribbon></petstar-ribbon>
          <div class="booking-divider booking-divider-left">
              <div class="booking-content">
                  <booking-header></booking-header>
                  <div class="w-100 d-block d-md-none px-4 mb-3">
                      <home-button></home-button>
                  </div>
                  <opening-hours></opening-hours>
              </div>
              <div class="fixed-buttons d-none d-md-block">
                  <home-button></home-button>
              </div>
          </div>
          <div class="booking-divider booking-divider-right">
              <display-banner></display-banner>
              <div class="d-flex flex-column justify-content-center align-items-center mb-4 mt-5 py-5 py-md-0 px-4">
                <div v-if="success" role="alert" class="alert alert-info alert-icon" >
                  <div class="alert-content">Password set! Redirecting to login in {{ countdown }}s.</div>
                </div>
                <template v-else>
                  <error-banner v-if="error" :message="error"></error-banner>
                  <div class="form-group w-100">
                    <label for="lastName" class="w-100 text-left">Password</label>
                    <input v-model="password" type="password" class="form-control" placeholder="Enter your new password" aria-label="Password" required>
                  </div>
                  <div class="form-group w-100">
                    <label for="lastName" class="w-100 text-left">Confirm Password</label>
                    <input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm your new password" aria-label="Password" required>
                    <validation-message v-if="message" :message="message"></validation-message>
                  </div>
                  <button @click="trySetPassword" class="btn btn-tertiary w-100 mt-3" :disabled="disabled">
                    <i class="fas fa-key"></i>
                    Set Password
                    <span v-if="loading" class="inline-loader"></span>
                  </button>
                  <button class="btn btn-outline-secondary w-100 mt-3"
                    @click="gotoLogin">
                    Back to Login
                  </button>
                </template>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
/* eslint-disable */
  import { WebApi } from '../../services/WebApi'
  import { ErrorHelper } from '../../helpers/ErrorHelper'
  import { SiteCacheMixin } from '@/mixins/Site/SiteCacheMixin'
  import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'
  import PetstarRibbon from '../Booking/PetstarRibbon.vue'
  import OpeningHours from '../Booking/OpeningHours.vue'
  import Header from '../Booking/Header.vue'
  import DisplayBanner from '../Booking/DisplayBanner.vue'
  import ValidationMessage from '../ErrorValidation/ValidationMessage.vue'
  import HomeButton from '../Booking/HomeButton.vue'

  export default {
      name: 'PasswordSet',
      mixins: [SiteCacheMixin],
      data() {
        return {
          countdown: 3,
          password: '',
          confirmPassword: '',
          message: '',
          loading: false,
          success: false,
          error: ''
        }
      },
      computed: {
        selected() {
          return this.sites.length > 0 ? this.sites[0] : null;
        },
        selectedSiteId() {
          return this.selected ? this.selected.id : '';
        },
        resetId () {
          return this.$route.query.id
        },
        uploadModel() {
          return {
              password: this.password
          }
        },
        disabled() {
          return !this.uploadModel.password || this.loading;
        },
      },
      watch: {
        password() {
          this.message = '';
        },
        confirmPassword() {
          this.message = ''
        }
      },
      methods: {
        trySetPassword() {
          const self = this;
          self.check();
          if(!!self.message) {
            return;
          }
          self.error = '';
          self.success = false;
          self.loading = true;
          WebApi.post('ClientPasswordReset/' + self.resetId + '/Set', self.uploadModel)
            .then(() => {
              self.success = true;
              self.startCountdown();
            })
            .catch(error => self.error = ErrorHelper.getError(error))
            .finally(() => self.loading = false);
        },
        startCountdown() {
          const self = this;
          const countdownInterval = setInterval(() => {
            if (self.countdown > 0) {
              self.countdown--;
            } else {
              clearInterval(countdownInterval);
                this.$router.push({ name: 'login', query: { siteId: this.selectedSiteId } })
            }
          }, 1000);
        },
        gotoLogin(){
          this.$router.push({ name: 'login', query: { siteId: this.selectedSiteId } })   
        },
        check() {
          this.message = this.password === this.confirmPassword ? '' : "Passwords do not match.";
        }
      },
      components: {
        'opening-hours': OpeningHours,
        'booking-header': Header,
        'error-banner': ErrorBanner,
        'petstar-ribbon': PetstarRibbon,
        'display-banner': DisplayBanner,
        'validation-message': ValidationMessage,
        'home-button': HomeButton
      }
  }
</script>

<style scoped>
  @media (max-width: 768px) {
    .booking-divider-right {
        height: max-content;
        min-height: 60vh !important;
    }
  }
</style>