/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const RecaptchaSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RecaptchaSettings', [
            'fetchedRecaptchaSettings',
            'fetchingRecaptchaSettings',
            'attempted',
            'recaptchaSettings'
        ])
    },
    methods: {
        ...mapActions('RecaptchaSettings', [
            'fetchRecaptchaSettings'
        ]),
        refreshRecaptchaSettings() {
            if (!this.fetchedRecaptchaSettings && !this.fetchingRecaptchaSettings) {
                this.tryFetchRecaptchaSettings();
            }
        },
        tryFetchRecaptchaSettings() {
            const self = this;
            self.error = '';
            self.fetchRecaptchaSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshRecaptchaSettings();
    }
}
