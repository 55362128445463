/* eslint-disable */
<template>
  <div class="d-flex flex-column align-items-start w-100">
    <div class="section-header d-flex text-default">
      <div class="col-10 px-0">Opening Times</div>
      <div class="col-2 d-flex justify-content-end align-items-center px-0">
        <i @click="toggleCollapsible" class="icon fa fas fa-chevron-down" :class="{'icon-rotate': isOpen}"></i>
      </div>
    </div>
    <div class="collapsible-content w-100" v-bind:class="{'collapsible-content-show': isOpen}">
      <span v-if="loadingOpeningHours" class="inline-loader text-center"></span>
      <template v-else>
        <div v-for="hours in openingHours" :key="hours.id" class="d-flex w-100 mt-1">
          <div class="col-4 text-left">{{hours.dayOfWeek.description}}</div>
          <div class="col-8 text-left text-default">
            <div v-if="hours.closed">CLOSED</div>
            <template v-else>
              <span>{{formatOpeningHours(hours)}}</span> - <span>{{formatClosingHours(hours)}}</span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import moment from 'moment'
  import { mapState } from 'vuex';
  import { OpeningHourCacheMixin } from '../../mixins/Booking/OpeningHourCacheMixin'
  import { TimeFormatMixin } from '@/mixins/Booking/TimeFormatMixin';

  export default {
    name: 'OpeningHours',
    mixins: [OpeningHourCacheMixin, TimeFormatMixin],
    data() {
      return {
         isOpen: true
      }
    },
    computed: {
      ...mapState('Sites', [
        'sites'
      ]),
      selected() {
        return this.sites.length > 0 ? this.sites[0] : [];
      },
      selectedSiteId() {
        return this.$route.query.siteId ? this.$route.query.siteId : this.selected ? this.selected.id : '';
      }
    },
    methods: {
      formatOpeningHours(hours) {
        return moment(hours.openingLocal).format(this.momentJSTimeFormat);
      },
      formatClosingHours(hours) {
        return moment(hours.closingLocal).format(this.momentJSTimeFormat);
      },
      toggleCollapsible () {
        this.isOpen = !this.isOpen
      }
    },
    created() {
      if (window.innerWidth < 768) {
        this.isOpen = false;
      }
    }
  }
</script>

<style scoped>
  .icon {
    font-size: 1.25rem;
    transition: transform 0.3s;
    cursor: pointer;
  }

  .icon-rotate {
    transform: rotate(180deg);
  }

  .collapsible-content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
  }
  .collapsible-content-show {
    height: max-content;
    opacity: 1;
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
  }
  @media screen and (max-width: 768px)  {
    .collapsible-content-show {
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 0.5rem;
    }
  }
</style>
