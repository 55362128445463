<template>
  <collapsed-section title="Emergency Contact">
    <error-banner v-if="error" :message="error"></error-banner>
    <labeled-form-control label="Name">
      <input v-model="name" class="form-control" type="text" placeholder="Name" :disabled="alternateContactUpdated"/>
    </labeled-form-control>
    <labeled-form-control label="Mobile">
      <input v-model="mobile" class="form-control" type="tel" placeholder="Mobile" :disabled="alternateContactUpdated"/>
    </labeled-form-control>
  </collapsed-section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { AlternateContactUploadMixin } from '../../mixins/Registration/AlternateContactUploadMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import CollapsedSection from '../Layout/CollapsedSection.vue'
import LabeledFormControl from '../Layout/LabeledFormControl.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'ClientEmergencyContactSection',
  mixins: [AlternateContactUploadMixin, ClientOnboardingInviteCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedClientNoteEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    ownerId () {
      return this.invite ? this.invite.clientId : ''
    },
    alternateContactUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.alternateContactUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedPetDetail'])
  },
  watch: {
    async updatedClientNoteEvent () {
      if (!this.alternateContactUpdated) {
        try {
          await this.tryUploadAlternateContact()
        } finally {
          this.triggerUpdatedPetDetail()
        }
      } else {
        this.triggerUpdatedPetDetail()
      }
    }
  },
  components: {
    'collapsed-section': CollapsedSection,
    'labeled-form-control': LabeledFormControl,
    'error-banner': ErrorBanner
  }
}
</script>
