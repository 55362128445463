/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper';

export const ClientCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationClients', [
            'clientCache',
            'loadingClient',
            'loadedClient'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - clientId!!!');
        },
    },
    watch: {
        inviteId() {
            this.refreshClient();
        }
    },
    methods: {
        ...mapActions('RegistrationClients', [
            'fetchClient'
        ]),
        refreshClient() {
            if (!this.loadedClient && this.inviteId) {
                this.tryFetchClient();
            }
        },
        tryFetchClient() {
            const self = this;
            if (!self.inviteId) {
                return;
            }
            self.error = '';
            self.fetchClient(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClient();
    }
}
