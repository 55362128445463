/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CacheHelper } from '../../helpers/CacheHelper';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const AppointmentSearchMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('AppointmentSearches', [
            'appointmentSearchesCleared'
        ]),
        ...mapGetters('AppointmentSearches', [
            'findAppointmentSearch'
        ]),
        selectedDates() {
            console.log('OVERRIDE COMPUTED - selectedDates ')
            return {}
        },
        searchModel() {
            return {
                startUtc: this.selectedDates.start,
                endUtc: this.selectedDates.end,
                siteIds: [this.selectedSiteId],
                statusIds: [0, 2]
            }
        },
        searchModelValid() {
            return !!this.searchModel
                && !!this.searchModel.startUtc
                && !!this.searchModel.endUtc
        },
        appointmentSearchCacheKey() {
            return CacheHelper.fullObjectCacheKey(this.searchModel);
        },
        appointmentCache() {
            return this.findAppointmentSearch(this.appointmentSearchCacheKey);
        },
        loadingAppointments() {
            return this.appointmentCache && this.appointmentCache.loading;
        },
        retryAppointments() {
            return this.appointmentCache && this.appointmentCache.retry;
        },
        appointments() {
            return this.appointmentCache ? this.appointmentCache.value.appointments : [];
        }
    },
    watch: {
        searchModel() {
            this.refreshAppointments();
        },
        appointmentSearchesCleared() {
            this.refreshAppointments();
        }
    },
    methods: {
        ...mapActions('AppointmentSearches', [
            'searchAppointmentsBySiteId'
        ]),
        refreshAppointments() {
            this.error = '';
            if (!this.appointmentCache || !!this.appointmentCache.retry) {
                this.searchAndCacheAppointments();
            }
        },
        searchAndCacheAppointments() {
            const self = this;
            if (!self.searchModelValid) {
                return;
            }
            self.searchAppointmentsBySiteId({
                key: self.appointmentSearchCacheKey,
                siteId: self.selectedSiteId,
                payload: self.searchModel
            }).catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAppointments();
    }
}
