import { mapActions, mapState } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const FormatOptionsTimeCacheMixin = {
  mixins: [SelectedSiteIdMixin],
  computed: {
    ...mapState('FormatOptionsTime', [
      'fetchingTimeFormatOptions',
      'fetchedTimeFormatOptions',
      'timeFormatOptions'
    ])
  },
  watch: {
    selectedSiteId () {
      this.refreshTimeFormatOptions()
    }
  },
  methods: {
    ...mapActions('FormatOptionsTime', [
      'fetchTimeFormatOptionsBySiteId'
    ]),
    refreshTimeFormatOptions () {
      if (!this.fetchedTimeFormatOptions && !this.fetchingTimeFormatOptions) {
        this.tryFetchTimeFormatOptionsBySiteId()
      }
    },
    tryFetchTimeFormatOptionsBySiteId () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchTimeFormatOptionsBySiteId(self.selectedSiteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshTimeFormatOptions()
  }
}
