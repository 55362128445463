<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
/* eslint-disable */
    export default {
        name: 'Public'
    }
</script>