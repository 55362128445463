/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { CountryCacheMixin } from "@/mixins/Site/CountryCacheMixin";

export const FormatCountryCacheMixin = {
    mixins: [CountryCacheMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('CountryFormats', [
            'findCountryFormatCache'
        ]),
        countryId() {
            return !!this.country ? this.country.countryId : '';
        },
        countryFormatQueryModel() {
            return {
                countryId: this.countryId
            }
        },
        countryFormatQueryModelValid() {
            return !!this.countryFormatQueryModel.countryId;
        },
        countryFormatCache() {
            return this.findCountryFormatCache(this.countryId);
        },
        countryFormat() {
            return !!this.countryFormatCache ? this.countryFormatCache.value : null;
        },
        loadingCountryFormat() {
            return !!this.countryFormatCache && this.countryFormatCache.loading;
        },
        loadedCountryFormat() {
            return !!this.countryFormatCache && this.countryFormatCache.loaded;
        }
    },
    watch: {
        countryFormatQueryModel() {
            this.refreshCountryFormat();
        }
    },
    methods: {
        ...mapActions('CountryFormats', [
            'getCountryFormat'
        ]),
        refreshCountryFormat() {
            if (!this.countryFormatCache || !!this.countryFormatCache.retry) {
                this.tryGetCountryFormat();
            }
        },
        tryGetCountryFormat() {
            const self = this;
            if (!self.countryFormatQueryModelValid) {
                return;
            }
            self.error = '';
            self.getCountryFormat({ key: self.countryId, payload: self.countryFormatQueryModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCountryFormat();
    }
}
