<template>
  <div>
    <page-navbar></page-navbar>
    <div class="main-page container">
      <page-loader v-if="loadingClientNotificationSetting"></page-loader>
      <template v-else>
        <div class="col-md-8 mx-auto">
          <error-banner v-if="error" :message="error"></error-banner>
        </div>
        <template v-if="clientNotificationSetting">
          <h2 class="text-center text-default mb-5">You are now unsubscribed from marketing communications.</h2>
        </template>
      </template>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ClientNotificationSettingUnsubscribeMixin } from '@/mixins/Unsubscribe/ClientNotificationSettingUnsubscribeMixin'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'
import PageLoader from '../Loading/PageLoader.vue'
import PageNavBar from '../Layout/PageNavBar.vue'
import PageFooter from '../Layout/PageFooter.vue'

export default {
  name: 'UnsubscribeViewer',
  mixins: [ClientNotificationSettingUnsubscribeMixin],
  computed: {
    ...mapState('ClientNotificationSetting', [
      'clientNotificationSetting'
    ]),
    settingId () {
      return this.$route.query.id
    }
  },
  created () {
    this.tryUnsubscribeMarketingSetting()
  },
  components: {
    'error-banner': ErrorBanner,
    'page-loader': PageLoader,
    'page-navbar': PageNavBar,
    'page-footer': PageFooter
  }
}
</script>
