<template>
    <Vidle class="d-none"
        :duration="idleTimeDuration"
        @remind="handleRemind"
        @refresh="handleRefresh"
        :reminders="[idleTimeReminder]"
        :events="['mousedown', 'keydown', 'touchstart', 'touchend', 'scroll']"
        @idle="handleIdle">
    </Vidle>
</template>

<script>
/* eslint-disable */
import Vidle from 'v-idle'
import Swal from 'sweetalert2'
import { BookingSettingsCacheMixin } from '@/mixins/Booking/BookingSettingsCacheMixin';

export default {
    name: 'Idle',
    mixins: [BookingSettingsCacheMixin],
    computed: {
        idleTimeDuration() {
            return this.bookingSettings ? this.bookingSettings.idleTimeDuration : 300;
        },
        idleTimeReminder() {
            return this.bookingSettings ? this.bookingSettings.idleTimeReminder : 60;
        },
        sweetAlertTimer() {
            return this.idleTimeReminder * 1000;
        }
    },
    methods: {
        handleRemind() {
            Swal.fire({
                    html: `<div>
                        <div>Your session has been inactive for a while.</div>
                        <div><b>Do you still need more time?</b</div>
                        </div>`,
                    icon: 'warning',
                    timer: this.sweetAlertTimer,
                    timerProgressBar: true,
                    confirmButtonText: 'Yes, I need more time.'
                });
        },
        handleIdle() {
            this.$emit('idle');
        },
        handleRefresh(event) {
            if (Swal.isVisible() && !Swal.getContent().contains(event.target)) {
                Swal.close();
            }
        }
    },
    components: {
        Vidle
    }
}
</script>
