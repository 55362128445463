/* eslint-disable */
import { mapActions } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { VetContactAddMixin } from "./VetContactAddMixin";

export const VetContactUploadMixin = {
    mixins: [VetContactAddMixin],
    computed: {
        vetContactUpdateModel() {
            return !!this.vetContactCache ? Object.assign({}, this.vetContactCache, {
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            }) : {};
        }
    },
    watch: {
        vetContactCache() {
            this.refreshVetContactUploadModel();
        }
    },
    methods: {
        ...mapActions('RegistrationVetContact', [
            'updateVetContact'
        ]),
        refreshVetContactUploadModel() {
            this.name = !!this.vetContactCache ? this.vetContactCache.name : '';
            this.email = !!this.vetContactCache ? this.vetContactCache.email : '';
            this.mobile = !!this.vetContactCache ? this.vetContactCache.mobile : '';
            this.otherInfo = !!this.vetContactCache ? this.vetContactCache.otherInfo : '';
        },
        tryUploadVetContact() {
            if (!!this.vetContactCache) {
                return this.tryUpdateVetContact();
            } else {
                return this.tryAddVetContact();
            }
        },
        tryUpdateVetContact() {
            const self = this;
            self.error = '';
            return self.updateVetContact({ inviteId: self.inviteId, payload: self.vetContactUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshVetContactUploadModel();
    }
}
