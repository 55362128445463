/* eslint-disable */
<template>
  <div class="timeslots booking-summary pt-2">
    <summary-item v-for="pet in selectedPets" :key="pet.id" :pet="pet"></summary-item>
    <total-amount></total-amount>
  </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import SummaryItem from './SummaryItem.vue'
    import TotalAmount from './TotalAmount.vue'
    
    export default {
        name: 'Summary',
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets',
            ])
        },
        components: {
          'summary-item': SummaryItem,
          'total-amount': TotalAmount
        }
    }
</script>

<style scoped>
  .booking-summary {
    @media (max-width: 768px) {
        min-height: max-content;
    }
  }
</style>
