<template>
  <div class="dropdown">
    <div class="dropdown-menu w-100 d-block">
        <div v-for="breed in breeds"
          :key="breed.id"
          @click="handleClick(breed)"
          role="option"
          class="dropdown-item">
          <span class="text">
            {{ breed.name }}
          </span>
        </div>
        <template v-if="breeds.length === 0">
            <a role="option" class="dropdown-item"> No results found </a>
        </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'PetBreedSearchFieldResults',
    props: {
      breeds: []
    },
    methods: {
      handleClick (breed) {
        this.$emit('breedSelected', breed)
      }
    }
  }
</script>

<style scoped>
  .dropdown-item {
    font-size: 0.75rem;
    cursor: pointer;
  }
</style>
