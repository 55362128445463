import { mapActions, mapState } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteMixin } from '../Site/SelectedSiteMixin'

export const FormatOptionsDateCacheMixin = {
  mixins: [SelectedSiteMixin],
  computed: {
    ...mapState('FormatOptionsDate', [
      'fetchingDateFormatOptions',
      'fetchedDateFormatOptions',
      'dateFormatOptions'
    ])
  },
  watch: {
    selectedSiteId () {
      this.refreshDateFormatOptions()
    }
  },
  methods: {
    ...mapActions('FormatOptionsDate', [
      'fetchDateFormatOptions'
    ]),
    refreshDateFormatOptions () {
      if (!this.fetchedDateFormatOptions && !this.fetchingDateFormatOptions) {
        this.tryFetchDateFormatOptions()
      }
    },
    tryFetchDateFormatOptions () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchDateFormatOptions()
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshDateFormatOptions()
  }
}
