<template>
  <div style="height: 100vh !important; overflow-y: hidden">
    <page-navbar></page-navbar>
    <div class="main-page justify-content-start align-items-center">
      <error-banner v-if="error" :message="error"></error-banner>
      <template v-else>
        <template v-if="contract">
          <download-link v-if="isSigned" :contract="contract"></download-link>
          <template v-else>
            <pdf-viewer :contract="contract" :signature-required="signatureRequired"></pdf-viewer>
            <signing-form v-if="signatureRequired" :contract="contract"
              @signed="tryCompleteContract"></signing-form>
          </template>
        </template>
        <template v-else>
          <page-loader v-if="loading"></page-loader>
        </template>
      </template>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { WebApi } from '@/services/WebApi'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import PageLoader from '../Loading/PageLoader.vue'
import DownloadLink from './DownloadLink.vue'
import PdfViewer from './PdfViewer.vue'
import SigningForm from './SigningForm.vue'
import ErrorBanner from '../ErrorValidation/ErrorBanner.vue'
import PageNavBar from '../Layout/PageNavBar.vue'
import PageFooter from '../Layout/PageFooter.vue'

export default {
  name: 'ContractViewer',
  data () {
    return {
      error: '',
      loading: false,
      contract: null
    }
  },
  computed: {
    contractId () {
      return this.$route.query.id
    },
    isSigned () {
      return !!this.contract && this.contract.status.id === 5
    },
    signatureRequired () {
      return !!this.contract && this.contract.signatureRequired
    }
  },
  methods: {
    tryFetchContract () {
      const self = this
      if (!self.contractId) {
        return
      }
      self.error = ''
      self.loading = true
      WebApi.get('ClientContract/' + self.contractId)
        .then(response => { self.contract = response.data })
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    },
    tryCompleteContract () {
      const self = this
      if (!self.contractId) {
        return
      }
      self.error = ''
      self.loading = true
      WebApi.put('ClientContract/' + self.contractId + '/Complete')
        .then(response => { self.contract = response.data })
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    }
  },
  components: {
    'page-loader': PageLoader,
    'download-link': DownloadLink,
    'pdf-viewer': PdfViewer,
    'signing-form': SigningForm,
    'error-banner': ErrorBanner,
    'page-navbar': PageNavBar,
    'page-footer': PageFooter
  },
  created () {
    this.tryFetchContract()
  }
}
</script>

<style scoped>
  .main-page {
    min-height: calc(100vh - 6.25rem);
    margin-block: 0;
  }
</style>
