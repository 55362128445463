/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi.js'

const state = () => ({
    breedCaches: []
})

const getters = {
    findBreedCache: (state) => (key) => {
        return state.breedCaches.find(bc => bc.key === key);
    }
}

const mutations = {
    setLoading(state, { keys, loading }) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.breedCaches.find(bc => bc.key === key);
            if (!!existing) {
                existing.loading = !!loading;
                existing.retry = false;
            } else {
                state.breedCaches.push({ key: key, value: null, loading: !!loading });
            }
        }
    },
    setBreed(state, breed) {
        const existing = state.breedCaches.find(bc => bc.key === breed.id);
        if (!!existing) {
            existing.value = breed;
            existing.loading = false;
        } else {
            state.breedCaches.push({ key: breed.id, value: breed, loading: false });
        }
    },
    setRetry(state, keys) {
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            var existing = state.breedCaches.find(bc => bc.key === key);
            if (!!existing) {
                existing.retry = true;
                existing.loading = false;
            }
        }
    }
}

const actions = {
    fetchBreedBySiteId({ commit }, {siteId, id}) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { keys: [id], loading: true });
            WebApi.get('Portal/'+ siteId +'/Breed/' + id)
                .then(response => {
                    commit('setBreed', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { keys: [id], loading: false });
                    } else {
                        commit('setRetry', [id]);
                    }
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
