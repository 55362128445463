/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper.js';
import { CacheHelper } from '@/helpers/CacheHelper.js';
import { SelectedSiteMixin } from '../Site/SelectedSiteMixin';
import { SortableSearchMixin } from './SortableSearchMixin.js';

export const BreedSearchMixin = {
    mixins: [SelectedSiteMixin, SortableSearchMixin],
    data() {
        return {
            error: '',
            searchTerm: '',
            timeoutId: null,
            skip: 0,
            take: 10
        }
    },
    computed: {
        ...mapGetters('RegistrationBreedSearches', [
            'findBreedSearch'
        ]),
        breedSearchModel() {
            return {
                searchTerm: this.searchTerm,
                locationId: this.selectedSiteId,
                skip: this.skip,
                take: this.take,
                ascending: this.ascending
            }
        },
        breedSearchModelValid() {
            return !!this.breedSearchModel.searchTerm;
        },
        breedSearchCacheKey() {
            return CacheHelper.getObjectCacheKey(this.breedSearchModel);
        },
        breedSearchCache() {
            return this.findBreedSearch(this.breedSearchCacheKey);
        },
        breeds() {
            return this.breedSearchCache ? this.breedSearchCache.values : [];
        },
        loadingBreedSearch() {
            return this.breedSearchCache && this.breedSearchCache.loading;
        },
        finished() {
            return this.breeds.length < this.breedSearchModel.take;
        },
        pageNumber() {
            return 1 + (this.skip / this.take);
        },
        breedResultsReady() {
            return this.breedSearchModelValid && !!this.breedSearchCache && !this.loadingBreedSearch;
        }
    },
    watch: {
        selectedSiteId() {
            this.skip = 0;
            this.refreshBreedSearch();
        },
        searchTerm() {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.skip = 0;
                this.refreshBreedSearch();
            }, 500);
        },
        sortModel() {
            this.refreshBreedSearch();
        }
    },
    methods: {
        ...mapActions('RegistrationBreedSearches', [
            'searchBreeds'
        ]),
        refreshBreedSearch() {
            if (!this.breedSearchCache || !!this.breedSearchCache.retry) {
                this.trySearchBreed();
            }
        },
        trySearchBreed() {
            const self = this;
            if (!self.breedSearchModelValid) {
                return;
            }
            self.error = '';
            self.searchBreeds({ key: this.breedSearchCacheKey, payload: this.breedSearchModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        previous() {
            this.skip = Math.max(0, this.skip - this.take);
            this.refreshBreedSearch();
        },
        next() {
            if (!this.finished) {
                this.skip += this.take;
                this.refreshBreedSearch();
            }
        },
        skipTo(skip) {
            this.skip = skip;
            this.refreshBreedSearch();
        }
    },
    created() {
        this.refreshBreedSearch();
    }
}
