<template>
  <footer class="footer">
    <div class="bg-footer text-footer-color py-3">
      <p class="mb-0">© 2024 {{selectedCompanyName}}. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
import { SelectedSiteMixin } from '@/mixins/Site/SelectedSiteMixin'

export default {
  name: 'PageFooter',
  mixins: [SelectedSiteMixin]
}
</script>
