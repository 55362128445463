import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapGetters } from 'vuex'

export const ClientCheckoutsCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  props: {
    reference: String
  },
  computed: {
    ...mapGetters('ClientCheckouts', [
      'findClientCheckoutsCache'
    ]),
    clientCheckoutsCache () {
      return this.findClientCheckoutsCache(this.reference)
    },
    clientCheckouts () {
      return this.clientCheckoutsCache ? this.clientCheckoutsCache.values : []
    },
    loadingClientCheckouts () {
      return !!this.clientCheckoutsCache && this.clientCheckoutsCache.loading
    }
  },
  watch: {
    reference () {
      this.refreshClientCheckouts()
    }
  },
  methods: {
    ...mapActions('ClientCheckouts', [
      'findClientCheckouts'
    ]),
    refreshClientCheckouts () {
      if (!this.clientCheckoutsCache || this.clientCheckoutsCache.retry) {
        this.tryFindClientCheckouts()
      }
    },
    tryFindClientCheckouts () {
      const self = this
      if (!self.reference) {
        return
      }
      self.error = ''
      self.findClientCheckouts(self.reference)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshClientCheckouts()
  }
}
