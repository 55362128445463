/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const BookingSettingsCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingSettings', [
            'fetchedBookingSettings',
            'fetchingBookingSettings',
            'attemptedBookingSettings',
            'bookingSettings',
        ]),
        enabled() {
            return this.bookingSettings ? this.bookingSettings.enabled : false;
        },
        showRibbon() {
            return this.bookingSettings ? this.bookingSettings.showRibbon : false;
        }
    },
    methods: {
        ...mapActions('BookingSettings', [
            'fetchBookingSettings'
        ]),
        refreshBookingSettings() {
            if (!this.fetchedBookingSettings && !this.fetchingBookingSettings) {
                this.tryFetchBookingSettings();
            }
        },
        tryFetchBookingSettings() {
            const self = this;
            self.error = '';
            self.fetchBookingSettings()
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingSettings();
    }
}
