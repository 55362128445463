/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
  loadingMarketingSetting: false,
  loadedMarketingSetting: false,
  marketingSettingCache: null
})

const mutations = {
  setMarketingSetting(state, marketingSetting) {
    state.marketingSettingCache = marketingSetting
    state.loadedMarketingSetting = true;
  },
  setLoading (state, value) {
      state.loadingMarketingSetting = !!value;
  }
}

const actions = {
  findMarketingSetting ({ commit }, inviteId) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        WebApi.get('ClientOnboardingInvite/' + inviteId + '/Marketing')
          .then(response => {
              commit('setMarketingSetting', response.data);
              resolve(response)
          })
          .catch(error => reject(error))
          .finally(() => commit('setLoading', false));
      })
  },
  updateMarketingSetting ({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      WebApi.put('ClientOnboardingInvite/' + inviteId + '/Marketing', payload)
        .then(response => {
            commit('setMarketingSetting', response.data);
            resolve(response)
        })
        .catch(error => reject(error))
        .finally(() => commit('setLoading', false));
    })
  },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
