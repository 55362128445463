import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapGetters } from 'vuex'

export const ScheduleResourcesCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  props: {
    scheduleId: {
      type: String,
      required: true
    },
    sequence: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('ScheduleResources', [
      'findScheduleResourcesCaches'
    ]),
    scheduleResourcesFilterRequest () {
      return {
        sequence: this.sequence
      }
    },
    scheduleResourcesCacheKey () {
      return this.scheduleId + this.sequence
    },
    scheduleResourcesCache () {
      return this.findScheduleResourcesCaches(this.scheduleResourcesCacheKey)
    },
    scheduleResources () {
      return this.scheduleResourcesCache ? this.scheduleResourcesCache.values : []
    },
    loadingScheduleResources () {
      return !!this.scheduleResourcesCache && this.scheduleResourcesCache.loading
    }
  },
  watch: {
    scheduleResourcesCacheKey () {
      this.refreshScheduleResources()
    }
  },
  methods: {
    ...mapActions('ScheduleResources', [
      'fetchScheduleResources'
    ]),
    refreshScheduleResources () {
      if (!this.scheduleResourcesCache || this.scheduleResourcesCache.retry) {
        this.tryFetchScheduleResources()
      }
    },
    tryFetchScheduleResources () {
      const self = this
      self.error = ''
      self.fetchScheduleResources({ key: self.scheduleResourcesCacheKey, scheduleId: self.scheduleId, payload: self.scheduleResourcesFilterRequest })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshScheduleResources()
  }
}
