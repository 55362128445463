/* eslint-disable */
import { WebApi } from '../../services/WebApi';

const state = () => ({
    appointmentSearches: [],
    appointmentSearchesCleared: 0
})

const getters = {
    findAppointmentSearch: (state) => (key) => {
        return state.appointmentSearches.find(ac => ac.key === key);
    }
}

const mutations = {
    addAppointmentSearch(state, appointmentCache) {
        const existing = state.appointmentSearches.find(ac => ac.key === appointmentCache.key);
        if (existing) {
            appointmentCache.value.appointments = existing.value.appointments.concat(appointmentCache.value.appointments);
        }
        const local = state.appointmentSearches.filter(ac => ac.key !== appointmentCache.key);
        local.push(appointmentCache);
        state.appointmentSearches = local;
    },
    setRetry(state, key) {
        const existing = state.appointmentSearches.find(ac => ac.key === key);
        if (existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearAppointmentSearches(state) {
        state.appointmentSearches = [];
        state.appointmentSearchesCleared++;
    }
}

const actions = {
    searchAppointmentsBySiteId({ commit }, { key, siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('addAppointmentSearch', {
                key: key,
                value: { appointments: [], more: false },
                loading: true
            });
            WebApi.post('Portal/'+ siteId + '/Appointment/Search', payload)
                .then(response => {
                    commit('addAppointmentSearch', {
                        key: key,
                        value: {
                            appointments: response.data
                        },
                        loading: false
                    });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
