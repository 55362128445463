/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    fetchedRecaptchaSettings: false,
    fetchingRecaptchaSettings: false,
    recaptchaSettings: null,
    attempted: false
})

const mutations = {
    setFetching(state, fetching) {
        state.fetchingRecaptchaSettings = !!fetching;
        state.fetchedRecaptchaSettings = false;
        state.attempted = true;
    },
    setRecaptchaSettings(state, RecaptchaSettings) {
        state.recaptchaSettings = RecaptchaSettings;
        state.fetchingRecaptchaSettings = false;
        state.fetchedRecaptchaSettings = true;
    }
}

const actions = {
    fetchRecaptchaSettings({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setFetching', true);
            WebApi.get('Recaptcha/Setting')
                .then(response => {
                    commit('setRecaptchaSettings', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setFetching', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
