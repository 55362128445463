/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    clientNotificationSetting: null,
    loadingClientNotificationSetting: false,
    retryClientNotificationSetting: false
})

const mutations = {
    setClientNotificationSetting(state, setting) {
        state.clientNotificationSetting = setting;
    },
    setLoading(state, loading) {
        state.loadingClientNotificationSetting = !!loading;
    },
    setRetry(state, retry) {
        state.retryClientNotificationSetting = !!retry;
    }
}

const actions = {
    unsubscribeMarketingSetting({ commit }, settingId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.put('ClientNotificationSetting/' + settingId +'/Unsubscribe')
                .then(response => {
                    commit('setClientNotificationSetting', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
