<template>
    <settings-checker>
        <booking-select></booking-select>
    </settings-checker>
</template>

<script>
/* eslint-disable */
    import BookingSelect from './BookingSelect.vue';
    import SettingsChecker from './SettingsChecker.vue';

    export default {
        name: 'Home',
        components: {
            'booking-select': BookingSelect,
            'settings-checker': SettingsChecker
        }
    }
</script>