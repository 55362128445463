<template>
    <div class="product-select-section">
        <template v-if="allProducts.length > 0">
            <div class="booking-divider-title text-header-color w-100">What service would you like for {{petName}}?</div>
            <div class="timeslots">
                <validation-message v-if="message" :message="message" class="mb-2"></validation-message>
                <div v-for="product in allProducts"
                    :key="product.id"
                    @click="handleProductSelect(product)"
                    class="btn-pet"
                    :class="{'btn-pet-active' : productIds.includes(product.id)}">
                    <div class="btn-pet-name">{{product.name}}</div>
                    <div class="btn-pet-breed">{{currencySymbol}} {{product.amount.toFixed(2).toString()}}</div>
                </div>
            </div>
        </template>
        <div v-else-if="noProducts" class="font-size-h2 text-header-color mt-3 mt-md-1 px-4">NO SERVICES AVAILABLE</div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState, mapMutations} from 'vuex'
    import { ServiceProductCacheMixin } from '@/mixins/Booking/ServiceProductCacheMixin';
    import { BreedProductCacheMixin } from '@/mixins/Booking/BreedProductCacheMixin';
    import { PetProductCacheMixin } from '@/mixins/Booking/PetProductCacheMixin';
    import { CountryCacheMixin } from '@/mixins/Booking/CountryCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'ProductSelect',
        mixins: [SiteBookingSettingMixin, ServiceProductCacheMixin, BreedProductCacheMixin, PetProductCacheMixin, CountryCacheMixin],
        props: {
            id: ''
        },
        data() {
            return {
                message: '',
                isValid: false
            } 
        },
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            pet() {
                return this.selectedPets.length > 0 ? this.selectedPets.find(p => p.id === this.id) : null
            },
            breedId() {
                return !!this.pet ? this.pet.breedId : '';
            },
            petId() {
                return !!this.pet ? this.pet.petId : '';
            },
            petName() {
                return !!this.pet ? this.pet.name : ''
            },
            serviceItems() {
                return !!this.pet ? this.pet.serviceItems : [];
            },
            productIds() {
                return this.serviceItems.length > 0 ? this.serviceItems.map(p => p.id) : [];
            },
            basicProduct() {
                if (this.siteBookingSettingPricingType === 5) {
                    return !!this.petProduct ? this.petProduct : null; 
                } else if (this.siteBookingSettingPricingType === 4) {
                    return !!this.petProduct ? this.petProduct : !!this.breedProduct ? this.breedProduct : null; 
                } else if (this.siteBookingSettingPricingType === 3) {
                    return !!this.breedProduct ? this.breedProduct : null;
                } else if (this.siteBookingSettingPricingType === 2) {
                    return !!this.petProduct ? this.petProduct : null;
                }
                return null;
            },
            allProducts() {
                return (!!this.basicProduct ? [this.basicProduct] : []).concat(this.serviceProducts);
            },
            noProducts() {
                return this.allProducts.length < 1 && (!this.loadingServiceProducts || !this.loadingBreedProduct || !this.loadingPetProduct)
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'addServiceItem',
                'removeServiceItem',
                'clearServiceItems'
            ]),
            handleProductSelect(product) {
                if(!!this.allowedMultipleServiceSelect) {
                    const existing = this.serviceItems.find(si => si.id === product.id);
                    if(!!existing) {
                        this.removeServiceItem({petId: this.id, serviceItem: product})
                    } else {
                        if(this.serviceItems.length < this.siteBookingSettingMaxServices) {
                            this.addServiceItem({petId: this.id, serviceItem: product})
                        }
                    }
                } else {
                    this.clearServiceItems(this.id);
                    this.addServiceItem({petId: this.id, serviceItem: product})
                }
            },
            back() {
                this.$emit('back')
            },
            next() {
                this.check();
                if(this.isValid) {
                    this.$emit('next');
                }
            },
            check() {
                this.isValid = this.serviceItems.length > 0;
                this.message = this.isValid ? '' : 'Please select a product';
            },
            refreshServiceProducts() {
                //do nothing
            },
            refreshBreedProduct() {
                //do nothing
            },
            refreshPetProduct() {
                //do nothing
            }
        },
        components: {
            'validation-message': ValidationMessage
        }
    }
</script>

<style scoped>
    .booking-divider-title {
        @media screen and (min-width: 768px)  {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .product-select-section {
        width: 100%;
        height: 100%;
        overflow: hidden;

        @media screen and (max-width: 768px)  {
            min-height: 10vh
        }
    }
    .timeslots {
        @media screen and (min-width: 768px)  {
            height: 100%;
            max-height: calc(650px - 20rem);
        }
    }
</style>