<template>
  <labeled-form-control label="Address">
    <span v-if="loadingClientAddress" class="inline-loader"></span>
    <template v-else>
        <div v-if="editing">
            <address-manual-input v-model="clientAddressLines" :client-address-updated="clientAddressUpdated"></address-manual-input>
            <a @click="editing=false" class="link" style="cursor: pointer !important">Back</a>
        </div>
        <template v-else>
            <div v-if="!!addressString">
                <div class="mb-1">{{addressString}}</div>
                <a v-if="!clientAddressUpdated" @click="editing=true" class="link" style="cursor: pointer !important">Edit</a>
            </div>
            <template v-else>
                <address-lookup v-if="canUseLookup" v-model="clientAddressLines" :client-address-updated="clientAddressUpdated"></address-lookup>
                <address-manual-input v-else-if="!canUseLookup && loadedCountryFormat" v-model="clientAddressLines" :client-address-updated="clientAddressUpdated"></address-manual-input>
            </template>
        </template>
    </template>
  </labeled-form-control>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { AddressHelper } from '@/helpers/AddressHelper'
import { FormatCountryCacheMixin } from '@/mixins/Format/FormatCountryCacheMixin'
import { ClientAddressUploadMixin } from '@/mixins/Registration/ClientAddressUploadMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'
import ClientAddressFieldManualInput from './ClientAddressFieldManualInput.vue'
import ClientAddressFieldLookup from './ClientAddressFieldLookup.vue'

export default {
  name: 'ClientAddressField',
  mixins: [FormatCountryCacheMixin, ClientAddressUploadMixin, ClientOnboardingInviteCacheMixin],
  data () {
    return {
      editing: false
    }
  },
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedClientDetailsEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    clientAddressLines: {
      get () {
        return {
          line1: this.line1,
          line2: this.line2,
          line3: this.line3,
          town: this.town,
          county: this.county,
          postCode: this.postCode
        }
      },
      set (value) {
        this.setClientAddressLines(value)
      }
    },
    addressString () {
      return AddressHelper.getAddressLines(this.clientAddressLines).join(', ')
    },
    canUseLookup () {
      return this.countryFormat && this.countryFormat.addressLookup
    },
    clientAddressUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.clientAddressUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedClientAddress']),
    setClientAddressLines (address) {
      this.line1 = address ? address.line1 : ''
      this.line2 = address ? address.line2 : ''
      this.line3 = address ? address.line3 : ''
      this.town = address ? address.town : ''
      this.county = address ? address.county : ''
      this.postCode = address ? address.postCode : ''
    }
  },
  watch: {
    async updatedClientDetailsEvent () {
      if (!this.clientAddressUpdated) {
        try {
          await this.tryUploadClientAddress()
        } finally {
          this.triggerUpdatedClientAddress()
        }
      } else {
        this.triggerUpdatedClientAddress()
      }
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl,
    'address-manual-input': ClientAddressFieldManualInput,
    'address-lookup': ClientAddressFieldLookup
  }
}
</script>
