/* eslint-disable */
import { WebApi } from "@/services/WebApi";

const state = () => ({
    loadedClient: false,
    loadingClient: false,
    clientCache: null
})

const mutations = {
    setClient(state, value) {
        state.clientCache = value;
        state.loadedClient = true;
    },
    setLoading(state, value) {
        state.loading = !!value;
    }
}

const actions = {
    fetchClient({ commit }, inviteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('ClientOnboardingInvite/' + inviteId + '/Client')
                .then(response => {
                    commit('setClient', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    },
    updateClient({ commit }, { inviteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.put('ClientOnboardingInvite/' + inviteId + '/Client', payload)
                .then(response => {
                    commit('setClient', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', false));
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
