import moment from 'moment'

export const PaymentRowMixin = {
  props: {
    checkout: {
      type: Object,
      required: true
    },
    dateFormat: String
  },
  computed: {
    canProcess () {
      return this.checkout.canProcess
    },
    provider () {
      return this.checkout.providerType.description
    },
    status () {
      return this.checkout.status.description
    },
    formattedDate () {
      return moment(this.checkout.createdUtc).format(this.dateFormat)
    },
    amount () {
      return this.checkout.amount.toFixed(2)
    }
  },
  methods: {
    signalProcess () {
      if (this.canProcess) {
        this.$emit('process', this.checkout)
      }
    }
  }
}
