/* eslint-disable */
export const SortableSearchMixin = {
    data() {
        return {
            sortKey: '',
            ascending: true
        }
    },
    computed: {
        sortModel: {
            get() {
                return {
                    sortKey: this.sortKey,
                    ascending: this.ascending
                }
            },
            set(value) {
                this.sortKey = value.sortKey;
                this.ascending = value.ascending;
            }
        }
    }
}
