import { mapActions, mapGetters } from 'vuex'
import { SelectedSiteMixin } from '../Site/SelectedSiteMixin'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const FormatSettingTimeCacheMixin = {
  mixins: [SelectedSiteMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters('FormatSettingTime', [
      'findTimeFormatSettingCache'
    ]),
    timeFormatSettingCache () {
      return this.findTimeFormatSettingCache(this.selectedSiteId)
    },
    timeFormatSetting () {
      return this.timeFormatSettingCache ? this.timeFormatSettingCache.value : null
    },
    loadingTimeFormatSetting () {
      return !!this.timeFormatSettingCache && this.timeFormatSettingCache.loading
    }
  },
  watch: {
    selectedSiteId () {
      this.refreshTimeFormatSetting()
    }
  },
  methods: {
    ...mapActions('FormatSettingTime', [
      'fetchTimeFormatSetting'
    ]),
    refreshTimeFormatSetting () {
      if (!this.timeFormatSettingCache || this.timeFormatSettingCache.retry) {
        this.tryFetchTimeFormatSetting()
      }
    },
    tryFetchTimeFormatSetting () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchTimeFormatSetting(self.selectedSiteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshTimeFormatSetting()
  }
}
