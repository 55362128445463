<template>
    <div @click="onClick" role="option" class="dropdown-item" style="font-size: 0.75rem; cursor: pointer">
        {{ addressString }}
    </div>
</template>

<script>
import { AddressHelper } from '@/helpers/AddressHelper'

export default {
  name: 'ClientAddressFieldLookupResult',
  props: {
    address: Object
  },
  computed: {
    addressString () {
      return AddressHelper.getAddressLines(this.address).join(', ')
    }
  },
  methods: {
    onClick () {
      this.$emit('addressClicked', this.address)
    }
  }
}
</script>
