/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const SubscriptionCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Subscription', [
            'fetchedSubscription',
            'fetchingSubscription',
            'attempted',
            'subscription'
        ]),
        featureKey() {
            console.log('OVERRIDE COMPUTED - featureKey');
        },
        subscriptionFeatures() {
            return !!this.subscription ? this.subscription.feature : null;
        },
        subscriptionFeatureFound() {
            return !!this.subscriptionFeatures && !!this.subscriptionFeatures.hasOwnProperty(this.featureKey);
        },
        subscriptionFeatureAllowed() {
            return this.subscriptionFeatureFound ? this.subscriptionFeatures[this.featureKey] : false;
        },
        attemptedSubscription() {
            return !!this.attempted
        }
    },
    watch: {
        selectedSiteId() {
            this.refreshSubscription();
        }
    },
    methods: {
        ...mapActions('Subscription', [
            'fetchSubscriptionBySiteId'
        ]),
        refreshSubscription() {
            if (!this.fetchedSubscription && !this.fetchingSubscription) {
                this.tryFetchSubscriptionBySiteId();
            }
        },
        tryFetchSubscriptionBySiteId() {
            const self = this;
            if(!self.selectedSiteId) {
                return;
            }
            self.error = '';
            self.fetchSubscriptionBySiteId(self.selectedSiteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSubscription();
    }
}
