/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    petDurations: [],
    loadingPetDurations: false,
    retryPetDurations: false
})

const mutations = {
    setPetDurations(state, petDurations) {
        state.petDurations = petDurations;
    },
    setLoading(state, loading) {
        state.loadingPetDurations = !!loading;
    },
    setRetry(state, retry) {
        state.retryPetDurations = !!retry;
    }
}

const actions = {
    getPetDurationsBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/'+ siteId +'/PetDuration')
                .then(response => {
                    commit('setPetDurations', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}