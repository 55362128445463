<template>
    <div class="text-center my-auto">
        <h2 v-if="error">{{ error }}</h2>
        <template v-else>
            <h2>Your document has been signed and is available to download.</h2>
            <br />
            <a v-if="document" :href="document.href" target="_blank" type="button" class="btn btn-primary btn-lg">
                <i class="fas fa-cloud-download-alt"></i>
                Download Document
            </a>
            <template v-else>
                <page-loader v-if="loading"></page-loader>
            </template>
        </template>
    </div>
</template>

<script>
import { WebApi } from '@/services/WebApi'
import PageLoader from '../Loading/PageLoader.vue'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export default {
  name: 'DownloadLink',
  data () {
    return {
      error: '',
      loading: false,
      document: null
    }
  },
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  computed: {
    contractId () {
      return this.contract.id
    }
  },
  methods: {
    tryFetchDocument () {
      const self = this
      self.error = ''
      self.loading = true
      WebApi.get('ClientContract/' + self.contractId + '/Document')
        .then(response => { self.document = response.data })
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    }
  },
  components: {
    'page-loader': PageLoader
  },
  created () {
    this.tryFetchDocument()
  }
}
</script>
