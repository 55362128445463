/* eslint-disable */
import { ErrorHelper } from "@/helpers/ErrorHelper"
import { WebApi } from "@/services/WebApi"

const state = () => ({
    breedProductCaches: []
})

const getters = {
    findBreedProductCache: (state) => key => state.breedProductCaches.find(bpc => bpc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.breedProductCaches.push({ key: key, value: null, loading: !!loading });
        }
    },
    setBreedProduct(state, product) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === product.siteBreedId);
        if (!!existing) {
            existing.value = product;
            existing.loading = false;
        } else {
            state.breedProductCaches.push({ key: product.siteBreedId, value: product, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.breedProductCaches.find(bpc => bpc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findBreedProductBySiteId({ commit }, { siteId, siteBreedId }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: siteBreedId, loading: true });
            WebApi.get('Portal/'+ siteId +'/Breed/' + siteBreedId + '/BreedProduct')
                .then(response => {
                    commit('setBreedProduct', response.data);
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        resolve(false);
                    } else {
                        commit('setRetry', siteBreedId);
                        reject(error);
                    }
                })
                .finally(() => commit('setLoading', { key: siteBreedId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
