import { mapActions, mapGetters, mapState } from 'vuex'
import { SelectedSiteMixin } from './SelectedSiteMixin'
import { ErrorHelper } from '@/helpers/ErrorHelper'

export const CountryCacheMixin = {
  mixins: [SelectedSiteMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapState('Countries', [
      'fetchedCountries',
      'fetchingCountries'
    ]),
    ...mapGetters('Countries', [
      'findCountry'
    ]),
    country () {
      return this.findCountry(this.selectedSiteId)
    },
    countryName () {
      return this.country ? this.country.name : ''
    },
    currency () {
      return this.country ? this.country.currency : ''
    },
    currencySymbol () {
      return this.country ? this.country.currencySymbol : ''
    }
  },
  watch: {
    selectedSiteId () {
      this.refreshCountries()
    }
  },
  methods: {
    ...mapActions('Countries', [
      'fetchCountries'
    ]),
    refreshCountries () {
      if (!this.fetchedCountries && !this.fetchingCountries) {
        this.tryFetchCountries()
      }
    },
    tryFetchCountries () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchCountries()
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshCountries()
  }
}
