/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const LogoCacheMixin = {
  mixins: [SelectedSiteIdMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters('Logos', [
      'findLogoCache'
    ]),
    logoCache () {
      return this.findLogoCache(this.selectedSiteId)
    },
    logo () {
      return this.logoCache ? this.logoCache.value : null
    },
    logoUrl () {
      return this.logo ? this.logo.url : ''
    },
    loadingLogo () {
      return !!this.logoCache && this.logoCache.loading
    }
  },
  watch: {
    selectedSiteId () {
      this.refreshLogo()
    }
  },
  methods: {
    ...mapActions('Logos', [
      'fetchLogoBySiteId'
    ]),
    refreshLogo () {
      if (!this.logoCache || this.logoCache.retry) {
        this.tryFetchLogoBySiteId()
      }
    },
    tryFetchLogoBySiteId () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchLogoBySiteId(self.selectedSiteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshLogo()
  }
}
