<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState('Sites', [
      'selected'
    ]),
    selectedSiteName () {
      return this.selected ? this.selected.name : ''
    }
  },
  watch: {
    selected () {
      this.setTabTitle()
    }
  },
  methods: {
    setTabTitle () {
      document.title = this.selectedSiteName
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--default-color);
}
</style>
