/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    pets: [],
    loadingPets: false,
    retryPets: false,
    attempted: false
})

const getters = {
    findPet: (state) => key => state.pets.find(p => p.key === key)
}

const mutations = {
    setPets(state, pets) {
        state.pets = pets;
    },
    setLoading(state, loading) {
        state.loadingPets = !!loading;
        state.attempted = true;
    },
    setRetry(state, retry) {
        state.retryPets = !!retry;
    }
}

const actions = {
    getPetsBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/'+ siteId +'/Pet')
                .then(response => {
                    commit('setPets', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}