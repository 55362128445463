import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapGetters } from 'vuex'

export const SchedulePetsCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  props: {
    scheduleId: {
      type: String,
      required: true
    },
    sequence: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('SchedulePets', [
      'findSchedulePetsCaches'
    ]),
    schedulePetsFilterRequest () {
      return {
        sequence: this.sequence
      }
    },
    schedulePetsCacheKey () {
      return this.scheduleId + this.sequence
    },
    schedulePetsCache () {
      return this.findSchedulePetsCaches(this.schedulePetsCacheKey)
    },
    schedulePets () {
      return this.schedulePetsCache ? this.schedulePetsCache.values : []
    },
    loadingSchedulePets () {
      return !!this.schedulePetsCache && this.schedulePetsCache.loading
    }
  },
  watch: {
    schedulePetsCacheKey () {
      this.refreshSchedulePets()
    }
  },
  methods: {
    ...mapActions('SchedulePets', [
      'fetchSchedulePets'
    ]),
    refreshSchedulePets () {
      if (!this.schedulePetsCache || this.schedulePetsCache.retry) {
        this.tryFetchSchedulePets()
      }
    },
    tryFetchSchedulePets () {
      const self = this
      self.error = ''
      self.fetchSchedulePets({ key: self.schedulePetsCacheKey, scheduleId: self.scheduleId, payload: self.schedulePetsFilterRequest })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshSchedulePets()
  }
}
