/* eslint-disable */
import { mapState, mapActions } from 'vuex';
import { ErrorHelper } from '@/helpers/ErrorHelper';

export const NotificationSendMixin = {
    computed: {
        ...mapState('RegistrationNotifications', [
            'sendingNotification'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        }
    },
    watch: {
        notificationsAddedEvent() {
            this.text = '';
            this.success = true;
        }
    },
    methods: {
        ...mapActions('RegistrationNotifications', [
            'sendNotification'
        ]),
        trySendNotification() {
            const self = this;
            self.sendNotification(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
