<template>
    <div class="w-100">
        <display-banner></display-banner>
        <div class="booking-header">
            <div v-if="loading" class="d-flex w-100 justify-content-center align-items-center">
            <span class="inline-loader d-md-block d-none h-100"></span>
            <span class="inline-loader d-md-none d-block"></span>
            </div>
            <template v-else>
                <div class="booking-header-logo">
                    <template v-if="!!logo">
                        <img v-if="isLogoProperlyLoaded" :src="logoUrl" @error="handleImageError"/>
                        <template v-else>
                            <i class="fa fas fa-paw"></i>
                        </template>
                    </template>
                    <template v-else>
                        <i class="fa fas fa-paw"></i>
                    </template>
                </div>
                <div class="text-left">
                    <div class="font-size-h3 text-header-color font-weight-bold">{{selectedCompanyName}}</div>
                    <div class="text-default">{{selectedContactNumber}}</div>
                    <div class="text-default">{{addressString}}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex';
    import { LogoCacheMixin } from '@/mixins/Booking/LogoCacheMixin'
    import { AddressCacheMixin } from '@/mixins/Booking/AddressCacheMixin'
    import { AddressHelper } from '@/helpers/AddressHelper'
    import { SelectedSiteIdMixin } from '@/mixins/Booking/SelectedSiteIdMixin';
    import { SiteBrandingSettingMixin } from '@/mixins/Booking/SiteBrandingSettingMixin';
    import DisplayBanner from './DisplayBanner.vue';

    export default {
        name: 'Header',
        mixins: [SelectedSiteIdMixin, AddressCacheMixin, LogoCacheMixin, SiteBrandingSettingMixin],
        data () {
            return {
                isLogoProperlyLoaded: true
            }
        },
        computed: {
            ...mapState('Sites', [
                'fetchingSites',
                'sites'
            ]),
            selected() {
                return this.sites.length > 0 ? this.sites[0] : [];
            },
            selectedSiteName () {
                return this.selected ? this.selected.name : ''
            },
            selectedCompanyName () {
                return this.selected ? this.selected.companyName : ''
            },
            selectedContactNumber() {
                return this.selected ? this.selected.contactNumber : ''
            },
            selectedSiteId() {
                return this.$route.query.siteId ? this.$route.query.siteId : this.selected ? this.selected.id : '';
            },
            ownerId() {
                return this.selectedSiteId;
            },
            ownerTypeId() {
                return 12;
            },
            ownerQueryModel() {
                return {
                    ownerId: this.ownerId,
                    ownerTypeId: this.ownerTypeId,
                }
            },
            addressString() {
                return AddressHelper.getAddressLines(this.address).join(', ');
            },
            loading() {
                return !!this.fetchingSites || !!this.loadingAddress || !!this.loadingLogo;
            }
        },
        methods: {
            handleImageError () {
                if (!!this.logo) {
                    this.isLogoProperlyLoaded = false
                }
            }
        },
        components: {
            'display-banner': DisplayBanner
        }
    }
</script>
