<template>
    <div class="booking">
        <div class="booking-wrapper">
            <petstar-ribbon></petstar-ribbon>
            <div class="booking-divider booking-divider-left">
                <div class="booking-content">
                    <booking-header></booking-header>
                    <opening-hours></opening-hours>
                </div>
            </div>
            <div class="booking-divider booking-divider-right">
                <div class="booking-content">
                    <display-banner></display-banner>
                    <div class="font-size-h2 text-header-color px-4 my-5">PORTAL IS CURRENTLY NOT AVAILABLE.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import PetstarRibbon from './PetstarRibbon.vue'
    import Header from './Header.vue';
    import OpeningHours from './OpeningHours.vue';
    import DisplayBanner from './DisplayBanner.vue';

    export default {
        name: 'FeatureDisabled',
        components: {
            'petstar-ribbon': PetstarRibbon,
            'booking-header': Header,
            'opening-hours': OpeningHours,
            'display-banner': DisplayBanner
        }
    }
</script>