<template>
  <div class="row form-group">
    <div class="col-md-4 text-left">
      <label>{{label}}</label>
    </div>
    <div class="col-md-8">
        <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabeledFormControl',
  props: {
    label: String
  }
}
</script>
