<template>
    <div class="booking">
        <div class="booking-wrapper no-site-page">
            <petstar-ribbon></petstar-ribbon>
            <div class="d-flex justify-content-center align-items-center w-100">
                <div class="font-size-h2 text-header-color">SITE DOES NOT EXISTS</div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import PetstarRibbon from './PetstarRibbon.vue'

    export default {
        name: 'NoSiteFound',
        components: {
            'petstar-ribbon': PetstarRibbon
        }
    }
</script>

<style scoped>
    @media (max-width: 768px) {
        .no-site-page {
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>