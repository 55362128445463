/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    loadedClient: false,
    loadingClient: false,
    client: null,
    attempted: false
})

const mutations = {
    setLoading(state, fetching) {
        state.loadingClient = !!fetching;
        state.loadedClient = false;
        state.attempted = true;
    },
    setClient(state, client) {
        state.client = client;
        state.loadingClient = false;
        state.loadedClient = true;
    },

}

const actions = {
    fetchClientBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/'+ siteId +'/Client')
                .then(response => {
                    commit('setClient', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
