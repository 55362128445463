/* eslint-disable */
import { mapState, mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const BreedCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('Breeds', [
            'findBreedCache'
        ]),
        breedId() {
            console.log('!!!OVERRIDE breedId() computed method!!!');
        },
        breedCache() {
            return this.findBreedCache(this.breedId);
        },
        breed() {
            return this.breedCache ? this.breedCache.value : null;
        },
        breedName() {
            return !!this.breed ? this.breed.name : 'Unknown';
        },
        loadingBreed() {
            return this.breedCache && this.breedCache.loading;
        }
    },
    watch: {
        breedId() {
            this.refreshBreed();
        },
        selectedSiteId() {
            this.refreshBreed();
        }
    },
    methods: {
        ...mapActions('Breeds', [
            'fetchBreedBySiteId'
        ]),
        refreshBreed() {
            if (!this.breedCache || !!this.breedCache.retry) {
                this.tryFetchBreedBySiteId();
            }
        },
        tryFetchBreedBySiteId() {
            const self = this;
            if (!self.breedId || !self.selectedSiteId) {
                return;
            }
            self.error = '';
            self.fetchBreedBySiteId({siteId: self.selectedSiteId, id: self.breedId})
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBreed();
    }
}
