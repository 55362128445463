/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const ResourceCacheMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('Resources', [
            'resources',
            'loadingResources',
            'retryResources',
            'attempted'
        ]),
        unhiddenResources() {
            return !!this.resources ? this.resources.filter(r => !r.hidden) : [];
        },
        attemptedResources() {
            return !!this.attempted;
        }
    },
    watch: {
        selectedSiteId() {
            this.refreshResources()
        }
    },
    methods: {
        ...mapActions('Resources', [
            'getResourcesBySiteId'
        ]),
        refreshResources() {
            if (this.resources.length == 0 || !!this.retryResources) {
                this.tryGetResourcesBySiteId();
            }
        },
        tryGetResourcesBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getResourcesBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshResources();
    }
}
