/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    addressCaches: []
})

const getters = {
    findAddressCache: (state) => (key) => {
        return state.addressCaches.find(ac => ac.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.addressCaches.push({ key: key, values: [], loading: !!loading })
        }
    },
    setAddresses(state, { key, addresses }) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.values = addresses;
            existing.loading = false;
        } else {
            state.addressCaches.push({ key: key, values: addresses, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.addressCaches.find(ac => ac.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchAddressesBySiteId({ commit }, { key, siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            WebApi.get('Portal/'+ siteId + '/Address?' + new URLSearchParams(payload))
                .then(response => {
                    commit('setAddresses', { key: key, addresses: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
