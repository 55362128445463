<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <display-banner></display-banner>
            <template v-if="loading">
                <span class="inline-loader d-md-block d-none h-100"></span>
                <span class="inline-loader d-md-none d-block"></span>
            </template>
            <products-select-item v-else :id="currentPetId"
                ref="productsSelectItemRef"
                @back="handleBack"
                @next="handleNext">
            </products-select-item>
        </div>
        <div class="fixed-buttons">
            <button class="btn btn-tertiary w-100 mr-2" @click="triggerBack">Back</button>
            <button class="btn btn-secondary w-100 ml-2" @click="triggerNext">Next</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex';
    import { ServiceProductCacheMixin } from '@/mixins/Booking/ServiceProductCacheMixin';
    import { BreedProductCacheMixin } from '@/mixins/Booking/BreedProductCacheMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import DisplayBanner from '../DisplayBanner.vue';
    import ProductsSelectItem from './ProductsSelectItem.vue';

    export default {
        name: 'ProductsSelect',
        mixins: [ServiceProductCacheMixin, BreedProductCacheMixin, SiteBookingSettingMixin],
        props: {
            lastPet: false
        },
        data() {
            return {
                tabs: 0,
                currentTab: 0
            }
        },
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            currentPet() {
                return this.selectedPets[this.currentTab];
            },
            currentPetId() {
                return !!this.currentPet ? this.currentPet.id : ''
            },
            breedId() {
                return this.siteBookingSettingPricingType === 4 || this.siteBookingSettingPricingType === 3 ? 
                    !!this.currentPet ? this.currentPet.breedId : '' : '';
            },
            loading() {
                return !!this.loadingServiceProducts || !!this.loadingBreedProduct || !!this.loadingPetProduct;
            }
        },
        methods: {
            triggerBack() {
                this.$refs.productsSelectItemRef.back();
            },
            triggerNext() {
                this.$refs.productsSelectItemRef.next();
            },
            handleNext() {
                if(this.currentTab < this.tabs) {
                    this.currentTab++;
                } else {
                    this.$emit('next');
                }
            },
            handleBack() {
                if(this.currentTab > 0) {
                    this.currentTab--;
                } else {
                    this.$emit('back');
                }
            }
        },
        created() {
            this.tabs = this.selectedPets.length > 0 ? this.selectedPets.length - 1 : 0;
            this.currentTab = !!this.lastPet ? this.selectedPets.length - 1 : 0;
        },
        components: {
            'display-banner': DisplayBanner,
            'products-select-item': ProductsSelectItem
        }
    }
</script>

<style scoped>
  @media (max-width: 768px) {
    .booking-divider-right {
        height: max-content;
        min-height: 38.5vh !important;
    }
    .booking-content {
      height: max-content;
      min-height: 28.5vh !important;
    }
  }
</style>
