/* eslint-disable */
import { mapActions } from 'vuex';
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { ClientNoteAddMixin } from "./ClientNoteAddMixin";

export const ClientNoteUploadMixin = {
    mixins: [ClientNoteAddMixin],
    computed: {
        clientNoteUpdateModel() {
            return !!this.clientNoteCache ? Object.assign({}, this.clientNoteCache, {
                text: this.noteText
            }) : {};
        }
    },
    watch: {
        clientNoteCache() {
            this.refreshClientNoteUpdateModel();
        }
    },
    methods: {
        ...mapActions('RegistrationClientNotes', [
            'updateClientNote'
        ]),
        refreshClientNoteUpdateModel() {
            this.noteText = !!this.clientNoteCache ? this.clientNoteCache.text : '';
        },
        tryUploadClientNote() {
            if (!!this.clientNoteCache) {
                return this.tryUpdateClientNote();
            } else {
                return this.tryAddClientNote();
            }
        },
        tryUpdateClientNote() {
            const self = this;
            self.error = '';
            return self.updateClientNote({ inviteId: self.inviteId, payload: self.clientNoteUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientNoteUpdateModel();
    }
}
