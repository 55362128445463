/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
  loadingClientNotes: false,
  loadedClientNotes: false,
  clientNoteCaches: []
})

const mutations = {
  setClientNotes(state, notes) {
    notes.forEach((note) => {
      const existingNoteIndex = state.clientNoteCaches.findIndex(
        (existingNote) =>
          existingNote.ownerType.id === note.ownerType.id &&
          existingNote.noteType.id === note.noteType.id
      );
      if (existingNoteIndex !== -1) {
        // If the note with matching ownerType.id and noteType.id exists, update it
        state.clientNoteCaches[existingNoteIndex] = { ...note };
      } else {
        // Otherwise, add the new note to the array
        state.clientNoteCaches.push({ ...note });
      }
    });
    state.loadedClientNotes = true;
  },
  setLoading (state, value) {
      state.loadingClientNotes = !!value;
  }
}

const actions = {
  findClientNotes ({ commit }, inviteId ) {
      return new Promise((resolve, reject) => {
          commit('setLoading', true)
          WebApi.get('ClientOnboardingInvite/' + inviteId + '/Client/Note' )
              .then(response => {
                  commit('setClientNotes', response.data);
                  resolve(response)
              })
              .catch(error => reject(error))
              .finally(() => commit('setLoading', false));
      })
  },
  addClientNote({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        WebApi.post('ClientOnboardingInvite/' + inviteId + '/Client/Note', payload)
            .then(response => {
                commit('setClientNotes', [response.data]);
                resolve(response);
            })
            .catch(error => reject(error))
            .finally(() => commit('setLoading', false));
    })
  },
  updateClientNote({ commit }, { inviteId, payload }) {
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        WebApi.put('ClientOnboardingInvite/' + inviteId + '/Client/Note', payload)
            .then(response => {
                commit('setClientNotes', [response.data]);
                resolve(response);
            })
            .catch(error => reject(error))
            .finally(() => commit('setLoading', false));
    });
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}