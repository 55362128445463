<template>
  <div class="registration-marketing">
    <div class="d-flex flex-column align-items-center">
      <error-banner v-if="error" :message="error" class="col-12"></error-banner>
      <div class="row mb-3">
        Do you want to hear about our updates and offers?
      </div>
      <div class="row">
        <button
          class="btn mr-2"
          v-bind:class="{ 'btn-tertiary': marketing }"
          @click="setMarketingSetting(true)"
          v-bind:disabled="loadingMarketingSetting || acceptMarketingUpdated">
          Yes
        </button>
        <button
          class="btn ml-2"
          v-bind:class="{ 'btn-tertiary': !marketing }"
          @click="setMarketingSetting(false)"
          v-bind:disabled="loadingMarketingSetting || acceptMarketingUpdated">
          No
        </button>
      </div>
    </div>
    <validation-message v-if="marketingSettingValidation" :message="marketingSettingValidation" class="pt-2"></validation-message>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { MarketingSettingUpdateMixin } from '@/mixins/Registration/MarketingSettingUpdateMixin'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'ClientAcceptMarketingSection',
  mixins: [MarketingSettingUpdateMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedVetContactEvent']),
    ...mapGetters('ClientOnboardingInvites', ['findClientOnboardingInviteCache']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    clientOnboardingInviteCache () {
      return this.findClientOnboardingInviteCache(this.inviteId)
    },
    acceptMarketingUpdated () {
      return this.clientOnboardingInviteCache ? this.clientOnboardingInviteCache.value.acceptMarketingUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedAcceptMarketing']),
    setMarketingSetting (value) {
      this.marketing = !!value
    }
  },
  watch: {
    async updatedVetContactEvent () {
      if (!this.acceptMarketingUpdated) {
        try {
          await this.tryUpdateMarketingSetting()
        } finally {
          this.triggerUpdatedAcceptMarketing()
        }
      } else {
        this.triggerUpdatedAcceptMarketing()
      }
    }
  },
  components: {
    'validation-message': ValidationMessage,
    'error-banner': ErrorBanner
  }
}
</script>

<style scoped>
.registration-marketing {
  border-block: 1px solid #ccc;
  padding-block: 1rem;
  margin-bottom: 1rem;
}
</style>
