/* eslint-disable */
import { mapActions } from 'vuex';
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { PetNoteAddMixin } from "./PetNoteAddMixin";

export const PetNoteUploadMixin = {
    mixins: [PetNoteAddMixin],
    computed: {
        petNoteUpdateModel() {
            return !!this.petNoteCache ? Object.assign({}, this.petNoteCache, {
                text: this.noteText
            }) : {};
        }
    },
    watch: {
        petNoteCache() {
            this.refreshPetNoteUpdateModel();
        }
    },
    methods: {
        ...mapActions('RegistrationPetNotes', [
            'updatePetNote'
        ]),
        refreshPetNoteUpdateModel() {
            this.noteText = !!this.petNoteCache ? this.petNoteCache.text : '';
        },
        tryUploadPetNote() {
            if (!!this.petNoteCache) {
                return this.tryUpdatePetNote();
            } else {
                return this.tryAddPetNote();
            }
        },
        tryUpdatePetNote() {
            const self = this;
            self.error = '';
            return self.updatePetNote({ inviteId: self.inviteId, payload: self.petNoteUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshPetNoteUpdateModel();
    }
}
