import { mapActions, mapGetters } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const FormatSettingDateCacheMixin = {
  mixins: [SelectedSiteIdMixin],
  data () {
    return {
      error: ''
    }
  },
  computed: {
    ...mapGetters('FormatSettingDate', [
      'findDateFormatSettingCache'
    ]),
    dateFormatSettingCache () {
      return this.findDateFormatSettingCache(this.selectedSiteId)
    },
    dateFormatSetting () {
      return this.dateFormatSettingCache ? this.dateFormatSettingCache.value : null
    },
    loadingDateFormatSetting () {
      return !!this.dateFormatSettingCache && this.dateFormatSettingCache.loading
    }
  },
  watch: {
    selectedSiteId () {
      this.refreshDateFormatSetting()
    }
  },
  methods: {
    ...mapActions('FormatSettingDate', [
      'fetchDateFormatSettingBySiteId'
    ]),
    refreshDateFormatSetting () {
      if (!this.dateFormatSettingCache || this.dateFormatSettingCache.retry) {
        this.tryFetchDateFormatSettingBySiteId()
      }
    },
    tryFetchDateFormatSettingBySiteId () {
      const self = this
      if (!self.selectedSiteId) {
        return
      }
      self.error = ''
      self.fetchDateFormatSettingBySiteId(self.selectedSiteId)
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshDateFormatSetting()
  }
}
