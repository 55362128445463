/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const MarketingSettingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationMarketingSetting', [
            'marketingSettingCache',
            'loadingMarketingSetting',
            'loadedMarketingSetting',
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
    },
    watch: {
        inviteId() {
            this.refreshMarketingSetting();
        }
    },
    methods: {
        ...mapActions('RegistrationMarketingSetting', [
            'findMarketingSetting'
        ]),
        refreshMarketingSetting() {
            if (!this.loadedMarketingSetting && this.inviteId) {
                this.tryFindMarketingSetting();
            }
        },
        tryFindMarketingSetting() {
            const self = this;
            self.error = '';
            self.findMarketingSetting(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshMarketingSetting();
    }
}
