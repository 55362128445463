/* eslint-disable */
import { AppSettingCacheMixin } from "./AppSettingCacheMixin"

export const SiteBookingPortalImageSettingMixin = {
    mixins: [AppSettingCacheMixin],
    computed: {
        settingKey() {
            return 'BookingPortalImage';
        },
        defaultSettingObject() {
            return {
                imageId: ''
            }
        }
    }
}
