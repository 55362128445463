<template>
  <div>
    <page-navbar></page-navbar>
    <div class="main-page container justify-content-start">
      <div class="col-md-8 mx-auto">
        <error-banner v-if="error" :message="error"></error-banner>
      </div>
      <template v-if="showWelcomeBanner">
        <div class="my-auto text-center">
          <img class="col-md-8" src="@/assets/Images/welcome-to-our-pack.png" alt="Welcome">
        </div>
      </template>
      <registration-form v-else-if="showRegistrationForm"></registration-form>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { ClientOnboardingInviteViewMixin } from '@/mixins/Registration/ClientOnboardingInviteViewMixin'
import { NotificationSendMixin } from '@/mixins/Registration/NotificationSendMixin'
import RegistrationForm from './RegistrationForm.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'
import PageNavBar from '../Layout/PageNavBar.vue'
import PageFooter from '../Layout/PageFooter.vue'

export default {
  name: 'RegistrationViewer',
  mixins: [ClientOnboardingInviteViewMixin, NotificationSendMixin],
  computed: {
    inviteId () {
      return this.$route.query.id
    },
    showRegistrationForm () {
      return !this.isClientOnboardingInviteCompleted && this.clientOnboardingInvite
    },
    showWelcomeBanner () {
      return this.isClientOnboardingInviteCompleted && this.clientOnboardingInvite
    }
  },
  watch: {
    isClientOnboardingInviteCompleted (value) {
      if (value) {
        this.trySendNotification()
      }
    }
  },
  components: {
    'registration-form': RegistrationForm,
    'error-banner': ErrorBanner,
    'page-navbar': PageNavBar,
    'page-footer': PageFooter
  }
}
</script>
