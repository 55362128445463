/* eslint-disable */
import process from 'process'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapState, mapGetters, mapActions } from 'vuex';
import { RecaptchaSettingsCacheMixin } from './RecaptchaSettingsCacheMixin'
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'

export const BookingAnonymousAddMixin = {
    mixins: [SelectedSiteIdMixin, RecaptchaSettingsCacheMixin],
    data() {
        return {
            error: '',
            recaptcha: '',
            renderRecaptcha: false,
            renderingRecaptcha: false,
            acceptedTerms: false,
            isValid: false,
            termsMessage: '',
            recaptchaMessage: ''
        }
    },
    computed: {
        ...mapGetters('BookingUpload', [
            'anonymousBookingModel',
        ]),
        ...mapState('TimeZone', [
            'selectedTimeZoneId'
        ]),
        ...mapState('Booking', [
            'addingBooking'
        ]),
        participants() {
            console.log('!!!OVERRIDE COMPUTED --- participants!!!')
        },
        bookingAddModel() {
            console.log('!!!OVERRIDE COMPUTED --- bookingAddModel!!!')
        },
        bookingAddModelValid() {
            const model = this.bookingAddModel;
            return this.recaptchaValid
                && !!model.firstName
                && !!model.lastName
                && !!model.email
                && !!model.startUtc
                && !!model.duration
                && !!model.timeZoneId
                && model.participants.length > 0
        },
        recaptchaEnabled() {
            return !!this.recaptchaSettings ? this.recaptchaSettings.recaptchaEnabled : false;
        },
        recaptchaValid() {
            return !this.recaptchaEnabled || !!this.bookingAddModel.recaptcha;
        }
    },
    methods: {
        ...mapActions('Booking', [
            'addBookingAnonymous'
        ]),
        tryAddBooking() {
            const self = this;
            self.error = '';
            if(!self.bookingAddModelValid || !self.selectedSiteId) {
                return;
            }
            self.addBookingAnonymous({siteId: this.selectedSiteId, payload: self.bookingAddModel })
                .catch(error => {
                    self.error = ErrorHelper.getError(error)
                    self.resetRecaptcha();
                    process.nextTick(() => {
                        self.renderRecaptcha = true;
                        self.renderingRecaptcha = true;
                    })
                });
        },
        onRecaptchaSuccess(response) {
            this.recaptcha = response;
        },
        onRecaptchaFailed() {
            this.recaptcha = '';
        },
        onRecaptchaRendered() {
            this.renderingRecaptcha = false;
        },
        resetRecaptcha() {
            this.onRecaptchaFailed();
            this.renderRecaptcha = false;
            this.renderingRecaptcha = false;
        }
    }
}
