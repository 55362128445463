<template>
    <div class="petstar-ribbon" v-if="showRibbon">
        <div class="petstar-ribbon-content">
            <div>POWERED BY</div>
            <div>Petstar</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { BookingSettingsCacheMixin } from '@/mixins/Booking/BookingSettingsCacheMixin'
    
    export default {
        name: 'PetstarRibbon',
        mixins: [BookingSettingsCacheMixin]
    }
</script>