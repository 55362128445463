<template>
    <div>
      <error-banner v-if="error" :message="error"></error-banner>
      <pet-details v-for="(pet, index) in petCaches" :key="pet.id" :pet-index="index" :invite="invite">hi</pet-details>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { PetCacheMixin } from '@/mixins/Registration/PetCacheMixin'
import PetDetailsSection from './PetDetailsSection.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'PetsList',
  mixins: [PetCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedPetDetailEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedPetNote']),
    ...mapMutations('ApplicationEvents', ['triggerResetUpdatedPetDetail'])
  },
  watch: {
    updatedPetDetailEvent (value) {
      if (value > this.petCaches.length) {
        this.triggerUpdatedPetNote()
      }
    }
  },
  components: {
    'pet-details': PetDetailsSection,
    'error-banner': ErrorBanner
  }
}
</script>
