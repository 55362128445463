/* eslint-disable */
<template>
    <div></div>
</template>

<script>
/* eslint-disable */
    import moment from 'moment'
    import { StatusEmitterMixin } from '@/mixins/Booking/StatusEmitterMixin';

    export default {
        name: 'ResourceWorkingHoursConflict',
        mixins: [StatusEmitterMixin],
        props: {
            timeSlot: Object,
            value: Object,
            hours: Object
        },
        computed: {
            startTime() {
                return moment(this.timeSlot.startTime).format('HH:mm:ss')
            },
            startLocal() {
                return this.hours.startLocal.split('T')[1];
            },
            endLocal() {
                return this.hours.endLocal.split('T')[1];
            },
            withinWorkingHours() {
                return this.startTime >= this.startLocal && this.startTime <= this.endLocal;
            },
            loading() {
                return false;
            },
            available() {
                return this.hours.dayOff ? false : this.withinWorkingHours;
            }
        }
    }
</script>
