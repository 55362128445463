<template>
    <settings-checker>
        <div class="booking">
            <div class="booking-wrapper">
                <petstar-ribbon></petstar-ribbon>
                <div class="booking-divider booking-divider-left">
                    <div class="booking-content">
                        <booking-header></booking-header>
                        <div class="w-100 d-block d-md-none px-4 mb-3">
                            <button class="btn btn-outline-secondary w-100"
                                @click="tryLogout">
                                <i class="fas fa-sign-out-alt"></i>
                                Logout
                            </button>
                        </div>
                        <opening-hours v-if="tab === 0 || tab === 3"></opening-hours>
                        <template v-else>
                            <div class="section-header d-flex">
                                Booking Summary
                            </div>
                            <booking-summary></booking-summary>
                        </template>
                    </div>
                    <div class="fixed-buttons d-none d-md-block">
                        <button class="btn btn-outline-secondary w-100"
                            @click="tryLogout">
                            <i class="fas fa-sign-out-alt"></i>
                            Logout
                        </button>
                    </div>
                </div>
                <idle @idle="handleIdle"></idle>
                <client-set></client-set>
                <duration-set></duration-set>
                <pet-select v-if="tab === 0" @next="handleNext" @toLastPet="handleToLastPet"></pet-select>
                <products-select v-if="tab === 1" @next="handleNext" @back="handleBack" :last-pet="lastPet"></products-select>
                <date-time-select v-if="tab === 2" @next="handleNext" @back="handleBack" @toLastPet="handleToLastPet"></date-time-select>
                <booking-confirmation v-if="tab === 3" @back="handleBack"></booking-confirmation>
            </div>
        </div>
    </settings-checker>
</template>

<script>
/* eslint-disable */
    import { mapActions, mapMutations } from 'vuex'
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import PetstarRibbon from './PetstarRibbon.vue'
    import Header from './Header.vue';
    import OpeningHours from './OpeningHours.vue';
    import PetSelect from './PetSelect/PetSelect.vue'
    import ClientSet from './Authenticated/ClientSet.vue';
    import DurationSet from './Authenticated/DurationSet.vue'
    import ProductsSelect from './Authenticated/ProductsSelect.vue'
    import DateTimeSelect from './TimeSlots/DateTimeSelect.vue'
    import SettingsChecker from './SettingsChecker.vue'
    import Summary from './Summary.vue'
    import Confirmation from './Authenticated/Confirmation.vue'
    import Idle from './Idle.vue'

    export default {
        name: 'BookingAuthenticated',
        mixins: [SiteBookingSettingMixin],
        data() {
            return {
                tab: 0,
                lastPet: false
            }
        },
        methods: {
            ...mapActions('Authentication', [
                'logout'
            ]),
            ...mapMutations('BookingUpload', [
                'resetBookingUpload'
            ]),
            handleNext() {
                if(!this.siteBookingSettingServiceSelectEnabled && this.tab === 0) {
                    this.tab = 2
                } else {
                    this.tab++
                }
            },
            handleBack() {
                if(!this.siteBookingSettingServiceSelectEnabled && this.tab === 2) {
                    this.tab = 0
                } else {
                    this.tab--
                }
            },
            handleToLastPet(value) {
                this.lastPet = !!value
            },
            tryLogout(){
                this.logout().then(() => {
                    this.$router.push({ name: 'login', query: {siteId: this.selectedSiteId }})
                        .then(() => { window.location.reload()})
                });
            },
            handleIdle() {
                this.tryLogout();
            }
        },
        created(){
            this.resetBookingUpload()
        },
        components: {
            'settings-checker': SettingsChecker,
            'petstar-ribbon': PetstarRibbon,
            'booking-header': Header,
            'opening-hours': OpeningHours,
            'booking-summary': Summary,
            'pet-select': PetSelect,
            'products-select': ProductsSelect,
            'date-time-select': DateTimeSelect,
            'booking-confirmation': Confirmation,
            'duration-set': DurationSet,
            'client-set': ClientSet,
            'idle': Idle
        }
    }
</script>