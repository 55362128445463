/* eslint-disable */
import { mapActions, mapGetters, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const ClientAddressCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationClientAddress', [
            'loadingClientAddress',
            'loadedClientAddress',
            'clientAddressCache'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        ownerTypeId() {
            return 2;
        }
    },
    watch: {
        inviteId() {
            this.refreshClientAddress()
        }
    },
    methods: {
        ...mapActions('RegistrationClientAddress', [
            'fetchClientAddress'
        ]),
        refreshClientAddress() {
            if (!this.loadedClientAddress && this.inviteId) {
                this.tryFetchClientAddress();
            }
        },
        tryFetchClientAddress() {
            const self = this;
            self.fetchClientAddress(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientAddress();
    }
}
