/* eslint-disable */
export const EmailHelper = {
    isValid(email) {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    getEmailValidator() {
        return {
            isValid: false,
            email: '',
            check(value) {
                this.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ? '' : "Please enter a valid email address"; 
                this.isValid = !this.email;
            }
        }
    }
}