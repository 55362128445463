<template>
  <labeled-form-control label="Medical Notes">
    <textarea v-model="noteText" class="form-control" cols="50" placeholder="Add notes (optional)" rows="5" :disabled="updated"></textarea>
  </labeled-form-control>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { PetNoteUploadMixin } from '@/mixins/Registration/PetNoteUploadMixin'
import { PetCacheMixin } from '@/mixins/Registration/PetCacheMixin'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'

export default {
  name: 'PetMedicalNote',
  mixins: [PetNoteUploadMixin, PetCacheMixin],
  props: {
    invite: Object,
    petIndex: Number,
    updated: Boolean
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedPetNoteEvent']),
    ...mapGetters('ClientOnboardingInvites', ['findClientOnboardingInviteCache']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    petId () {
      return this.petCaches ? this.petCaches[this.petIndex].id : ''
    },
    ownerId () {
      return this.petId ? this.petId : ''
    },
    clientOnboardingInviteCache () {
      return this.findClientOnboardingInviteCache(this.inviteId)
    },
    updatedPetNotes () {
      return this.clientOnboardingInviteCache ? this.clientOnboardingInviteCache.value.updatedPetNotes : null
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedPetNote']),
    ...mapMutations('ApplicationEvents', ['triggerUpdatedPetNotes']),
    ...mapMutations('ApplicationEvents', ['triggerResetUpdatedPetNote']),
    ...mapMutations('ClientOnboardingInvites', ['setUpdatedPetNote'])
  },
  watch: {
    async updatedPetNoteEvent (value) {
      if (value) {
        const petNoteUpdated = this.updatedPetNotes ? this.updatedPetNotes.includes(this.petId) : false
        if (value === this.petIndex + 1 && !petNoteUpdated) {
          try {
            await this.tryUploadPetNote()
          } finally {
            this.setUpdatedPetNote({ inviteId: this.inviteId, petId: this.petId })
            this.triggerUpdatedPetNote()
          }
        } else if (value === this.petIndex + 1 && petNoteUpdated) {
          this.triggerUpdatedPetNote()
        } else if (value > this.petCaches.length) {
          this.triggerUpdatedPetNotes()
        }
      }
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl
  }
}
</script>
