<template>
    <div></div>
</template>

<script>
/* eslint-disable */
    import { mapMutations, mapState } from 'vuex'
    import { PetDurationCacheMixin } from '@/mixins/Booking/PetDurationCacheMixin'
    import { AppointmentDefaultsSettingMixin } from '@/mixins/Booking/AppointmentDefaultsSettingMixin'
    
    export default {
        name: 'DurationSet',
        mixins: [PetDurationCacheMixin, AppointmentDefaultsSettingMixin],
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            selectedPetIds() {
                return this.selectedPets.length > 0 ? this.selectedPets.map(p => p.id) : [];
            },
            highestPetDuration() {
                const durations = this.petDurations.filter(duration => this.selectedPetIds.includes(duration.petId));

                // Find the maximum duration among the durations
                return durations.reduce((max, duration) => {
                    const durationInMinutes = duration.hours * 60 + duration.minutes;
                    return durationInMinutes > max ? durationInMinutes : max;
                }, 0);
            },
            petDurationMinutes() {
                return !!this.settingObject ? this.settingObject.petDurationMinutes : this.defaultSettingObject.petDurationMinutes;
            }
        },
        watch: {
            highestPetDuration() {
                this.refreshDuration();
            },
            petDurationMinutes() {
                this.refreshDuration();
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'setDuration'
            ]),
            refreshDuration(){
                var duration = !!this.highestPetDuration ? this.highestPetDuration : this.petDurationMinutes;
                this.setDuration(duration);
            }
        },
        created() {
            this.refreshDuration();
        }
    }
</script>
