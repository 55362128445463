/* eslint-disable */
import { FormatCountryCacheMixin } from "@/mixins/Format/FormatCountryCacheMixin";

export const MobileValidatorMixin = {
    mixins: [FormatCountryCacheMixin],
    data() {
        return {
            mobileValidation: ''
        }
    },
    computed: {
        mobileRegex() {
            return !!this.countryFormat ? new RegExp(this.countryFormat.mobileFormat) : new RegExp();
        }
    },
    methods: {
        validateMobile(mobile) {
            this.mobileValidation = '';
            if (!mobile) {
                this.mobileValidation = 'Please enter mobile'
                return;
            }
            try {
                if (!mobile.match(new RegExp(this.mobileRegex))) {
                    this.mobileValidation = 'Mobile format is invalid for ' + this.countryName;
                }
            } catch { }
        }
    }
}
