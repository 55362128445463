/* eslint-disable */
import { WebApi } from '@/services/WebApi';

const mutations = {
    sendingNotification(state, value) {
        state.sendingNotification = !!value;
    }
}

const actions = {
    sendNotification({ commit }, inviteId ) {
        return new Promise((resolve, reject) => {
            commit('sendingNotification', true);
            WebApi.post('ClientOnboardingInvite/'+ inviteId + '/Notification')
                .then(response => { resolve(response)})
                .catch(error => reject(error))
                .finally(() => commit('sendingNotification', false));
        });
    }
}

export default {
    namespaced: true,
    mutations,
    actions
}
