<template>
    <div class="btn-pet" :class="{ 'btn-pet-active': active }" @click="setSelectedPets(petsWithPetId)">
        <div class="btn-pet-name">All</div>
        <div class="btn-pet-breed">{{petNames}}</div>
    </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from 'vuex'
import { PetCacheMixin } from '@/mixins/Booking/PetCacheMixin';

export default {
    name: 'PetSelectAll',
    mixins: [PetCacheMixin],
    computed: {
        ...mapState('BookingUpload', [
            'selectedPets'
        ]),
        petsWithPetId: function() {
            return this.pets.map(pet => {
                return {
                    ...pet,
                    petId: pet.id,
                    serviceItems: []
                };
            });
        },
        petNames() {
            return this.pets
                .map(pet => pet.name)
                .join(', ');
        },
        allPetIds() {
            return this.pets.map(pet => pet.id);
        },
        selectedPetIds() {
            return this.selectedPets.length > 0 ? this.selectedPets.map(p => p.id) : []; 
        },
        active() {
            return (
                this.selectedPetIds.length === this.allPetIds.length &&
                this.selectedPetIds.every(id => this.allPetIds.includes(id))
            );
        }
    },
    methods: {
        ...mapMutations('BookingUpload', [
            'setSelectedPets'
        ])
    }
}
</script>
