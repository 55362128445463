<template>
    <div>
        <div v-if="index !== 0" class="mb-2" style="border-top: 1px solid #D9D9D9"></div>
        <div class="w-100 d-flex align-items-center justify-content-between">
            <div>Pet {{index + 1}}</div>
            <button v-if="index !== 0"
                class="btn btn-danger text-white"
                @click="deletePet">
                <i class="fas fa-trash-alt"></i>
            </button>
        </div>
        <div class="form-group w-100">
            <label for="petName" class="w-100 text-left">Name</label>
            <input v-model="petName"
                type="text"
                class="form-control"
                name="petName"
                placeholder="Enter your pet's name">
            <validation-message v-if="petAndBreedValidator.name" :message="petAndBreedValidator.name"></validation-message>
        </div>
        <pet-breed :pet-id="petId" :validator="petAndBreedValidator.breedId"></pet-breed>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState, mapMutations } from 'vuex';
    import { PetHelper } from '@/helpers/PetHelper';
    import PetBreed from './PetBreed.vue';
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

    export default {
        name: 'AddPet',
        props: {
            index: 0,
            petId: ''
        },
        data() {
            return {
                petAndBreedValidator: PetHelper.getPetAndBreedValidator()
            }
        },
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            pet() {
                return this.selectedPets.length > 0 ? this.selectedPets.find(p => p.id === this.petId) : null
            },
            petName: {
                get() {
                    return !!this.pet ? this.pet.name : ''
                },
                set(value) {
                    this.updatePetName({petId: this.petId, name: value})
                }
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'updatePetName',
                'updatePetIsValid'
            ]),
            deletePet() {
                if (this.index !== 0) {
                    this.$emit('deletePet', this.id);
                }
            },
            next() {
                this.petAndBreedValidator.check(this.pet)
                this.updatePetIsValid({petId: this.petId, value: this.petAndBreedValidator.isValid})
            }
        },
        components: {
            'pet-breed': PetBreed,
            'validation-message': ValidationMessage
        }
    }
</script>

<style scoped>
    .btn {
        padding: 0.5rem 0.75rem !important;
    }
</style>
