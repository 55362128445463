<template>
    <div class="pdf-scroll" :class="{'pdf-scroll-adjusted': signatureRequired}">
      <!-- trivial comemnt -->
        <vue-pdf-embed v-if="pdfSource" :source="pdfSource" :width="pdfWidth" @loaded="tryViewContract"></vue-pdf-embed>
    </div>
</template>

<script>
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'PdfViewer',
  data () {
    return {
      error: '',
      loading: false,
      pdfSource: ''
    }
  },
  props: {
    contract: {
      type: Object,
      required: true
    },
    signatureRequired: {
      default: false
    }
  },
  computed: {
    contractId () {
      return this.contract.id
    },
    pdfWidth () {
      if (window.innerWidth < 768) {
        return window.innerWidth
      } else if (window.innerWidth > 768 && window.innerWidth < 1900) {
        return 1000
      } else {
        return 1440
      }
    }
  },
  methods: {
    tryFetchPdf () {
      const self = this
      self.error = ''
      self.loading = true
      WebApi.get('ClientContract/' + self.contractId + '/Pdf')
        .then(response => { self.pdfSource = 'data:application/pdf;base64,' + response.data })
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    },
    tryViewContract () {
      const self = this
      self.error = ''
      self.loading = true
      WebApi.put('ClientContract/' + self.contractId + '/View')
        .catch(error => { self.error = ErrorHelper.getError(error) })
        .finally(() => { self.loading = false })
    }
  },
  components: {
    VuePdfEmbed
  },
  created () {
    this.tryFetchPdf()
  }
}
</script>

<style scoped>
  .pdf-scroll {
    overflow: auto;
    height: calc(100vh - 6.25rem) !important;
    width: 960px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px)  {
    .pdf-scroll {
      height: calc(100vh - 6.25rem) !important;
      width: 100%;
    }
  }
  @media (min-width: 1900px) {
    .pdf-scroll {
      width: 1440px;
    }
  }
  .pdf-scroll-adjusted {
    height: calc(100vh - 21.75rem) !important;
  }

  @media screen and (max-width: 768px)  {
    .pdf-scroll-adjusted {
      height: calc(100vh - 20.5rem) !important;
      width: 100%;
    }
  }
</style>
