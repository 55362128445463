<template>
  <div v-if="clientCheckouts.length > 0">
    <h3 class="text-left text-primary">Payments</h3>
    <div v-if="error" class="alert alert-danger alert-icon" role="alert">
      <div class="alert-content">
        <p>{{ error }}</p>
      </div>
    </div>
    <template v-if="displayTable">
      <payment-processor v-if="processing" :checkout="processing" @cancelled="onCancel" @completed="onComplete"></payment-processor>
      <template v-else>
        <table-desktop class="d-md-block d-none">
          <template #header>
            <tr class="bg-primary text-white">
              <th>Date</th>
              <th>Amount ({{ currencySymbol }})</th>
              <th>Status</th>
              <th></th>
            </tr>
          </template>
          <template #rows>
            <row-desktop v-for="(checkout, index) in clientCheckouts" :key="checkout.id" :checkout="checkout"
              :date-format="momentJSDateFormat" :number="index" @process="onProcess"></row-desktop>
          </template>
        </table-desktop>
        <table-mobile class="d-md-none d-block">
          <template #rows>
            <row-mobile v-for="(checkout, index) in clientCheckouts" :key="checkout.id" :checkout="checkout"
              :date-format="momentJSDateFormat" :number="index" @process="onProcess"></row-mobile>
          </template>
        </table-mobile>
      </template>
    </template>
    <template v-else>
      No payments to display
    </template>
  </div>
</template>

<script>
import { ClientCheckoutsCacheMixin } from '@/mixins/Payment/ClientCheckoutsCacheMixin'
import TableDesktop from '../Layout/TableDesktop.vue'
import PaymentsTableMobile from './PaymentsTableMobile.vue'
import { DateFormatMixin } from '@/mixins/Format/DateFormatMixin'
import PaymentDesktopRow from './PaymentDesktopRow.vue'
import PaymentMobileRow from './PaymentMobileRow.vue'
import { CountryCacheMixin } from '@/mixins/Site/CountryCacheMixin'
import { mapActions } from 'vuex'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import PaymentProcessor from '../Payments/PaymentProcessor.vue'

export default {
  name: 'PaymentsTable',
  mixins: [DateFormatMixin, CountryCacheMixin, ClientCheckoutsCacheMixin],
  data () {
    return {
      processing: null
    }
  },
  computed: {
    displayTable () {
      return this.clientCheckouts.length > 0
    }
  },
  methods: {
    ...mapActions('ClientCheckouts', [
      'updateClientCheckout'
    ]),
    tryUpdateClientCheckout () {
      const self = this
      if (!self.processing) {
        return
      }
      self.updateClientCheckout({ reference: self.reference, checkout: self.processing })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    },
    onProcess (checkout) {
      this.error = ''
      this.processing = checkout
    },
    onCancel () {
      this.tryUpdateClientCheckout()
      this.processing = null
    },
    onComplete (message) {
      this.error = message || ''
      this.tryUpdateClientCheckout()
      this.processing = null
    }
  },
  components: {
    'table-desktop': TableDesktop,
    'table-mobile': PaymentsTableMobile,
    'row-desktop': PaymentDesktopRow,
    'row-mobile': PaymentMobileRow,
    'payment-processor': PaymentProcessor
  }
}
</script>
