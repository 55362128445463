<template>
    <div class="display-banner">
        <div v-if="loadingImages" class="display-banner-img w-100 d-flex align-items-center justify-content-center">
            <span class="inline-loader d-md-block d-none h-100"></span>
            <span class="inline-loader d-md-none d-block"></span>
        </div>
        <img v-else-if="image" class="display-banner-img" :src="image.thumbnails.large" alt="Banner" >
        <img v-else src="@/assets/Images/OnlineBooking_Header_Default.png" alt="display-header" class="display-banner-img">
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex';
    import { ImageCacheMixin } from '@/mixins/Booking/ImageCacheMixin';
    import { SiteBookingPortalImageSettingMixin } from '@/mixins/Booking/SiteBookingPortalImageSettingMixin';

    export default {
        name: 'DisplayBanner',
        mixins: [SiteBookingPortalImageSettingMixin, ImageCacheMixin],
        computed: {
            ...mapState('Sites', [
                'sites'
            ]),
            selected() {
                return this.sites.length > 0 ? this.sites[0] : [];
            },
            selectedSiteId() {
                return this.$route.query.siteId ? this.$route.query.siteId : this.selected ? this.selected.id : '';
            },
            ownerId() {
                return this.selectedSiteId;
            },
            ownerTypeId() {
                return 12;
            },
            ownerQueryModel() {
                return {
                    ownerId: this.ownerId,
                    ownerTypeId: this.ownerTypeId,
                }
            },
            imageId() {
                return !!this.settingObject ? this.settingObject.imageId : '';
            },
            image() {
                return this.images.find(i => i.id === this.imageId);
            }
        }
    }
</script>