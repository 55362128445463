<template>
    <div class="payment_table mb-5">
        <div class="d-flex bg-primary text-white">
            <div class="payment_table-head col-4">Date</div>
            <div class="payment_table-head col-4">Amount (£)</div>
            <div class="payment_table-head col-4">Status</div>
        </div>
        <slot name="rows"></slot>
    </div>
</template>

<script>
export default {
  name: 'PaymentsTableMobile'
}
</script>

<style scoped>
.payment_table {
  display: table;
  width: 100%;
  font-size: 0.8rem;
}

.payment_table-head {
  padding: 0.75rem;
  font-weight: bold;
}
</style>
