/* eslint-disable */
import { mapActions } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { ClientAddressAddMixin } from "./ClientAddressAddMixin";

export const ClientAddressUploadMixin = {
    mixins: [ClientAddressAddMixin],
    computed: {
        clientAddressUpdateModel() {
            return !!this.clientAddressCache ? Object.assign({}, this.clientAddressCache, {
                line1: this.line1,
                line2: this.line2,
                line3: this.line3,
                town: this.town,
                county: this.county,
                postCode: this.postCode
            }) : {};
        }
    },
    watch: {
        clientAddressCache() {
            this.refreshClientAddressUploadModel()
        }
    },
    methods: {
        ...mapActions('RegistrationClientAddress', [
            'updateClientAddress'
        ]),
        refreshClientAddressUploadModel() {
            this.line1 = !!this.clientAddressCache ? this.clientAddressCache.line1 : '';
            this.line2 = !!this.clientAddressCache ? this.clientAddressCache.line2 : '';
            this.line3 = !!this.clientAddressCache ? this.clientAddressCache.line3 : '';
            this.town = !!this.clientAddressCache ? this.clientAddressCache.town : '';
            this.county = !!this.clientAddressCache ? this.clientAddressCache.county : '';
            this.postCode = !!this.clientAddressCache ? this.clientAddressCache.postCode : '';
        },
        tryUploadClientAddress() {
            if (!!this.clientAddressCache) {
                return this.tryUpdateClientAddress();
            } else {
                return this.tryAddClientAddress();
            }
        },
        tryUpdateClientAddress() {
            const self = this;
            self.clientAddressValidator.check(self.clientAddressUpdateModel);
            if (!self.clientAddressValidator.isValid) {
                return;
            }
            self.error = '';
            return self.updateClientAddress({ inviteId: self.inviteId, payload: self.clientAddressUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshClientAddressUploadModel();
    }
}
