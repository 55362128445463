<template>
    <tr>
        <td>{{ startFormatted }}</td>
        <td>{{ petName }}</td>
        <td>{{ resourceName }}</td>
        <td>{{ status }}</td>
    </tr>
</template>

<script>
import { AppointmentRowMixin } from '@/mixins/Appointment/AppointmentRowMixin'

export default {
  name: 'AppointmentDesktopRow',
  mixins: [AppointmentRowMixin]
}
</script>
