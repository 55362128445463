/* eslint-disable */
import { mapActions } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { AlternateContactAddMixin } from "./AlternateContactAddMixin";

export const AlternateContactUploadMixin = {
    mixins: [AlternateContactAddMixin],
    computed: {
        alternateContactUpdateModel() {
            return !!this.alternateContactCache ? Object.assign({}, this.alternateContactCache, {
                name: this.name,
                mobile: this.mobile,
                email: this.email,
                otherInfo: this.otherInfo
            }) : {};
        }
    },
    watch: {
        alternateContactCache() {
            this.refreshAlternateContactUploadModel();
        }
    },
    methods: {
        ...mapActions('RegistrationAlternateContact', [
            'updateAlternateContact'
        ]),
        refreshAlternateContactUploadModel() {
            this.name = !!this.alternateContactCache ? this.alternateContactCache.name : '';
            this.email = !!this.alternateContactCache ? this.alternateContactCache.email : '';
            this.mobile = !!this.alternateContactCache ? this.alternateContactCache.mobile : '';
            this.otherInfo = !!this.alternateContactCache ? this.alternateContactCache.otherInfo : '';
        },
        tryUploadAlternateContact() {
            if (!!this.alternateContactCache) {
                return this.tryUpdateAlternateContact();
            } else {
                return this.tryAddAlternateContact();
            }
        },
        tryUpdateAlternateContact() {
            const self = this;
            self.error = '';
            return self.updateAlternateContact({ inviteId: self.inviteId, payload: self.alternateContactUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshAlternateContactUploadModel();
    }
}
