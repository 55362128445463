<template>
  <div>
    <template v-if="token">
        <template v-if="user">
          <router-view/>
        </template>
        <template v-else>
            <template v-if="error">
                <h2 class="text-center">{{ error }}</h2>
            </template>
            <template v-else>
                <page-loader></page-loader>
            </template>
        </template>
    </template>
    <template v-else>
        <h2 class="text-center">Link is invalid</h2>
    </template>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { WebApi } from '@/services/WebApi'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import PageLoader from '@/components/Loading/PageLoader.vue'

export default {
  name: 'ExternalView',
  data () {
    return {
      error: '',
      user: null
    }
  },
  computed: {
    token () {
      return this.$route.query.token
    }
  },
  methods: {
    ...mapMutations('Sites', [
      'clearSites'
    ]),
    refreshUser () {
      const self = this
      if (!self.token) {
        return
      }
      self.error = ''
      WebApi.post('Authentication/Token/Login', { token: self.token })
        .then(response => { self.user = response.data })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  components: {
    'page-loader': PageLoader
  },
  created () {
    this.clearSites()
    this.refreshUser()
  }
}
</script>
