/* eslint-disable */
import { WebApi } from '../../services/WebApi';

const state = () => ({
    eventSearches: [],
    eventSearchesCleared: 0
})

const getters = {
    findEventSearch: (state) => (key) => {
        return state.eventSearches.find(ac => ac.key === key);
    }
}

const mutations = {
    addEventSearch(state, eventCache) {
        const existing = state.eventSearches.find(ac => ac.key === eventCache.key);
        if (existing) {
            eventCache.value.events = existing.value.events.concat(eventCache.value.events);
        }
        const local = state.eventSearches.filter(ac => ac.key !== eventCache.key);
        local.push(eventCache);
        state.eventSearches = local;
    },
    setRetry(state, key) {
        const existing = state.eventSearches.find(ac => ac.key === key);
        if (existing) {
            existing.retry = true;
            existing.loading = false;
        }
    },
    clearEventSearches(state) {
        state.eventSearches = [];
        state.eventSearchesCleared++;
    }
}

const actions = {
    searchEventsBySiteId({ commit }, { key, siteId, payload }) {
        return new Promise((resolve, reject) => {
            commit('addEventSearch', {
                key: key,
                value: { events: [], more: false },
                loading: true
            });
            WebApi.post('Portal/'+ siteId + '/Event/Search', payload)
                .then(response => {
                    commit('addEventSearch', {
                        key: key,
                        value: {
                            events: response.data
                        },
                        loading: false
                    });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
