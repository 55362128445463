import { SiteHelper } from '@/helpers/SiteHelper'
import { WebApi } from '@/services/WebApi'

const state = () => ({
  fetchedSites: false,
  fetchingSites: false,
  sites: [],
  selected: SiteHelper.getDefaultSite()
})

const mutations = {
  setFetching (state, fetching) {
    state.fetchingSites = !!fetching
    state.fetchedSites = false
  },
  setSites (state, sites) {
    state.sites = sites
    state.selected = sites.length > 0 ? sites[0] : SiteHelper.getDefaultSite()
    SiteHelper.setDefaultSite(state.selected)
    state.fetchedSites = true
    state.fetchingSites = false
  },
  clearSites (state) {
    SiteHelper.clearDefaultSite()
    state.selected = SiteHelper.getDefaultSite()
    state.sites = []
  }
}

const actions = {
  fetchSites ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Site')
        .then(response => {
          commit('setSites', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  },
  fetchSiteById ({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Portal/' + id + '/Site')
        .then(response => {
          commit('setSites', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
