import { ErrorHelper } from '@/helpers/ErrorHelper'
import { WebApi } from '@/services/WebApi'

const state = () => ({
  dateFormatSettingCaches: []
})

const getters = {
  findDateFormatSettingCache: (state) => key => state.dateFormatSettingCaches.find(dfsc => dfsc.key === key)
}

const mutations = {
  setLoading (state, { key, loading }) {
    const existing = state.dateFormatSettingCaches.find(dfsc => dfsc.key === key)
    if (existing) {
      existing.loading = !!loading
      existing.retry = false
    } else {
      state.dateFormatSettingCaches.push({ key: key, value: null, loading: !!loading })
    }
  },
  setDateFormatSetting (state, { key, dateFormatSetting }) {
    const existing = state.dateFormatSettingCaches.find(dfsc => dfsc.key === key)
    if (existing) {
      existing.value = dateFormatSetting
      existing.loading = false
    } else {
      state.dateFormatSettingCaches.push({ key: key, value: dateFormatSetting, loading: false })
    }
  },
  setRetry (state, key) {
    const existing = state.dateFormatSettingCaches.find(dfsc => dfsc.key === key)
    if (existing) {
      existing.retry = true
    }
  }
}

const actions = {
  fetchDateFormatSetting ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: siteId, loading: true })
      WebApi.get('DateFormatSetting')
        .then(response => {
          commit('setDateFormatSetting', { key: siteId, dateFormatSetting: response.data })
          resolve(response)
        })
        .catch(error => {
          if (ErrorHelper.isNotFound(error)) {
            commit('setLoading', { key: siteId, loading: false })
            resolve(false)
          } else {
            commit('setRetry', siteId)
            reject(error)
          }
        })
    })
  },
  fetchDateFormatSettingBySiteId ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setLoading', { key: siteId, loading: true })
      WebApi.get('Portal/' + siteId + '/DateFormatSetting')
        .then(response => {
          commit('setDateFormatSetting', { key: siteId, dateFormatSetting: response.data })
          resolve(response)
        })
        .catch(error => {
          if (ErrorHelper.isNotFound(error)) {
            commit('setLoading', { key: siteId, loading: false })
            resolve(false)
          } else {
            commit('setRetry', siteId)
            reject(error)
          }
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
