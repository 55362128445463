/* eslint-disable */
import { FormatCountryCacheMixin } from "@/mixins/Booking/FormatCountryCacheMixin";

export const MobileValidatorMixin = {
    mixins: [FormatCountryCacheMixin],
    data() {
        return {
            message: ''
        }
    },
    computed: {
        mobileRegex() {
            return !!this.countryFormat ? new RegExp(this.countryFormat.mobileFormat) : new RegExp();
        }
    },
    methods: {
        validateMobile(value) {
            this.message = '';
            this.message = value.match(new RegExp(this.mobileRegex)) ? '' : 'Please enter a valid mobile format for ' + this.countryName;
        }
    }
}
