<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column w-100">
        <div class="d-flex">
          <input v-model="searchTerm"
            class="form-control search-input"
            placeholder="Search your pet's breed"
            type="search"/>
          <div class="form-control search-icon">
              <span v-if="loadingBreedSearch" class="inline-loader"></span>
              <i v-else class="fas fa-search"></i>
          </div>
        </div>
        <validation-message v-if="validator && !breedResultsReady" :message="validator"></validation-message>
    </div>
    <search-results v-if="breedResultsReady"
      :breeds="breeds"
      @breedSelected="handleBreedSelected">
    </search-results>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations } from 'vuex';
import { BreedSearchMixin } from '@/mixins/Booking/BreedSearchMixin'
import PetBreedSearchFieldResults from './PetBreedSearchFieldResults.vue'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue';

export default {
  name: 'PetBreedSearchField',
  mixins: [BreedSearchMixin],
  props: {
      petId: '',
      validator: ''
  },
  methods: {
    ...mapMutations('BookingUpload', [
      'updateBreed'
    ]),
    handleBreedSelected (breed) {
      this.updateBreed({petId: this.petId, breedId: breed.id });
      this.searchTerm = ''
    }
  },
  components: {
    'search-results': PetBreedSearchFieldResults,
    'validation-message': ValidationMessage
  }
}
</script>

<style scoped>
.search-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-icon {
    width: max-content;
    padding: 0.375rem 0.75rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
