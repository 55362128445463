import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapGetters } from 'vuex'

export const ScheduleAppointmentsCacheMixin = {
  data () {
    return {
      error: ''
    }
  },
  props: {
    scheduleId: {
      type: String,
      required: true
    },
    sequence: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('ScheduleAppointments', [
      'findScheduleAppointmentsCache'
    ]),
    scheduleAppointmentsFilterRequest () {
      return {
        sequence: this.sequence
      }
    },
    scheduleAppointmentsCacheKey () {
      return this.scheduleId + this.sequence
    },
    scheduleAppointmentsCache () {
      return this.findScheduleAppointmentsCache(this.scheduleAppointmentsCacheKey)
    },
    scheduleAppointments () {
      return this.scheduleAppointmentsCache ? this.scheduleAppointmentsCache.values : []
    },
    loadingScheduleAppointments () {
      return !!this.scheduleAppointmentsCache && this.scheduleAppointmentsCache.loading
    }
  },
  watch: {
    scheduleAppointmentsCacheKey () {
      this.refreshScheduleAppointments()
    }
  },
  methods: {
    ...mapActions('ScheduleAppointments', [
      'fetchScheduleAppointments'
    ]),
    refreshScheduleAppointments () {
      if (!this.scheduleAppointmentsCache || this.scheduleAppointmentsCache.retry) {
        this.tryFetchScheduleAppointments()
      }
    },
    tryFetchScheduleAppointments () {
      const self = this
      self.error = ''
      self.fetchScheduleAppointments({ key: self.scheduleAppointmentsCacheKey, scheduleId: self.scheduleId, payload: self.scheduleAppointmentsFilterRequest })
        .catch(error => { self.error = ErrorHelper.getError(error) })
    }
  },
  created () {
    this.refreshScheduleAppointments()
  }
}
