<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <template v-if="loadingResources">
                <span class="inline-loader d-md-block d-none h-100"></span>
                <span class="inline-loader d-md-none d-block"></span>
            </template>
            <template v-else-if="noResources">
                <display-banner></display-banner>
                <div class="font-size-h2 text-header-color mt-3 mt-md-1 px-4">NO RESOURCES AVAILABLE</div>
            </template>
            <template v-else>
                <div class="my-2 px-2 text-default w-100">Select a Date & Time</div>
                <date-select></date-select>
                <validation-message v-if="message" :message="message" class="mt-2"></validation-message>
                <time-slots></time-slots>
            </template>
        </div>
        <div class="fixed-buttons" v-if="!noResources">
            <button class="btn btn-tertiary w-100 mr-2" @click="back">Back</button>
            <button class="btn btn-secondary w-100 ml-2" @click="next">Next</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'
    import { ResourceCacheMixin } from '@/mixins/Booking/ResourceCacheMixin'
    import { WorkingHourCacheMixin } from '@/mixins/Booking/WorkingHourCacheMixin'
    import DateSelect from './DateSelect.vue'
    import TimeSlots from './TimeSlots.vue'
    import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'
    import DisplayBanner from '../DisplayBanner.vue'

    export default {
        name: 'DateTimeSelect',
        mixins: [ResourceCacheMixin, WorkingHourCacheMixin],
        data() {
            return {
                message: '',
                isValid: false
            } 
        },
        computed: {
            ...mapState('BookingUpload', [
                'startUtc'
            ]),
            ...mapState('BookingSelectedDate', [
                'selectedDate'
            ]),
            noResources() {
                return !!this.attemptedResources && this.unhiddenResources.length < 1 && !this.loadingResources
            }
        },
        watch: {
            selectedDate() {
                this.message = '';
                this.isValid = false;
            }
        },
        methods: {
            back() {
                this.$emit('back');
                this.$emit('toLastPet', true)
            },
            next() {
                this.check();
                if(this.isValid) {
                    this.$emit('next');
                }
            },
            check() {
                this.isValid = !!this.startUtc;
                this.message = this.isValid ? '' : 'Please select a time slot';
            }
        },
        components: {
            'date-select': DateSelect,
            'time-slots': TimeSlots,
            'validation-message': ValidationMessage,
            'display-banner': DisplayBanner
        }
    }
</script>
