/* eslint-disable */
import { mapState } from 'vuex';
import { SelectedSiteIdMixin } from './SelectedSiteIdMixin'
import { WebApi } from '@/services/WebApi'

export const ClientUserCheckingMixin = {
    mixins: [SelectedSiteIdMixin],
    data() {
        return {
            fetchedClient: null,
            fetchingClient: false,
            error: ''
        }
    },
    computed: {
        ...mapState('Client', [
            'client'
        ]),
        cacheClientId() {
            return !!this.client ? this.client.id : ''
        },
        fetchedClientId() {
            return !!this.fetchedClient ? this.fetchedClient.id : '';
        },
        clientChanged() {
            return (this.cacheClientId !== this.fetchedClientId) && !!this.fetchedClient && !!this.client;
        }
    },
    watch: {
        clientChanged(value) {
            this.checkClient();
        }
    },
    methods: {
        fetchClient() {
            const self = this
            self.error = ''
            if(!self.selectedSiteId) {
                return;
            }
            self.fetchingClient = true;
            WebApi.get('Portal/' + self.selectedSiteId + '/Client')
                .then(response => { 
                    self.fetchedClient = response.data;
                    self.checkClient();
                })
                .finally(() => {self.fetchingClient = false});
        },
        checkClient() {
            if(!!this.clientChanged) {
                window.location.reload();
            }
        }
    },
    created() {
        this.fetchClient();
    }
}
