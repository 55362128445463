/* eslint-disable */
import { mapActions, mapState } from "vuex"
import { ErrorHelper } from "@/helpers/ErrorHelper";

export const VetContactCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('RegistrationVetContact', [
            'vetContactCache',
            'loadingVetContact',
            'loadedVetContact'
        ]),
        inviteId() {
            console.log('!!!OVERRIDE COMPUTED - inviteId!!!');
        },
        contactTypeId(){
            return 3;
        },
        ownerTypeId() {
            return 2;
        }
    },
    watch: {
        inviteId() {
            this.refreshVetContact()
        }
    },
    methods: {
        ...mapActions('RegistrationVetContact', [
            'fetchVetContact'
        ]),
        refreshVetContact() {
            if (!this.loadedVetContact && this.inviteId) {
                this.tryFetchVetContact();
            }
        },
        tryFetchVetContact() {
            const self = this;
            self.error = '';
            self.fetchVetContact(self.inviteId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshVetContact();
    }
}
