<template>
    <collapsed-section title="Vet Details">
      <error-banner v-if="error" :message="error"></error-banner>
      <labeled-form-control label="Name">
        <input v-model="name" class="form-control" type="text" placeholder="Name" :disabled="vetContactUpdated"/>
      </labeled-form-control>
      <labeled-form-control label="Mobile">
        <input v-model="mobile" class="form-control" type="tel" placeholder="Mobile" :disabled="vetContactUpdated"/>
      </labeled-form-control>
      <labeled-form-control label="Address">
        <textarea v-model="otherInfo" class="form-control" cols="50" rows="5" maxlength="50" placeholder="Address" :disabled="vetContactUpdated"></textarea>
      </labeled-form-control>
    </collapsed-section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { VetContactUploadMixin } from '../../mixins/Registration/VetContactUploadMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import CollapsedSection from '../Layout/CollapsedSection.vue'
import LabeledFormControl from '../Layout/LabeledFormControl.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'ClientVetContactSection',
  mixins: [VetContactUploadMixin, ClientOnboardingInviteCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['updatedPetNotesEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    ownerId () {
      return this.invite ? this.invite.clientId : ''
    },
    vetContactUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.vetContactUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedVetContact'])
  },
  watch: {
    async updatedPetNotesEvent () {
      if (!this.vetContactUpdated) {
        try {
          await this.tryUploadVetContact()
        } finally {
          this.triggerUpdatedVetContact()
        }
      } else {
        this.triggerUpdatedVetContact()
      }
    }
  },
  components: {
    'collapsed-section': CollapsedSection,
    'labeled-form-control': LabeledFormControl,
    'error-banner': ErrorBanner
  }
}
</script>
