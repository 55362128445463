/* eslint-disable */
<template>
    <div class="d-flex flex-column align-items-center">
        <datepicker v-model="date"
            :inline="true"
            :disabled-dates="disabledDates"
            calendar-class="booking-datepicker__calendar"
            maximumView="day"
            calendar-button-icon="far fa-calendar-alt"
            @changedMonth=handleChangedMonth>
        </datepicker>
        <error-banner v-if="error" :message="error" class="mx-3 mt-2"></error-banner>
        <span v-if="loading" class="inline-loader mt-2"></span>
    </div>
</template>

<script>
/* eslint-disable */
    import moment from 'moment-timezone';
    import Datepicker from 'vuejs-datepicker';
    import { mapMutations } from 'vuex';
    import { OpeningHourCacheMixin } from '@/mixins/Booking/OpeningHourCacheMixin';
    import { AppointmentSearchMixin } from '@/mixins/Booking/AppointmentSearchMixin';
    import { EventSearchMixin } from '@/mixins/Booking/EventSearchMixin';
    import { OneOffHourSearchMixin } from '@/mixins/Booking/OneOffHourSearchMixin';
    import { SelectedDateMixin } from '@/mixins/Booking/SelectedDateMixin';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'DateSelect',
        mixins: [OpeningHourCacheMixin, AppointmentSearchMixin, EventSearchMixin, OneOffHourSearchMixin, SelectedDateMixin, SiteBookingSettingMixin],
        computed: {
            date: {
                get() {
                    return this.selectedDate;
                },
                set(value) {
                    this.setSelectedDate(value);
                }
            },
            datePickerInitialDate() {
                return moment().add(this.siteBookingSettingServiceMinNotice, this.siteBookingSettingServiceMinNoticeUnit)._d;
            },
            closedDays() {
                return this.openingHours ? this.openingHours.filter(day => day.closed).map(day => day.dayOfWeek.id) : [];
            },
            disabledDates() {
                return { 
                    to: this.datePickerStartDate,
                    days: this.closedDays,
                    from: this.datePickerEndDate
                }
            },
            loading() {
                return !!this.loadingOpeningHours || !!this.loadingAppointments || !!this.loadingOneOffHours
            }
        },
        watch: {
            closedDays() {
                this.findNearestOpenDate();
            },
            date() {
                this.clearStartUtc();
            }
        },
        methods: {
            ...mapMutations('BookingSelectedDate', [
                'setSelectedDate'
            ]),
            ...mapMutations('BookingUpload', [
                'clearStartUtc'
            ]),
            findNearestOpenDate(value) {
                const currentDate = value;
                const fromDate = this.datePickerEndDate;
                while (currentDate <= fromDate) {
                    if (!this.closedDays.includes(currentDate.getDay())) {
                       this.setSelectedDate(currentDate);
                        return;
                    }
                    currentDate.setDate(currentDate.getDate() + 1);
                }
                this.setSelectedDate(value)
                return;
            },
            handleChangedMonth(value) {
                const monthFirstDate = value > this.datePickerStartDate ? value : this.datePickerInitialDate;
                this.findNearestOpenDate(monthFirstDate);
                this.clearStartUtc();
            }
        },
        created() {
            this.findNearestOpenDate(this.selectedDate);
        },
        components: {
            'error-banner': ErrorBanner,
            Datepicker
        }
    }
</script>
