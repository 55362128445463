/* eslint-disable */
import { WebApi } from "@/services/WebApi"

const state = () => ({
    workingHours: [],
    loadingWorkingHours: false,
    retryWorkingHours: false
})

const mutations = {
    setWorkingHours(state, workingHours) {
        state.workingHours = workingHours;
    },
    setLoading(state, loading) {
        state.loadingWorkingHours = !!loading;
    },
    setRetry(state, retry) {
        state.retryWorkingHours = !!retry;
    }
}

const actions = {
    getWorkingHoursBySiteId({ commit }, siteId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            WebApi.get('Portal/'+ siteId + '/WorkingHour')
                .then(response => {
                    commit('setWorkingHours', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', true);
                    reject(error);
                })
                .finally (() => {
                    commit('setLoading', false);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}