<template>
    <div class="form-group w-100">
        <label for="breed" class="w-100 text-left">Breed</label>
        <div v-if="breed" class="d-flex w-100">
            <input v-model="breed.name"
                class="form-control search-input"
                type="search"
                disabled/>
            <div class="form-control search-icon"
                @click="updateBreed({petId: petId, breedId: ''})"
                style="cursor: pointer !important">
                <i class="fas fa-eraser" ></i>
            </div>
        </div>
        <breed-search v-else :pet-id="petId" :validator="validator"></breed-search>
    </div>
</template>
<script>
/* eslint-disable */
    import { mapState, mapMutations } from 'vuex';
    import PetBreedSearchField from './PetBreedSearchField.vue';
    import { BreedCacheMixin } from '@/mixins/Booking/BreedCacheMixin';

    export default {
        name: 'PetBreed',
        mixins: [BreedCacheMixin],
        props: {
            petId: '',
            validator: ''
        },
        computed: {
            ...mapState('BookingUpload', [
                'selectedPets'
            ]),
            pet() {
                return this.selectedPets.length > 0 ? this.selectedPets.find(p => p.id === this.petId) : null
            },
            breedId() {
                return !!this.pet ? this.pet.breedId : null
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'updateBreed'
            ])
        },
        components: {
            'breed-search': PetBreedSearchField
        }
    }
</script>

<style scoped>
    .search-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .search-icon {
        width: max-content;
        padding: 0.375rem 0.75rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
</style>