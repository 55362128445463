import { FormatOptionsDateCacheMixin } from './FormatOptionsDateCacheMixin'
import { FormatSettingDateCacheMixin } from './FormatSettingDateCacheMixin'

export const DateFormatMixin = {
  mixins: [FormatOptionsDateCacheMixin, FormatSettingDateCacheMixin],
  computed: {
    dateFormatId () {
      return this.dateFormatSetting ? this.dateFormatSetting.dateFormatId : 0
    },
    dateFormatOption () {
      return this.dateFormatOptions.find(dfo => dfo.id === this.dateFormatId)
    },
    momentJSDateFormat () {
      return this.dateFormatOption ? this.dateFormatOption.momentJS : 'DD MMM YYYY'
    },
    cSharpDateFormat () {
      return this.dateFormatOption ? this.dateFormatOption.cSharp : 'dd MMM yyyy'
    }
  }
}
