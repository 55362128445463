/* eslint-disable */
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="col-12 col-md-8">
      <error-banner v-if="error" :message="error"></error-banner>
      <client-details :invite="clientOnboardingInvite"></client-details>
      <emergency-contact :invite="clientOnboardingInvite"></emergency-contact>
      <pets-list :invite="clientOnboardingInvite"></pets-list>
      <vet-details :invite="clientOnboardingInvite"></vet-details>
      <accept-marketing :invite="clientOnboardingInvite"></accept-marketing>
      <div class="d-flex flex-column align-items-center">
        <div class="row">
          <button class="btn btn-secondary" @click="triggerSaveRegistration" v-bind:disabled="loading">
            Save & Complete
            <span v-if="loading" class="inline-loader"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from 'vuex'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import { MarketingSettingUpdateMixin } from '@/mixins/Registration/MarketingSettingUpdateMixin'
import ClientDetailsSection from './ClientDetails/ClientDetailsSection.vue'
import ClientEmergencyContactSection from './ClientEmergencyContactSection.vue'
import ClientVetContactSection from './ClientVetContactSection.vue'
import ClientAcceptMarketingSection from './ClientAcceptMarketingSection.vue'
import PetsList from './PetDetails/PetsList.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'RegistrationForm',
  mixins: [ClientOnboardingInviteCacheMixin, MarketingSettingUpdateMixin],
  computed: {
    ...mapState('ApplicationEvents', ['updatedAcceptMarketingEvent']),
    ...mapState('RegistrationClients', ['loadingClient']),
    ...mapState('RegistrationClientAddress', ['loadingClientAddress']),
    ...mapState('RegistrationPets', ['loadingPets']),
    ...mapState('RegistrationAlternateContact', ['loadingAlternateContact']),
    ...mapState('RegistrationVetContact', ['loadingVetContact']),
    ...mapState('RegistrationPetNotes', ['loadingPetNotes']),
    ...mapState('RegistrationClientNotes', ['loadingClientNotes']),
    ...mapState('RegistrationMarketingSetting', ['loadingMarketingSetting']),
    ...mapState('RegistrationBreeds', ['loadingPetBreeds']),
    loading () {
      return (
        this.loadingClient ||
        this.loadingClientAddress ||
        this.loadingPets ||
        this.loadingAlternateContact ||
        this.loadingVetContact ||
        this.loadingPetNotes ||
        this.loadingClientNotes ||
        this.loadingMarketingSetting ||
        this.loadingPetBreeds
      )
    },
    inviteId () {
      return this.$route.query.id
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerSaveRegistration']),
    ...mapMutations('ApplicationEvents', ['triggerResetUpdatedPetDetail']),
    ...mapMutations('ApplicationEvents', ['triggerResetUpdatedPetNote'])
  },
  watch: {
    updatedAcceptMarketingEvent () {
      this.tryFetchClientOnboardingInvite()
      this.triggerResetUpdatedPetDetail()
      this.triggerResetUpdatedPetNote()
    }
  },
  components: {
    'client-details': ClientDetailsSection,
    'emergency-contact': ClientEmergencyContactSection,
    'vet-details': ClientVetContactSection,
    'accept-marketing': ClientAcceptMarketingSection,
    'pets-list': PetsList,
    'error-banner': ErrorBanner
  }
}
</script>
