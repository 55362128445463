import { WebApi } from '@/services/WebApi'

const state = () => ({
  fetchedCountries: false,
  fetchingCountries: false,
  countries: [],
  country: null
})

const getters = {
  findCountry: (state) => siteId => state.countries.find(c => c.siteId === siteId)
}

const mutations = {
  setFetching (state, fetching) {
    state.fetchingCountries = !!fetching
    state.fetchedCountries = false
  },
  setCountries (state, countries) {
    state.countries = countries
    state.fetchedCountries = true
    state.fetchingCountries = false
  },
  setCountry (state, country) {
    state.country = country
    state.fetchedCountries = true
    state.fetchingCountries = false
  }
}

const actions = {
  fetchCountries ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Country')
        .then(response => {
          commit('setCountries', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  },
  fetchCountriesBySiteId ({ commit }, siteId) {
    return new Promise((resolve, reject) => {
      commit('setFetching', true)
      WebApi.get('Portal/' + siteId + '/Country')
        .then(response => {
          commit('setCountry', response.data[0])
          resolve(response)
        })
        .catch(error => {
          commit('setFetching', false)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
