/* eslint-disable */
<template>
    <div class="text-justify terms-and-conditions">
        <template v-if="loadingBookingTerm">
            <span class="inline-loader w-100"></span>
        </template>
        <template v-else-if="!!bookingTerm">
            <div v-html="sanitizedHtmlContent"></div>
        </template>
        <div v-else-if="noBookingTerm" class="font-size-h2 text-header-color mt-3 mt-md-1 px-4 text-center">TERMS AND CONDITIONS NOT AVAILABLE</div>
    </div>
</template>

<script>
/* eslint-disable */
    import { BookingTermCacheMixin } from '@/mixins/Booking/BookingTermCacheMixin'
    import DOMPurify from 'dompurify';

    export default {
        name: 'TermsAndConditions',
        mixins: [BookingTermCacheMixin],
        computed: {
            bookingTermHtml() {
                return !!this.bookingTerm && !!this.bookingTerm.html ? this.bookingTerm.html : ''
            },
            sanitizedHtmlContent() {
                return DOMPurify.sanitize(this.bookingTermHtml);
            },
            noBookingTerm() {
                return !!this.attemptedBookingTerm && !this.bookingTerm && !this.loadingBookingTerm;
            }
        }
    }
</script>

<style scoped>
    @media screen and (max-width: 768px)  {
        .terms-and-conditions {
            height: 50vh;
        }
    }
</style>