<template>
    <div></div>
</template>

<script>
/* eslint-disable */
    import { mapMutations } from 'vuex'
    import { AppointmentDefaultsSettingMixin } from '@/mixins/Booking/AppointmentDefaultsSettingMixin'
    
    export default {
        name: 'DurationSet',
        mixins: [AppointmentDefaultsSettingMixin],
        computed: {
            petDurationMinutes() {
                return !!this.settingObject ? this.settingObject.petDurationMinutes : this.defaultSettingObject.petDurationMinutes;
            }
        },
        watch: {
            petDurationMinutes() {
                this.refreshDuration();
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'setDuration'
            ]),
            refreshDuration(){
                this.setDuration(this.petDurationMinutes);
            }
        },
        created() {
            this.refreshDuration();
        }
    }
</script>
