<template>
  <div>
    <page-navbar></page-navbar>
    <div class="main-page container">
      <page-loader v-if="loading"></page-loader>
      <div v-else class="row">
        <div class="col-12 col-md-7">
          <client-details :schedule-id="scheduleId"></client-details>
          <appointments-table :schedule-id="scheduleId" :sequence="sequence"></appointments-table>
          <payments-table v-if="schedulePaymentAccount" :reference="reference"></payments-table>
        </div>
        <div class="col-12 col-md-5">
          <img class="w-100" src="@/assets/Images/appointment.png" alt="Appointment">
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { LogoCacheMixin } from '@/mixins/Logo/LogoCacheMixin'
import { SchedulePaymentAccountCacheMixin } from '@/mixins/Payment/SchedulePaymentAccountCacheMixin'
import AppointmentsTable from './AppointmentsTable.vue'
import ClientDetails from './ClientDetails.vue'
import PaymentsTable from './PaymentsTable.vue'
import PageLoader from '../Loading/PageLoader.vue'
import PageNavBar from '../Layout/PageNavBar.vue'
import PageFooter from '../Layout/PageFooter.vue'

export default {
  name: 'AppointmentViewer',
  mixins: [LogoCacheMixin, SchedulePaymentAccountCacheMixin],
  data () {
    return {
      sequence: 0
    }
  },
  methods: {
    ...mapMutations('PaymentTokens', [
      'setPaymentLoginRoute'
    ])
  },
  computed: {
    scheduleId () {
      return this.$route.query.id
    },
    sequenceNumber () {
      return parseInt(this.$route.query.sequence)
    },
    reference () {
      return this.selectedSiteId ? this.scheduleId + this.sequence : ''
    },
    loading () {
      return !!this.loadingSchedulePaymentAccount || !!this.loadingLogo
    }
  },
  components: {
    'client-details': ClientDetails,
    'appointments-table': AppointmentsTable,
    'payments-table': PaymentsTable,
    'page-loader': PageLoader,
    'page-navbar': PageNavBar,
    'page-footer': PageFooter
  },
  created () {
    this.sequence = isNaN(this.sequenceNumber) ? 0 : this.sequenceNumber
    this.setPaymentLoginRoute('ClientAppointmentSchedule/' + this.scheduleId + '/PaymentUser/Login')
  }
}
</script>
