import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapGetters, mapState } from 'vuex'

/* eslint-disable */
export const AppSettingCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('AppSettings', [
            'appSettings'
        ]),
        ...mapGetters('AppSettings', [
            'findAppSetting'
        ]),
        settingKey() {
            console.log('!!!OVERRIDE COMPUTED - settingKey!!!');
        },
        defaultSettingObject() {
            console.log('!!!OVERRIDE COMPUTED - defaultSettingObject!!!');
        },
        selectedSiteId() {
            console.log('!!!OVERRIDE COMPUTED - siteId')
        },
        settingCache() {
            return this.findAppSetting(this.settingKey);
        },
        loadingSetting() {
            return !!this.settingCache && this.settingCache.loading;
        },
        attemptedSetting() {
            return !!this.settingCache && this.settingCache.attempted;
        },
        setting() {
            return !!this.settingCache ? this.settingCache.value : null;
        },
        settingString() {
            return !!this.setting ? this.setting.value : '';
        },
        settingObject() {
            return Object.assign({}, this.defaultSettingObject, (!!this.settingString ? JSON.parse(this.settingString) : null));
        }
    },
    watch: {
        settingKey() {
            this.refreshSetting();
        },
        selectedSiteId() {
            this.refreshSetting();
        }
    },
    methods: {
        ...mapActions('AppSettings', [
            'fetchAppSettingBySiteId'
        ]),
        refreshSetting() {
            if (!this.settingCache || !!this.settingCache.retry) {
                this.tryGetAppSetting();
            }
        },
        tryGetAppSetting() {
            const self = this;
            self.error = '';
            if(!self.settingKey || !self.selectedSiteId) {
                return;
            }
            self.fetchAppSettingBySiteId({key: self.settingKey, siteId: self.selectedSiteId, payload: { settingKey: self.settingKey }})
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshSetting();
    }
}
