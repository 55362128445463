/* eslint-disable */
import { mapActions } from "vuex";
import { ErrorHelper } from "@/helpers/ErrorHelper";
import { PetNoteCacheMixin } from "./PetNoteCacheMixin";

export const PetNoteAddMixin = {
    mixins: [PetNoteCacheMixin],
    data() {
        return {
            noteText: ''
        }
    },
    computed: {
        petNoteAddModel() {
            return Object.assign({}, this.ownerQueryModel, {
                noteTypeId: this.noteTypeId,
                text: this.noteText
            });
        },
    },
    methods: {
        ...mapActions('RegistrationPetNotes', [
            'addPetNote'
        ]),
        tryAddPetNote() {
            const self = this;
            self.error = '';
            return self.addPetNote({ inviteId: self.inviteId, payload: self.petNoteAddModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    }
}
