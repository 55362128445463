/* eslint-disable */
<template>
    <div class="w-100 px-3 my-2">
        <div class="d-flex flex-column justify-content-center align-items-start">
            <p class="mb-0 text-left">Name: <strong>{{firstName}} {{lastName}}</strong></p>
            <p class="mb-0 text-left">Email: <strong>{{email}}</strong></p>
            <p class="mb-0 text-left">Mobile: <strong>{{telephoneMobile}}</strong></p>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState } from 'vuex'

    export default {
        name: 'SummaryClientDetails',
        computed: {
            ...mapState('BookingUpload', [
                'firstName',
                'lastName',
                'email',
                'telephoneMobile'
            ])
        }
    }
</script>