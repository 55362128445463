<template>
    <span class="inline-loader"></span>
</template>

<script>
export default {
  name: 'InlineLoader'
}
</script>

<style scoped>
.inline-loader {
    display: inline-block;
    width: 30px;
    height: 27px;
    vertical-align: middle;
    background: url(@/assets/Images/loading.svg) 50% 50% no-repeat;
    background-size: contain;
}
</style>
