/* eslint-disable */
import { mapActions } from 'vuex';
import { ClientCacheMixin } from './ClientCacheMixin.js';
import { ClientHelper } from '@/helpers/ClientHelper.js';
import { ErrorHelper } from '@/helpers/ErrorHelper.js';
import { MobileValidatorMixin } from './MobileValidatorMixin.js';

export const ClientUpdateMixin = {
    mixins: [ClientCacheMixin, MobileValidatorMixin],
    data() {
        return {
            firstName: '',
            lastName: '',
            telephoneHome: '',
            telephoneWork: '',
            telephoneMobile: '',
            clientValidator: ClientHelper.getClientValidator()
        }
    },
    computed: {
        clientUpdateModel() {
            return !!this.clientCache ? Object.assign({}, this.clientCache, {
                firstName: this.firstName,
                lastName: this.lastName,
                telephoneHome: this.telephoneHome,
                telephoneWork: this.telephoneWork,
                telephoneMobile: this.telephoneMobile
            }) : null;
        },
        clientUpdateModelValid() {
            return !!this.clientUpdateModel && this.clientValidator.isValid && !this.mobileValidation;
        }
    },
    watch: {
        clientCache() {
            this.refreshClientUpload();
        }
    },
    methods: {
        ...mapActions('RegistrationClients', [
            'updateClient',
        ]),
        refreshClientUpload() {
            this.firstName = !!this.clientCache ? this.clientCache.firstName : '';
            this.lastName = !!this.clientCache ? this.clientCache.lastName : '';
            this.telephoneHome = !!this.clientCache ? this.clientCache.telephoneHome : '';
            this.telephoneWork = !!this.clientCache ? this.clientCache.telephoneWork : '';
            this.telephoneMobile = !!this.clientCache ? this.clientCache.telephoneMobile : '';
        },
        tryUpdateClient() {
            const self = this;
            if (!self.clientCache) {
                return;
            }
            self.clientValidator.check(self.clientUpdateModel);
            self.validateMobile(self.telephoneMobile);
            if (!self.clientUpdateModelValid) {
                return;
            }
            self.error = '';
            return self.updateClient({ inviteId: self.inviteId, payload: self.clientUpdateModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        },
    },
    created() {
        this.refreshClientUpload();
    }
}
