<template>
    <div class="booking-divider booking-divider-right">
        <div class="booking-content">
            <display-banner></display-banner>
            <div class="booking-divider-title text-header-color">Your pet details</div>
            <div class="timeslots">
                <div v-for="(pet, index) in selectedPets" :key="pet.id">
                    <add-pet :pet-id="pet.id"
                        :index="index"
                        @deletePet="removePet(pet.id)"
                        ref="addPetComponents"/>
                </div>
                <a v-if="canAddMorePets" @click="handleAddPet" class="w-100 text-center" style="cursor: pointer !important; margin-top: auto">Add additional pet?</a>
            </div>
        </div>
        <div class="fixed-buttons">
            <button class="btn btn-tertiary w-100 mr-2" @click="back">Back</button>
            <button class="btn btn-secondary w-100 ml-2" @click="next">Next</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { mapState, mapMutations } from 'vuex';
    import { SiteBookingSettingMixin } from '@/mixins/Booking/SiteBookingSettingMixin';
    import DisplayBanner from '../DisplayBanner.vue';
    import AddPet from './AddPet.vue';

    export default {
        name: 'NewPets',
        mixins: [SiteBookingSettingMixin],
        computed: {
            ...mapState('BookingUpload' , [
                'selectedPets'
            ]),
            petsValidated() {
                return this.selectedPets.every(sp => !!sp.isValid)
            },
            canAddMorePets() {
                return this.selectedPets.length < this.siteBookingSettingMaxPets;
            }
        },
        methods: {
            ...mapMutations('BookingUpload', [
                'addPet'
            ]),
            ...mapMutations('BookingUpload', [
                'removePet'
            ]),
            back() {
                this.$emit('back')
            },
            next() {
                this.$refs.addPetComponents.forEach((addPetComponent) => {
                    addPetComponent.next();
                });

                if(!!this.petsValidated) {
                    this.$emit('next');
                    this.$emit('toLastPet', false);
                }
            },
            handleAddPet() {
                if (this.selectedPets.length >= this.siteBookingSettingMaxPets) {
                    return;
                } else {
                    this.addPet();
                }
            }
        },
        created() {
            if(this.selectedPets.length === 0) {
                this.addPet();
            }
        },
        components: {
            'display-banner': DisplayBanner,
            'add-pet': AddPet
        }
    }
</script>

<style scoped>
    .timeslots{
         @media screen and (max-width: 768px) {
            height: max-content !important;
            padding-bottom: 4rem;
         }
    }
</style>
