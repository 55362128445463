import { mapState } from 'vuex'
import moment from 'moment-timezone'

export const AppointmentRowMixin = {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    pets: {
      type: Array,
      required: true
    },
    resources: {
      type: Array,
      required: true
    },
    timeFormat: String
  },
  computed: {
    ...mapState('TimeZone', [
      'selectedTimeZoneId'
    ]),
    petId () {
      return this.appointment.petId
    },
    pet () {
      return this.pets.find(p => p.id === this.petId)
    },
    petName () {
      return this.pet ? this.pet.name : 'Unknown'
    },
    startUtc () {
      return this.appointment.startUtc
    },
    startFormatted () {
      return moment.tz(this.startUtc, this.selectedTimeZoneId).format(this.timeFormat)
    },
    resourceId () {
      return this.appointment.resourceId
    },
    resource () {
      return this.resources.find(r => r.id === this.resourceId)
    },
    resourceName () {
      return this.resource ? this.resource.name : 'Unknown'
    },
    status () {
      return this.appointment.status.description
    }
  }
}
