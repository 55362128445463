/* eslint-disable */
import { SelectedSiteMixin } from "../Site/SelectedSiteMixin";
import { AppSettingCacheMixin } from "./AppSettingCacheMixin"

export const BrandingSettingMixin = {
    mixins: [AppSettingCacheMixin, SelectedSiteMixin],
    computed: {
        settingKey() {
            return 'Branding';
        },
        defaultSettingObject() {
            return {
                mainTextColor: "#434242",
                primaryButtonColor: "#F24C64",
                secondaryButtonColor: "#86BF4E",
                tertiaryButtonColor: "#51C5DE",
                headerBackgroundColor: "#EEEEEE",
                headerTextColor: "#434242",
                footerBackgroundColor: "#0A4F70",
                footerTextColor: "#51C5DE",
            }
        }
    },
    watch: {
        setting(value) {
            document.documentElement.style.setProperty('--default-color', this.settingObject.mainTextColor);
            document.documentElement.style.setProperty('--primary-color', this.settingObject.primaryButtonColor);
            document.documentElement.style.setProperty('--secondary-color', this.settingObject.secondaryButtonColor);
            document.documentElement.style.setProperty('--tertiary-color', this.settingObject.tertiaryButtonColor);
            document.documentElement.style.setProperty('--header-color', this.settingObject.headerBackgroundColor);
            document.documentElement.style.setProperty('--header-text-color', this.settingObject.headerTextColor);
            document.documentElement.style.setProperty('--footer-color', this.settingObject.footerBackgroundColor);
            document.documentElement.style.setProperty('--footer-text-color', this.settingObject.footerTextColor);
        }
    }
}
