export const CacheHelper = {
  ownerRequestCacheKey (ownerRequestModel) {
    return ownerRequestModel.ownerId + ownerRequestModel.ownerTypeId
  },
  ownerCacheKey (ownerModel) {
    return ownerModel.ownerId + ownerModel.ownerType.id
  },
  getObjectCacheKey (obj) {
    let key = ''
    for (const property in obj) {
      key += obj[property]
    }
    return key
  },
  fullObjectCacheKey (obj) {
    let key = ''
    for (const property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        key += property
        key += obj[property]
      }
    }
    return key
  }
}
