import { ErrorHelper } from '@/helpers/ErrorHelper'
import { mapActions, mapState } from 'vuex'

/* eslint-disable */
export const BookingSuggestionCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('BookingSuggestion', [
            'bookingSuggestion',
            'loadingBookingSuggestion'
        ]),
        token () {
            return this.$route.query.token
        }
    },
    watch: {
        token() {
            this.refreshBookingSuggestion();
        }
    },
    methods: {
        ...mapActions('BookingSuggestion', [
            'fetchBookingSuggestionByToken'
        ]),
        refreshBookingSuggestion() {
            if (!this.bookingSuggestion) {
                this.tryFetchBookingSuggestionByToken();
            }
        },
        tryFetchBookingSuggestionByToken() {
            const self = this;
            self.error = '';
            if(!self.token) {
                return;
            }
            self.fetchBookingSuggestionByToken({ token: self.token })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingSuggestion();
    }
}
