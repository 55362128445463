<template>
    <div>
        <div class="d-flex payment_row">
            <div class="col-4 payment_row-data">{{ formattedDate }}</div>
            <div class="col-4 payment_row-data">{{ amount }}</div>
            <div class="col-4 payment_row-data">{{ status }}</div>
        </div>
        <button class="btn btn-secondary col-12" type="button" v-if="canProcess" @click="signalProcess">
          <i class="fas fa-shopping-cart"></i> Pay Now
        </button>
    </div>
</template>

<script>
import { PaymentRowMixin } from '@/mixins/Appointment/PaymentRowMixin'

export default {
  name: 'PaymentMobileRow',
  mixins: [PaymentRowMixin],
  props: {
    number: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.payment_row {
  border-top: 1px solid #dee2e6;
}

.payment_row-data {
  padding: 0.75rem;
}
</style>
