/* eslint-disable */
<template>
    <div>
        <one-off v-if="resourceOneOffHour" :hours="resourceOneOffHour" :time-slot="timeSlot" :resource-id="resourceId" v-model="hoursStatus"></one-off>
        <working-hour v-else-if="resourceWorkingHoursDay" :hours="resourceWorkingHoursDay" :time-slot="timeSlot" :resource-id="resourceId" v-model="hoursStatus"></working-hour>
    </div>
</template>

<script>
/* eslint-disable */
    import { SelectedDateMixin } from '@/mixins/Booking/SelectedDateMixin';
    import { OneOffHourSearchMixin } from '@/mixins/Booking/OneOffHourSearchMixin';
    import { StatusEmitterMixin } from '@/mixins/Booking/StatusEmitterMixin';
    import { WorkingHourCacheMixin } from '@/mixins/Booking/WorkingHourCacheMixin';
    import ResourceOneOffHoursConflict from './ResourceOneOffHoursConflict.vue';
    import ResourceWorkingHoursConflict from './ResourceWorkingHoursConflict.vue';

    export default {
        name: 'ResourceHoursConflict',
        mixins: [StatusEmitterMixin, OneOffHourSearchMixin, WorkingHourCacheMixin, SelectedDateMixin],
        props: {
            timeSlot: Object,
            resourceId: String
        },
        data() {
            return {
                hoursStatus: {
                    loading: false,
                    available: true
                }
            }
        },
        computed: {
            resourceOneOffHour() {
                return this.oneOffHours.length > 0 ? this.oneOffHours.find(a => a.resourceId === this.resourceId) : null;
            },
            noResourceOneOffHour() {
                return !this.resourceOneOffHour && !!this.attemptedOneOffHours && !this.loadingOneOffHours
            },
            resourceWorkingHours(){
                return this.workingHours.length > 0 ? this.workingHours.filter(wh => wh.resourceId === this.resourceId) : []
            },
            dayId() {
                return new Date(this.dateString).getUTCDay();
            },
            resourceWorkingHoursDay() {
                return this.resourceWorkingHours.find(wh => wh.dayOfWeek.id === this.dayId)
            },
            loading() {
                return !!this.loadingOneOffHours || !!this.loadingWorkingHours || this.hoursStatus.loading
            },
            noHours() {
                return !this.resourceWorkingHoursDay && !!this.noResourceOneOffHour
            },
            available() {
                return (this.noHours || this.hoursStatus.available) && !this.loading
            }
        },
        methods: {
            refreshOneOffHours() {
                //do nothing!
            },
            refreshWorkingHours() {
                //do nothing!
            }
        },
        components: {
            'one-off': ResourceOneOffHoursConflict,
            'working-hour': ResourceWorkingHoursConflict
        }
    }
</script>
