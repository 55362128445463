/* eslint-disable */
import { WebApi } from '@/services/WebApi';

const state = () => ({
    breedSearchCaches: []
})

const getters = {
    findBreedSearch: (state) => (key) => state.breedSearchCaches.find(search => search.key === key),
}

const mutations = {
    setLoading(state, key) {
        const existing = state.breedSearchCaches.find(bsc => bsc.key === key);
        if (!!existing) {
            existing.loading = true;
            existing.retry = false;
        } else {
            state.breedSearchCaches.push({ key: key, values: [], loading: true });
        }
    },
    setSearch(state, { key, results }) {
        const existing = state.breedSearchCaches.find(bsc => bsc.key === key);
        if (!!existing) {
            existing.values = results;
            existing.loading = false;
        } else {
            state.breedSearchCaches.push({ key: key, values: results, loading: false });
        }
    },
    setRetry(state, key) {
        var existing = state.breedSearchCaches.find(search => search.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    searchBreeds({ commit }, { key,  payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', key);
            WebApi.post('Breed/Search', payload)
                .then(response => {
                    commit('setSearch', { key: key, results: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
