/* eslint-disable */
import { ErrorHelper } from '../../helpers/ErrorHelper';
import { mapState, mapActions } from 'vuex';
import { SelectedSiteMixin } from "../Site/SelectedSiteMixin";

export const TimeZoneCacheMixin = {
    mixins: [SelectedSiteMixin],
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapState('TimeZone', [
            'timeZone',
            'loadingTimeZone',
            'retryTimeZone'
        ])
    },
    watch: {
        selectedSiteId() {
            this.refreshTimeZone()
        }
    },
    methods: {
        ...mapActions('TimeZone', [
            'getTimeZoneBySiteId'
        ]),
        refreshTimeZone() {
            if (!this.timeZone || !!this.retryTimeZone) {
                this.tryGetTimeZoneBySiteId();
            }
        },
        tryGetTimeZoneBySiteId() {
            const self = this;
            self.error = '';
            if(!self.selectedSiteId) {
                return;
            }
            self.getTimeZoneBySiteId(self.selectedSiteId)
                .catch(error => ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshTimeZone();
    }
}