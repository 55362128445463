<template>
  <div>
    <div class="mb-4" style="font-size: 1.25rem; font-weight: 600;">My Details</div>
    <error-banner v-if="error" :message="error"></error-banner>
    <name-fields v-model="clientName" :validator="clientValidator" :updated="clientDetailsUpdated"></name-fields>
    <labeled-form-control label="Mobile">
        <span v-if="loadingClient" class="inline-loader"></span>
        <template v-else>
          <input v-model="telephoneMobile" class="form-control" type="tel" placeholder="Mobile" :disabled="clientDetailsUpdated"/>
          <validation-message v-if="mobileValidation" :message="mobileValidation"></validation-message>
        </template>
    </labeled-form-control>
    <address-field :invite="invite"></address-field>
    <medical-note :invite="invite"></medical-note>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ClientUpdateMixin } from '@/mixins/Registration/ClientUpdateMixin'
import { ClientOnboardingInviteCacheMixin } from '@/mixins/Registration/ClientOnboardingInviteCacheMixin'
import LabeledFormControl from '@/components/Layout/LabeledFormControl.vue'
import ClientNameFields from './ClientNameFields.vue'
import ClientMedicalNote from './ClientMedicalNote.vue'
import ClientAddressField from './ClientAddressField.vue'
import ValidationMessage from '@/components/ErrorValidation/ValidationMessage.vue'
import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue'

export default {
  name: 'ClientDetailsSection',
  mixins: [ClientUpdateMixin, ClientOnboardingInviteCacheMixin],
  props: {
    invite: Object
  },
  computed: {
    ...mapState('ApplicationEvents', ['saveRegistrationEvent']),
    inviteId () {
      return this.invite ? this.invite.id : ''
    },
    clientName: {
      get () {
        return {
          firstName: this.firstName,
          lastName: this.lastName
        }
      },
      set (value) {
        this.firstName = value.firstName
        this.lastName = value.lastName
      }
    },
    clientDetailsUpdated () {
      return this.clientOnboardingInvite ? this.clientOnboardingInvite.clientDetailsUpdated : false
    }
  },
  methods: {
    ...mapMutations('ApplicationEvents', ['triggerUpdatedClientDetails'])
  },
  watch: {
    async saveRegistrationEvent () {
      if (!this.clientDetailsUpdated) {
        try {
          await this.tryUpdateClient()
        } finally {
          this.triggerUpdatedClientDetails()
        }
      } else {
        this.triggerUpdatedClientDetails()
      }
    }
  },
  components: {
    'labeled-form-control': LabeledFormControl,
    'name-fields': ClientNameFields,
    'address-field': ClientAddressField,
    'medical-note': ClientMedicalNote,
    'validation-message': ValidationMessage,
    'error-banner': ErrorBanner
  }
}
</script>
